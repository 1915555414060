import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import cx from 'classnames';

import {CommonHead} from './SignIn';
import {SubmitButton, CustomFormGroup, ErrorView} from './SignIn';
import {getTmUrl} from '../../utilities/nav';

import style from './SignInUp.module.css';

function SignUp({fullName, password, mail, error, loading}) {
  return (
    <Container>
      <CommonHead />
      <Row>
        <Col>
          <div className={cx(style.formGroupWrapper, style.signUpName)}>
            <CustomFormGroup 
              type="text"
              placeholder="Scrivi il tuo nome"
              value={fullName}
              onChange={mail.fullName}
              disabled={loading}/>
          </div>
          <div className={style.formGroupWrapper}>
            <CustomFormGroup
              type="password"
              placeholder="Scrivi la tua password"
              value={password}
              onChange={mail.password}
              disabled={loading}/>
          </div>
          <ErrorView error={error}/>
          <div className={style.privacyWrapper}>
            <p>
              <span>Cliccando sul seguente bottone accetto i </span>
              <a 
                href={getTmUrl()}
                title="Termini e Condizioni"
                target="_blank" 
                rel="noopener noreferrer"
              >
                Termini e Condizioni
              </a>
              <span> e la </span>
              <a 
                href="https://www.iubenda.com/privacy-policy/97438028" 
                title="Privacy Policy" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <span> di Up Academy</span>
            </p>
          </div>
          <div className={style.buttonWrapper}>
            <SubmitButton text="ENTRA IN UP ACADEMY" callback={mail.submit} disabled={loading}/>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default SignUp
