import firebase from 'firebase/app';
import 'firebase/auth';

const prodConfig = {
  apiKey: "AIzaSyDsC_alSuDSeUvCBaU4hHqmM5s1LV981x8",
  authDomain: "up-prod-facf2.firebaseapp.com",
  databaseURL: "https://up-prod-facf2.firebaseio.com",
  projectId: "up-prod-facf2",
  storageBucket: "up-prod-facf2.appspot.com",
  messagingSenderId: "216865246835",
  appId: "1:216865246835:web:7a553640985772d7"
}

const stagingConfig = {
  apiKey: "AIzaSyDY228gAffMX9mT_ckinia4OvPC6orgWn8",
  authDomain: "up-test-9f129.firebaseapp.com",
  databaseURL: "https://up-test-9f129.firebaseio.com",
  projectId: "up-test-9f129",
  storageBucket: "up-test-9f129.appspot.com",
  messagingSenderId: "824635371953"
}

const developmentConfig = {
  apiKey: "AIzaSyDY228gAffMX9mT_ckinia4OvPC6orgWn8",
  authDomain: "up-test-9f129.firebaseapp.com",
  databaseURL: "https://up-test-9f129.firebaseio.com",
  projectId: "up-test-9f129",
  storageBucket: "up-test-9f129.appspot.com",
  messagingSenderId: "824635371953"
}

let env = process.env.NODE_ENV

if (env === 'production' && process.env.REACT_APP_ENV === 'staging'){
  env = 'staging'
}

const config = (env === 'production') ? prodConfig : (env === 'staging') ? stagingConfig : developmentConfig


!firebase.apps.length && firebase.initializeApp(config);

// signInWithPopup error codes
// https://firebase.google.com/docs/reference/js/firebase.auth.Auth#sign-inwith-popup
// 
// auth/popup-closed-by-user    -> user close popup window
// auth/cancelled-popup-request -> if popup window is already open

class Firebase {
  constructor(authChanged) {
    this.auth = firebase.auth();
    this.auth.onAuthStateChanged(authChanged);

    this.google = new firebase.auth.GoogleAuthProvider();
    this.facebook = new firebase.auth.FacebookAuthProvider();
  }

  // Catch error in AccessDialog google and facebook method
  socialSignIn(provider) {
    return this.auth.signInWithPopup(provider)
      .then(result => result.user.getIdToken())
  }

  signIn(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password)
      .then(result => result.user.getIdToken())
  }

  signUp(fullName, email, password) {
    let fresh = null;

    return this.auth.createUserWithEmailAndPassword(email, password)
      .then(({user}) => {
        fresh = user;
        return fresh.updateProfile({
          displayName: fullName,
        })
      })
      .then(() => fresh.getIdToken())
  }

  googleSignIn() {
    return this.socialSignIn(this.google)
  }

  facebookSignIn() {
    return this.socialSignIn(this.facebook)
  }

  signOut() {
    return this.auth.signOut()
  }

  resetPassword(email, url) {
    return this.auth.sendPasswordResetEmail(email, {
      url: url,
    })
  }

  user() {
    return this.auth.currentUser
  }
}

export default Firebase
