import React from 'react';
import {connect} from 'react-redux';

import {onlyMobile} from '../../utilities/styleClasses';
import AppBar, {Page} from '../AppBar/AppBar';
import Footer from '../Footer/Footer';
import AccessManager from '../AccessManager/AccessManager';
import CheckoutManager from '../Checkout/CheckoutManager';
import {ToastContainer, toast} from 'react-toastify';
import * as selectors from '../../reducers/selectors';

import 'react-toastify/dist/ReactToastify.min.css';

toast.configure({
  autoClose: 2000,
  draggable: false,
  position: toast.POSITION.BOTTOM_LEFT,
})

function BottomSpacer({value}){
  return(
    <div 
      className={onlyMobile} 
      style={{height: value}}
    />
  )
}

function AppCommonBlock({children, page, mobileBottomSpacer}){
  const showPage = page !== Page.ViewCourse
  const footerSpacer = true
  return (
    <>
      <AccessManager/>
      <CheckoutManager/>
      <AppBar/> 
        {children}  
        {showPage && <Footer/>}
        {footerSpacer && <BottomSpacer value={mobileBottomSpacer}/>}
      <ToastContainer />
    </>
  )
}

const mapState = state => {
  return {
    mobileBottomSpacer: selectors.mobileBottomSpacer(state),
  }
}

export default connect(mapState)(AppCommonBlock) 
