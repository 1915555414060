import React, {useState} from 'react';
import {Row, Col, Collapse} from 'reactstrap';

import style from './ShowMoreItems.module.css';

function More({value, onClick}){
  return(
    <div className={style.more} onClick={onClick}>
      {value ? 
      <p>+{value}</p> : 
      <p>-</p>}
    </div>
  )
}

function ShowMoreItems({items}){
  const [showAll, setShowAll] = useState(false)
  const [status, setCollapseStatus] = useState('closed')
  if (items.length === 0) return null

  const handleClick = () => {
    setShowAll(prev => !prev)
  }

  const handleClose = () => {
    setCollapseStatus('closed')
  }

  const handleOpen = () => {
    setCollapseStatus('open')
  }

  const handleEntering = () => {
    setCollapseStatus('opening')
  }

  const handleClosing = () => {
    setCollapseStatus('closing')
  }

  const isClosed = status === 'closed'
  const moreValue = isClosed ? items.length : null
  const buttonClass = isClosed ? style.visible : style.hidden

  return(
    <div className="w-100">
      <Collapse
        isOpen={showAll}
        onExited={handleClose}
        onExiting={handleClosing}
        onEntering={handleEntering}
        onEntered={handleOpen}>
        {items}
      </Collapse>
      <Col xs={12}>
        <Row>
          <Col xs={2}>
            <More value={moreValue} onClick={handleClick}/>
          </Col>
          <Col xs={8} className="text-center">
            <div className={style.viewAll} onClick={handleClick}>
              <p className={buttonClass}><strong>VISUALIZZA TUTTI</strong></p>
            </div>
          </Col>
        </Row>
      </Col>
      
    </div>
  )
}

export default ShowMoreItems