import axios, {CancelToken} from 'axios'
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import JwtDecode from 'jwt-decode';
import queryString from 'query-string';
import {isEmpty} from 'lodash';

import { courseDuration, facultyDuration, courseStats } from './utilities'

let env = process.env.NODE_ENV

if (env === 'production' && process.env.REACT_APP_ENV === 'staging'){
  env = 'staging'
}

// const dev= 'http://192.168.101.228:8000'
const dev = 'http://localhost:8000'
const staging = 'https://stagingapi.upacademy.eu'
const prod = 'https://api.upacademy.eu'

const path = '/api/v1'

export const host = (env === 'production') ? prod : (env === 'staging') ? staging : dev

const baseURL = `${host}${path}`

class Client {

  constructor(baseUrl) {
    this.instance = axios.create({baseURL: baseUrl})
    this.auth = {}
    const maxRetry = 1
    this.retry = maxRetry

    // Session refresh manage
    // A client request with old token will be re-send whene we got a new token
    // es. a request like "client.getCourse(courseId)" got no error if the first call got 410
    // because we intercept the error, return 'refreshSession' and when is resolved returns original request
    // Only got an error if all the retry failed
    this.instance.interceptors.response.use(null, (error) => {
      if (error.config && error.response && error.response.status === 410) {
        if (this.retry > 0) {
          this.retry -= 1
          return this.refreshSession()
            .then(() => {
              const {access_token} = this.auth
              const headers = access_token ? {'Authorization': `Bearer ${access_token}`} : {}
              error.config.headers = headers

              // "get" request can be cached by browser, 
              // so if we don't change url, we get the same 410
              if (error.config && error.config.method === 'get'){
                const qs = queryString.extract(error.config.baseURL+error.config.url)
                const parsed = queryString.parse(qs)
                const tstmp = Date.now()
                var add = ""
                if (!isEmpty(parsed)){
                  add = "&timestamp="+tstmp
                }else{
                  add = "?timestamp="+tstmp
                }
                error.config.url += add
              }
              
              // reset this.retry only without another 410 response
              return this.instance.request(error.config)
                .then(resp => {
                  this.retry = maxRetry
                  return resp
                })
                .catch(err => {
                  if (err.response && err.response.status !== 410) {
                    this.retry = maxRetry
                  }
                  return err
                })
            })
        }else{
          // Show some message
          toast.error("Server error, try to refresh page")
          if(this.auth.access_token){
            const decoded = JwtDecode(this.auth.access_token);
            if(decoded){
              Sentry.configureScope(function(scope) {
                scope.setUser({"id": decoded.sub});
              });
            }
          }
          Sentry.captureException(error)
          return Promise.reject(error);
        }      
      }else{
        return Promise.reject(error);
      }
    });
  }

  setAuthFromResponse = (response) => {
    if (!response) return
    const {access_token, refresh_token} = response.data
    this.auth = {access_token, refresh_token}
    return response.data
  }

  startSession = token => {
    return this.getSessionData(token)
      .then(response => this.setAuthFromResponse(response))
  }

  refreshSession = () => {
    return this.refreshSessionData(this.auth.refresh_token)
      .then(response => this.setAuthFromResponse(response))
  }

  clearSession = () => {
    this.auth = {}
  }

  fillRequest = (config) => {
    const {access_token} = this.auth
    const headers = access_token ? {'Authorization': `Bearer ${access_token}`} : {}

    const requestConfig = {
      url: config.endpoint,
      headers: headers,
      params: config.params,
    }

    if (config.method === 'GET'){
      requestConfig.method = 'get';
    } else if (config.method === 'POST'){
      requestConfig.method = 'post';
      requestConfig.data = config.data;
    } else if (config.method === 'PUT'){
      requestConfig.method = 'put';
      requestConfig.data = config.data;
    } else if (config.method === 'DELETE'){
      requestConfig.method = 'delete';
      requestConfig.data = config.data;
    }

    return requestConfig
  }

  makeRequest = config => this.instance(config)

  request (config) {
    const req = this.fillRequest(config)
    return this.makeRequest(req)
      .catch(err => {
        // This code is here as an example to manage some errors
        const isCancel = axios.isCancel(err)
        if(err.response){
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // Actually 410 is managed with interceptor, sentry error is sent by ErrorableClient
          // so nothing to do here
        }else if (err.request && !isCancel){
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest
          console.error(err.message)
        }else if(!isCancel){
          // Something happened in setting up the request that triggered an Error
          console.error(err.message)
        }
        throw err;
      })
  }

  getAcademy (id) {
    return this.request({
      endpoint: `/academies/${id}`,
      method: 'GET',
    })
  }

  getAcademies () {
    return this.request({
      endpoint: '/academies',
      method: 'GET',
    })
  }

  getFaculties (academy) {
    return this.request({
      endpoint: `/academies/${academy}/faculties`,
      method: 'GET',
    })
  }

  getTeachers (academy) {
    return this.request({
      method: 'POST',
      endpoint: `/academies/${academy}/teacher_searches`,
      data: {
        teacher: {},
      },
    })
  }

  getCategories (academy) {
    return this.request({
      method: 'POST',
      endpoint: `/academies/${academy}/category_searches`,
      data: {
        category: {},
      },
    })
  }

  getCourses (academy) {
    return this.request({
      method: 'POST',
      endpoint: `/academies/${academy}/course_searches`,
      data: {
        course: {},
      },
    })
  }

  getCourse (id) {
    return this.request({
      method: 'GET',
      endpoint: `/courses/${id}`,
    })
  }

  getLesson (id) {
    return this.request({
      method: 'GET',
      endpoint: `/lessons/${id}`,
    })
  }

  getSessionData (token) {
    return this.request({
      method: 'POST',
      endpoint: `sessions`,
      data: {
        firebase_jwt: token,
      }
    })
  }

  refreshSessionData (refresh_token) {
    return this.request({
      method: 'PUT',
      endpoint: 'sessions/0',
      data: {
        refresh_token: refresh_token,
      }
    })
  }

  getUser (id) {
    return this.request({
      method: 'GET',
      endpoint: `users/${id}`,
    })
  }

  createCheckout(plan) {
    return this.request({
      method: 'POST',
      endpoint: '/providers/chargebee/pages',
      data: {
        plan_id: plan,
      },
    })
  }

  completeCheckout(id) {
    return this.request({
      method: 'PUT',
      endpoint: `/providers/chargebee/pages/${id}`,
    })
  }

  getReactions(user) {
    return this.request({
      method: 'GET',
      endpoint: `/users/${user}/reactions`,
    })
  }

  addFavourite(user, course) {
    return this.request({
      method: 'POST',
      endpoint: `/users/${user}/favourites`,
      data: {
        favourite: {
          course_id: course,
        },
      }
    })
  }

  removeFavourite(user, course) {
    return this.request({
      method: 'DELETE',
      endpoint: `/users/${user}/favourites/${course}`,
    })
  }

  setLessonProgress(user, lesson, seconds) {
    return this.request({
      method: 'POST',
      endpoint: `/users/${user}/progresses`,
      data: {
        progress: {
          lesson_id: lesson,
          seconds: seconds,
        }
      }
    })
  }

  setPillProgress(user, pill, seconds) {
    return this.request({
      method: 'POST',
      endpoint: `/users/${user}/pill_progresses`,
      data: {
        progress: {
          pill_id: pill,
          seconds: seconds,
        }
      }
    })
  }

  getAccesses(user) {
    return this.request({
      method: 'GET',
      endpoint: `/users/${user}/accesses`,
    })
  }

  getPlans() {
    return this.request({
      method: 'GET',
      endpoint: '/plans',
    })
  }

  getFacultyPlans(faculty) {
    return this.request({
      method: 'GET',
      endpoint: `/faculties/${faculty}/plans`,
    })
  }

  getCoursePlans(course) {
    return this.request({
      method: 'GET',
      endpoint: `/courses/${course}/plans`,
    })
  }

  getLiveEvents(faculty){
    const liveEvent = {status: 3}
    return this.request({
      method: 'POST',
      endpoint: `faculties/${faculty}/live_event_searches`,
      data: {live_event: liveEvent},
    })
  }

  getVimeoInfo(id, cancel={exec: null}){
    return this.request({
      method: 'GET',
      endpoint: `/vimeo/${id}`,
      cancelToken: new CancelToken(c => (cancel.exec = c)),
    })
  }

  getFacultyCta(faculty){
    return this.request({
      method: 'GET',
      endpoint: `faculties/${faculty}/cta`,
    })
  }
}

class AugmentedClient extends Client {

  getFaculties(academy) {
    return super.getFaculties(academy)
      .then(resp => {
        resp.data.faculties.forEach(faculty => {
          faculty.categories = faculty.categories || []
          faculty.teachers = faculty.teachers || []
          faculty.hours = facultyDuration(faculty)
          const courses = faculty.courses || []
          courses.forEach(course => {
            // TODO
            // remove this filter when api do this
            course.lessons = course.lessons.filter(l => l.module_id !== null)
            course.lessons.sort((a, b) => a.number - b.number)
            course.seconds = courseDuration(course)
            course.modules.sort((a, b) => a.number - b.number)
          })
        })

        return resp
      })
  }

  getCourse(id) {
    return super.getCourse(id)
      .then(resp => {
        const {course} = resp.data
        // TODO
            // remove this filter when api do this
        course.lessons = course.lessons.filter(l => l.module_id !== null)
        course.lessons.sort((a, b) => a.number - b.number)
        course.seconds = courseDuration(course)
        course.modules.sort((a, b) => a.number - b.number)
        return resp
      })
  }

  getReactions(user) {
    return super.getReactions(user)
      .then(resp => {
        const {course_reactions: reactions} = resp.data
        Object.keys(reactions).forEach(courseId => {
          const stats = courseStats(reactions[courseId])
          Object.assign(reactions[courseId], stats)
        })
        return resp
      })
  }

  getPlans() {
    return super.getPlans()
      .then(resp => {
        resp.data.plans.forEach(plan => {
          plan.price_string = `${plan.price/100}€`
          plan.one_shot = plan.period === 10 && plan.period_unit === 'y'
        })
        return resp
      })
  }
}

class ErrorableClient extends AugmentedClient {

  request (config) {
    return super.request(config)
      .catch(err => {
        if (err.response && err.response.status === 410){
          // No message, we show messafe in interceptor
          throw err;
        }
        toast.error("Server communication error")
        Sentry.captureException(err)
        throw err;
      })
  }
}

export const client = new ErrorableClient(baseURL)
