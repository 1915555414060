import React from 'react';
import {Button} from 'reactstrap';

import style from './OutlineButton.module.css';

function OutlineButton({text, click}) {
  return (
    <Button
      onClick={click}
      color="secondary"
      className={style.button}
      outline
    >
      {text}
    </Button>
  )
}

export default OutlineButton
