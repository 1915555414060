import React from 'react';
import { ListGroupItem } from 'reactstrap';
import cx from 'classnames';
import { omit } from 'lodash';

import style from './ListItem.module.css';

function ListItem (props){
  const {text, box, callback, active, activeclassname=style.activeItem} = props
  const parentProps = omit(props, ['text', 'box', 'callback', 'active'])
  // disable (and other addictional props of ListGroupItem) will be passed in ListItem props
  const handleClick = () => {
    if (callback){
      return callback()
    }
  }

  const rootClass = 'd-flex justify-content-between align-items-center';
  const actionClass = callback ? style.actionItem : ''
  const activeClass = active ? activeclassname : ''
  return (
    <ListGroupItem 
        {...parentProps} 
        onClick={handleClick} 
        className={cx(rootClass, props.className, actionClass, activeClass)}>
      <p className={cx(style.text, "big")}>{text}</p>
      {box}
    </ListGroupItem>
  )
}

export default ListItem
