import React from 'react';

function ArrowLeft ({color='#000'}){
  return(
    <svg width="23px" height="43px" viewBox="0 0 23 43">
        <g id="UP-Academy-versione-review" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="Dettaglio-Faculty" transform="translate(-191.000000, -2383.000000)" stroke={color} strokeWidth="4">
                <polyline id="left_arrow" transform="translate(202.500000, 2404.500000) scale(-1, 1) rotate(-90.000000) translate(-202.500000, -2404.500000) " points="183 2395 202.423375 2414 222 2395"></polyline>
            </g>
        </g>
    </svg>
  )
}

export default ArrowLeft