import React from 'react';
import {Col} from 'reactstrap';
import moment from 'moment';
import cx from 'classnames';

import CustomCard from '../UI/Card/Card';
import TeacherPreview from '../TeacherPreview/TeacherPreview';
import ShowMoreItems from '../ShowMoreItems/ShowMoreItems';

import style from './LiveEventCard.module.css';

function Start({event}){
  const startAt = event.startAt ||  moment(event.start_at).milliseconds(0)
  const hour = startAt.format('HH:mm')
  const day = startAt.format('D MMMM')
  let liveText = `LIVE ${day}`
  if (startAt.isSame(moment(), 'day')){
    liveText = 'LIVE SOON'
  }
  return (
    <p>
      <span className={cx(style.day, 'text-red-1')}>{liveText} </span>
      dalle ore {hour}
    </p>
  )
}

function Teacher({teacher}){
  return(
    <Col xs={12}>
      <TeacherPreview 
        teacher={teacher} 
        imageCol={2} 
        textCol={10} 
        rootClass={style.teacher} 
        positionClass="d-none" 
        nameClass={style.teacherName}/>
    </Col>
  )
}

const teachersArray = teachers => {
  return teachers.map((el, idx) => <Teacher key={idx} teacher={el}/>)
}

function LiveEventCard({event}){
  const allTeachers = event.teachers || []
  const maxTeachers = 3

  const initialTeachers = allTeachers.slice(0, maxTeachers)
  const moreTeachers = allTeachers.slice(maxTeachers)

  return(
    <CustomCard className={style.root}>
      <Col xs={12} className={style.titleWrap}>
        <p className={style.title}>{event.title}</p>
      </Col>
      <Col xs={12} className={style.startWrap}>
        <Start event={event}/>
      </Col>
      <div>
        {teachersArray(initialTeachers)}
        <ShowMoreItems items={teachersArray(moreTeachers)}/>
      </div>
    </CustomCard>
  )
}

export default LiveEventCard