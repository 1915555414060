import React, {Component} from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import {withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import _ from 'lodash';
import cx from 'classnames';

import * as selectors from '../../reducers/selectors';
import * as actions from '../../actions';
import {onlyDesktop, onlyMobile} from '../../utilities/styleClasses';
import { api as client } from '../../api';
import { CourseStatsBadge } from '../UI';
import {coursePlansInfo} from '../Checkout/utilities';
import CourseTop, {Cta} from '../CourseTop/CourseTop';
import CourseContent from '../CourseContent/CourseContent';
import {Teachers} from '../Faculty/Faculty';
import Faq from '../Faq/Faq';

import style from './CoursePage.module.css';

const mobileCta = 'mobileCta'

function CourseAbout({course}){
  if (_.isEmpty(course)) return null
  const hText = "Informazioni"
  return(
    <Container fluid className="bg-white">
      <Container className={style.aboutWrapper}>
        <h3 className={onlyDesktop}>{hText}</h3>
        <h4 className={onlyMobile}>{hText}</h4>
        <Row>
          <Col xs={12} lg={6}>
            <div className={style.aboutDescription}>
              {course.description}
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className={style.stats}>
              <CourseStatsBadge 
                course={course} 
                className={style.statsRoot} 
                textClassName={style.statsText} 
                valueClassName={style.statsValue} 
                statsBoxClassName={style.statsBox}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

function MobileCta(props){
  const {isDataReady, course, user, facultyCta, hasCourseAccess} = props
  if (!isDataReady) {
    return null
  }
  const courseInfo = coursePlansInfo(course)
  const haveUser = !_.isEmpty(user)
  const hasCoursePlan = courseInfo.coursePlan !== null
  const hasFacultyPlan = courseInfo.facultyPlan !== null
  const hasLanding = !_.isEmpty(facultyCta)
  
  if (!hasCoursePlan && !hasFacultyPlan) return null
  if (haveUser && !hasCourseAccess && hasLanding && facultyCta.url === "") return null
  
  return(
    <Container fluid
      className={cx(style.mobileCta, onlyMobile)} 
      id={mobileCta}
    >
      <Row>
        <Col xs={12}> 
          <Cta {...props}/>
        </Col>
      </Row>
    </Container>
  )
}

class CoursePage extends Component{
  state = {
    course: {},
    spacerSetted: false,
    courseReady: false,
  }
  noCourse = false

  faculty() {
    const {getCourseFaculties} = this.props
    const {course} = this.state
    if (_.isEmpty(course)) {
      return {}
    }
    return getCourseFaculties(course.id)[0]
  }

  render(){
    const {course, courseReady} = this.state
    const {user, hasCourseAccess, isDataReady, history, getFacultyCta} = this.props
    const faculty = this.faculty()
    const courseAccess = hasCourseAccess(course)
    let ctaData = {}
    if (faculty) {
      ctaData = getFacultyCta(faculty.id)
    }
    
    if (this.noCourse) return <Redirect to="/"/>  
    const somethingEmpty = _.isEmpty(course) || !faculty
  
    return(
      <>
        {somethingEmpty && 
        <div style={{textAlign: "center", paddingTop: "70px", backgroundColor: "white", width: "100%"}}>
          <Spinner/>
        </div>  
        }
        <CourseTop 
          course={course} 
          faculty={faculty}
          hasCourseAccess={courseAccess}
          facultyCta={ctaData}
        />
        <CourseAbout course={course}/>
        <CourseContent 
          user={user} 
          course={course} 
          hasCourseAccess={courseAccess}
          onCourseUpdate={this.updateCourse}
          courseReady={courseReady}
          facultyCta={ctaData}
        />
        {!somethingEmpty &&
        <>
          <Teachers teachers={course.teachers}/>
          <Faq />
          <MobileCta 
            user={user}
            course={course}
            hasCourseAccess={courseAccess}
            history={history}
            isDataReady={isDataReady}
            clear={this.clearSpacerSetted}
            facultyCta={ctaData}
          />
        </>
        }
      </>
    )
  }

  clearSpacerSetted = () => {
    this.setState({spacerSetted: false})
  }

  loadCourse = courseId => {
    client.getCourse(courseId)
      .then(response => this.setState({course: response.data.course, courseReady: true}))
      .catch(() => {this.noCourse = true})
  }

  updateCourse = () => {
    const {match: {params: {courseId}}} = this.props
    this.setState({courseReady: false})
    this.loadCourse(courseId)
  }

  updateMobileSpacer = () => {
    const {setBottomSpacer} = this.props
    const {spacerSetted} = this.state
    if (!spacerSetted){
      const mobileCtaElem = document.getElementById(mobileCta)
      if (mobileCtaElem){
        this.setState({spacerSetted: true})
        setBottomSpacer(mobileCtaElem.offsetHeight)  
      }
    }
  }

  componentDidMount(){
    this.updateCourse()
    this.updateMobileSpacer()
  }

  componentDidUpdate(prevProps){
    const {isDataReady} = this.props
    const {spacerSetted} = this.state
    this.updateMobileSpacer()
    if(prevProps.isDataReady && !isDataReady && spacerSetted){
      this.setState({spacerSetted: false})
    }
  }

  componentWillUnmount(){
    this.props.setBottomSpacer(null)
  }
}

const mapState = state => {
  return {
    user: selectors.getUser(state),
    hasCourseAccess: selectors.hasCourseAccess(state),
    getCourseFaculties: selectors.getCourseFaculties(state),
    isDataReady: selectors.getUserDataReady(state),
    getFacultyCta: selectors.getFacultyCta(state),
  }
}

const mapDispatch = dispatch => {
  return {
    setBottomSpacer: value => dispatch(actions.setMobileBottomSpacer(value))
  }
}

export default withRouter(connect(mapState, mapDispatch)(CoursePage))