import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import cx from 'classnames';
import {orderBy} from 'lodash';

import * as selectors from '../../reducers/selectors';
import CourseCard from '../CourseCard/CourseCard';
import TeacherCard from '../TeacherCard/TeacherCard';
import { Spinnable, IntroSection } from '../UI';
import FacultyExtra from '../FacultyExtra/FacultyExtra';
import UpLogo from '../UI/UpLogo/UpLogo';
import FacultyTop from '../FacultyTop/FacultyTop';
import News from '../News/News';
import FacultyLive from '../FacultyLive/FacultyLive';

import style from './Faculty.module.css';

function FacultyCourses({faculty}) {
  if (faculty.courses === null) return null
  const description = "Scegli quello che fa per te!"
  const coursesOrdered = orderBy(faculty.courses, ['id'], ['acs'])

  return (
    <IntroSection
      title={<h2>Prodotti e servizi</h2>}
      description={
        <IntroDescription 
          description={description} 
          className={style.coursesIntroDescription}
        />
      }
      className={style.coursesIntro}
    >
      <Row className={cx(style.courseWrapper, style.coursesList)}>
      {coursesOrdered.map((course, idx) => 
        <Col xs={12} md={6} lg={4} xl={3} key={idx}>
          <CourseCard course={course} key={idx} />
        </Col>)}  
      </Row>
    </IntroSection>
  )
}

function IntroDescription({description, className}){
  return(
    <p className={cx('big', style.introDescription, className)}>{description}</p>
  )
}

function UpSkill({faculty}){
  const IntroTitle = ({name}) => {
    return(
      <div className={style.introTitle}>
        <div className={style.logoWrapper}>
          <UpLogo color="#25248E"/>
        </div>
        <h2> Skill</h2>
      </div>
    )
  }

  const concepts = faculty.concepts.sort((a,b) => (a.id - b.id) ).map(item => item.title)
  const audiences = faculty.audience.split(/\n/)

  return(
    <IntroSection
      title={<IntroTitle/>}
      className={style.coursesIntro}>
      <Row>
        <Col xs="12" md="12" lg="4" className={style.reasonCol}>
          <h5><strong>Perché iscriversi?</strong></h5>
          <p className={cx("big", style.grayColor)}>{faculty.reason}</p>
        </Col>
        <Col xs="12" md="6" lg="4" className={style.audienceCol}>
          <h5><strong>Per chi è?</strong></h5>
          {audiences.map((item, idx) => <p key={idx} className={cx("big", style.audiences)}>{item}</p>)}
        </Col>
        <Col xs="12" md="6" lg="4" className={style.conceptsCol}>
          <h5><strong>Cosa imparerai?</strong></h5>
          <ul>
          {concepts.map((item, idx) => 
            <li key={idx}><span className="oi oi-circle-check" aria-hidden="true"></span>
              <p className={cx("big", style.concepts)}>{item}</p>
            </li>)}
          </ul>
        </Col>
      </Row>
    </IntroSection>
  )
}

export function Teachers({teachers}){
  const IntroTitle = () => {
    return <h2>I relatori</h2>
  }
  const description="Impara tutto quello che c'è da sapere direttamente da chi sperimenta ogni giorno sulla sua pelle"
  if (!teachers) return null
  
  return(
    <IntroSection
      title={<IntroTitle/>}
      className={style.coursesIntro}
      description={
        <IntroDescription 
          description={description} 
          className={style.teachersIntroDescription}/>
      }
    >
      <Row className={style.courseWrapper}>
      {teachers.map(teacher => 
        <Col xs={12} md={6} lg={4} key={teacher.id}>
          <TeacherCard teacher={teacher}/>
        </Col>)}  
      </Row>
    </IntroSection>
  )
}

class Faculty extends Component{

  state = {
    academy: {},
    checkout: false,
  }

  facultyId() {
    return Number(this.props.match.params.id)
  }

  faculty() {
    return this.props.faculty(this.facultyId())
  }

  render(){
    const faculty = this.faculty()
    const {user, hasAccess} = this.props
    const teachers = faculty ? faculty.teachers : [] 
    
    return (
      <Spinnable item={faculty}>
        <FacultyTop 
          faculty={faculty} />
        <FacultyLive
          faculty={faculty} />
        <FacultyCourses 
          faculty={faculty} />
        <FacultyExtra 
          faculty={faculty} 
          user={user} 
          hasAccess={hasAccess}/>
        <UpSkill 
          faculty={faculty}/>
        <News 
          faculty={faculty}
          user={user} 
          hasAccess={hasAccess}/>
        <Teachers 
          teachers={teachers}/>
      </Spinnable>
    )
  }
}

const mapState = (state) => {
  return {
    user: selectors.getUser(state),
    faculties: selectors.getFaculties(state),
    faculty: selectors.getFaculty(state),
    isDataReady: selectors.getUserDataReady(state),
    hasAccess: selectors.hasFacultyAccess(state),
  }
}

export default withRouter(connect(mapState)(Faculty))
export {IntroDescription}