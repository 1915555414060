import React from 'react';
import CircularProgressbar from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import './progressCircle.css';

function ProgressCircle({percentage}){
  return (
    <CircularProgressbar 
      percentage={percentage}
      text={`${percentage}%`}
    />
  )
}

export default ProgressCircle

