import React from 'react';
import { Row, Col } from 'reactstrap';
import cx from 'classnames';
import _ from 'lodash';

import {getLessonsSeconds, secondsToHours} from '../UI/StatsBadge/StatsBadge'
import {onlyDesktop, onlyMobile} from '../../utilities/styleClasses';
import {ActionButton, Mode as ButtonMode, RedButton} from '../UI/Buttons';
import style from './CourseDescriptionBox.module.css';

function Cta(props){
  const {
    user, 
    course, 
    hasCourseAccess, 
    facultyCta,
    actionCallback,
   } = props

  const haveUser = !_.isEmpty(user)
  const courseAccess = hasCourseAccess(course)
  
  const hasLanding = !_.isEmpty(facultyCta)
  const showCourseButton = haveUser && courseAccess
  const showBuyFromLanding = haveUser && !courseAccess && hasLanding

  return(
    <div className={style.cta}>
      <div >
        {showBuyFromLanding && facultyCta.url !== "" &&
          <div >
            <a href={facultyCta.url}>
              <RedButton>
                {facultyCta.cta}
              </RedButton>
            </a>
          </div>
        }
        {showCourseButton &&
          <ActionButton 
            callback={actionCallback} 
            text="INIZIA" 
            mode={ButtonMode.small}
          />
        }
      </div>
    </div>
  )
}

function CourseDescriptionBox(props){
  const {course, course: {lessons}, onLessonStart, hasCourseAccess, user, facultyCta} = props

  const hours = secondsToHours(getLessonsSeconds(lessons))

  const lessonsString = lessons.length === 1 ? 'video' : 'video'
  const hoursString = hours === 1 ? 'ora' : 'ore'

  const lessonsText = (
    <p>
      <strong>{lessons.length}</strong> {lessonsString}  |  <strong>{hours}</strong> {hoursString}
    </p>
  )

  const content = (
    <>
      <Col xs={12} className={cx(style.contentCol, style.objective)}>
        <div className={style.contentWrapper}>
          <h4>{course.objective}</h4>
        </div>
      </Col>
      <Col xs={12} className={cx(style.contentCol, style.lessonLength)}>
        <div className={style.contentWrapper}>
          {lessons && lessonsText}
        </div>
      </Col>
      <Col xs={12} className={cx(style.contentCol, style.description)}>
        <div className={style.contentWrapper}>
          <p>{course.description}</p>
        </div>
      </Col>
      <Col xs={12} className={cx(style.contentCol, style.startCourse)}>
        <div className={style.contentWrapper}>
          <Cta 
            user={user} 
            course={course} 
            hasCourseAccess={hasCourseAccess} 
            facultyCta={facultyCta} 
            actionCallback={() => onLessonStart(lessons[0])}
          />
        </div>
      </Col>
    </>
  )

  return (
    <>
      <Row className={onlyDesktop}>
        <div className={style.desktopRoot}>
        {content}
        </div>
      </Row>
      <Row className={cx(onlyMobile, style.mobileRoot)}>
        {content}
      </Row>
    </>
  )
}

export default CourseDescriptionBox