import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';

import style from './VideoPlayer.module.css';

class YoutubePlayer extends Component{
  ref = React.createRef()
  state={
    ready: false,
    player: null,
  }

  handleReady = event => {
    const {onReady} = this.props
    // https://developers.google.com/youtube/iframe_api_reference
    const player = event.target
    onReady()
    setTimeout(() => this.setState({ready: true, player: player}),100)
    event.target.mute()
    event.target.playVideo()
  }

  render(){
    const {id, light, className, onPause, onPlay, onError, onEnded} = this.props
    const opacity = this.state.ready ? style.opacityOne : style.opacityZero

    const id_youtube = (id+"").substring((id+"").lastIndexOf('/') + 1)

    // https://developers.google.com/youtube/player_parameters
    // autoplay seems not to work on mobile, even with mute on
    // so we try to play video when it's ready in handleReady
    const opts = {
      width: '100%',
      height: '100%',
      playerVars: { 
        // autoplay: 1,
        enablejsapi: 1,
        modestbranding: 1,
        playsinline: 1,
      }
    };

    return (
      <YouTube
        videoId={id_youtube}
        opts={opts}
        containerClassName={cx(style.playerRoot, opacity, className)}
        onReady={this.handleReady}
        onPause={onPause}
        onPlay={onPlay}
        onError={onError}
        onEnded={onEnded}
        light={light}
      />
    )
  }

  componentDidUpdate(prevProps){
    if (this.props.id && (this.props.id !== prevProps.id)){
      this.setState({ready: false})
    }
  }
}

YoutubePlayer.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onReady: PropTypes.func,
  onPlay: PropTypes.func,
  onEnded: PropTypes.func,
  onPause: PropTypes.func,
  onError: PropTypes.func,
}

YoutubePlayer.defaultProps = {
  light: false,
  className: '',
  onReady: () => {},
  onPlay: () => {},
  onEnded: () => {},
  onPause: () => {},
  onError: () => {},
}

export default YoutubePlayer
