import React, { Component } from "react";
import Slider from "react-slick";
import PropTypes from 'prop-types';
import cx from 'classnames';

import ArrowRight from '../UI/ArrowRight/ArrowRight';
import ArrowLeft from '../UI/ArrowLeft/ArrowLeft';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './customSlider.css';
import style from './CustomSlider.module.css';

function resetSelectedItemClass(className){
  const target = document.querySelector(`.${className}`)
  if (target){
    target.classList.remove(className)
  }
}

function arrowClick(onClick, resetSelection, selectedClass){
  if (onClick){
    resetSelectedItemClass(selectedClass)
    onClick()
    if (resetSelection) resetSelection()
  }
}

function Arrow(props){
  const {
    className, 
    onClick, 
    resetSelection, 
    selectedClass, 
    component} = props;
  return (
    <div 
      className={className} 
      style={{zIndex: 10}}
      onClick={() => arrowClick(onClick, resetSelection, selectedClass)}
    >
      {component}
    </div>
  )
}

function PrevArrow(props){
  return <Arrow {...props} component={<ArrowLeft/>}/>
}

function NextArrow(props){
  return <Arrow {...props} component={<ArrowRight/>}/>
}

const initialSettings = {
  selectedIndex: null,
  current: 0,
  itemClick: false,
}

class CustomSlider extends Component {
  state = initialSettings
  
  afterChange = (value) => {
    const {data, onSelection} = this.props
    const newState = {current: value, selectedIndex: null}
    if (this.state.itemClick){
      newState.selectedIndex = value
      newState.itemClick = false
      onSelection(data[value])
    }
    this.setState(newState)
  }

  settings = {
    focusOnSelect: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 500,
    afterChange: this.afterChange,
  }
  mergedSettings = Object.assign(this.settings, this.props.settings)

  handleClick = (item, idx) => {
    const {
      data, 
      onClick, 
      goTo, 
      onSelection, 
      doNothing, 
      selectedClass} = this.props
    if (doNothing) return
    const {current, selectedIndex} = this.state
    const clickOnSelected = idx === selectedIndex
    if (clickOnSelected) return
    const clickOnCurrent = current === idx
    const isLastSlideStock = current >= (data.length - this.mergedSettings.slidesToShow)
    
    if (isLastSlideStock || clickOnCurrent){
      // immediatly set 'selectedIndex' and call callback
      this.setState({selectedIndex: idx})
      onSelection(item)
    }else{
      // do all the work in afterChange method (if goTo)
      if (goTo && selectedIndex !== null){  
        resetSelectedItemClass(selectedClass)
      }

      if (goTo){
        this.setState({itemClick: true},() => {
          if(goTo) this.slider.slickGoTo(idx)  
        })
      }else{
        this.setState({selectedIndex: idx})
        onSelection(item)
      }
    }
    
    if (onClick) onClick(item)
  }

  // if we pass dataKey and change it on data change,
  // Slider will be reInitialized and current slide come back to the first
  // https://stackoverflow.com/a/57877031
  render() {
    const {
      data, 
      ItemComponent, 
      selectedClass, 
      displayBlock, 
      onSelection, 
      wrapperClassName,
      dataKey,
    } = this.props
    const {selectedIndex} = this.state
    
    const resetSelection = selectedIndex === null ? null : () => onSelection()
    
    const arrows = {
      nextArrow: <NextArrow 
                  resetSelection={resetSelection} 
                  selectedClass={selectedClass}/>,
      prevArrow: <PrevArrow 
                  resetSelection={resetSelection} 
                  selectedClass={selectedClass}/>,
    }
    Object.assign(this.mergedSettings, arrows)
    
    // Default container <div> has display: inline-block,
    // and this cause strange bottom space, fixed with display: block
    // in css module (.sliderWrapper > div .itemContainer)
    return (
      <div className={style.sliderWrapper} key={dataKey}>
        <Slider ref={c => (this.slider = c)} {...this.mergedSettings}>
          {data.map((item, idx) => {
            const selectionClass = idx === selectedIndex ? selectedClass : ""
            return (
              <div 
                key={item.id} 
                onClick={() => this.handleClick(item, idx)} 
                className={displayBlock ? style.itemContainer : ''}
              >
                <div className={cx(style.itemWrapper, wrapperClassName)}>
                  <div className={selectionClass}>
                    <ItemComponent item={item} number={idx+1}/>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    );
  }
}

CustomSlider.propTypes = {
  data: PropTypes.array.isRequired,
  wrapperClassName: PropTypes.string,
  ItemComponent: PropTypes.func.isRequired,
  settings: PropTypes.object,
  selectedClass: PropTypes.string,
  displayBlock: PropTypes.bool,
  goTo: PropTypes.bool,
  onClick: PropTypes.func,
  onSelection: PropTypes.func,
  doNothing: PropTypes.bool,
  dataKey: PropTypes.number,
}

CustomSlider.defaultProps = {
  goTo: true,
  displayBlock: false,
  onSelection: () => {},
  doNothing: false,
}

export default CustomSlider