import {pathSegments} from '../App';

export function getCourseUrl(course){
  return `/${pathSegments.academies}/${course.academy_id}/${pathSegments.courses}/${course.id}`
}

export function getViewCourseUrl(course){
  return `/${pathSegments.academies}/${course.academy_id}/${pathSegments.viewcourse}/${course.id}`
}

export function getFacultyUrl(faculty){
  return `/${pathSegments.academies}/${faculty.id}`
}

export function getPillSerieUrl(serie){
  return `/${pathSegments.academies}/${serie.faculty_id}/${pathSegments.pillSerie}/${serie.id}`
}

export function getTmUrl(){
  return `/${pathSegments.tm}`
}