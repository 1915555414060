import React, {Component, useState} from 'react';
import {connect} from 'react-redux'
import {Collapse, Container, Row, Col} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import cx from 'classnames';
import _ from 'lodash';

import * as selectors from '../../reducers/selectors';
import {onlyDesktop, onlyMobile, onlyDesktopFlex} from '../../utilities/styleClasses';
import {
  ActionButton, 
  ActionType, 
  Mode as ButtonMode,
  PlayButton} from '../UI/Buttons';
import {
  logIn, 
  loggingEmitter, 
  AccessEvent} from '../AccessManager/utilities';
import {
  courseCheckout,
  checkout, 
  checkoutEmitter, 
  CheckoutEvent, 
  coursePlansInfo} from '../Checkout/utilities';
import {getViewCourseUrl} from '../../utilities/nav';
import {getModulesWithLessons} from '../../utilities/lessons';
import {CollapseIcon} from '../UI';
import {isLessonComplete, getLessonProgress} from '../../utilities/reactions';

import style from './CourseContent.module.css';

const flexAlign = "d-flex align-items-center"
const flexCenter = "align-items-center justify-content-center"

function FreeLessonButton({view}){
  if (!view) return null
  return(
    <ActionButton 
      action={ActionType.default } 
      mode = {ButtonMode.small }
      text="GRATIS PER TE"   
      className={cx(style.freeButton, 'no-shadow')}/>
  )
}

function Lock(){
  return(
    <div className={style.lock}/>
  )
}

function ModuleItem({moduleItem, idx, isOpen, toggleOpen, onLessonPlay, hasCourseAccess}){
  const [openComplete, setOpenComplete] = useState(false)
  let rootClass = style.moduleItemRoot
  let borderClass = style.onlyBorder
  if (isOpen) {
    rootClass = cx(rootClass, style.moduleSelected)
    borderClass = cx(borderClass, style.moduleOpen)
  }
  // TODO
  // lots of render here, don't want
  moduleItem.lessons.map(el => {
    const getPerc = progress => {
      return progress.current * 100 / progress.total
    }
    const progress = getLessonProgress(el)
    const percentage = progress.current ? getPerc(progress) : 0
    el.percentage = percentage
    return el
  })

  const handleOpen = () => {
    setOpenComplete(true)
  }

  const handleClose = () => {
    setOpenComplete(false)
  }
  
  return(
    <>
      <Col xs={12}>
        <Row 
          className={rootClass} 
          onClick={() => toggleOpen(moduleItem.module)}
        >
          <Col xs={2} md={1} className={flexAlign}>
            <div className={style.number}>
              <h5 className={onlyDesktop}>{idx}</h5>
              <h4 className={onlyMobile}>{idx}</h4>
            </div>
          </Col>
          <Col xs={8} md={10} className={flexAlign}>
            <h5>{moduleItem.module.title} ({moduleItem.lessons.length})</h5>
          </Col>
          <Col xs={2} md={1} className={cx(flexAlign, flexCenter)}>
            <CollapseIcon isOpen={isOpen}/>
          </Col>
        </Row>
      </Col>
      <Collapse 
        isOpen={isOpen} 
        onEntered={handleOpen} 
        onExited={handleClose} 
        style={{width: '100%'}}
      >
      {moduleItem.lessons.map((lesson, lessonIdx) => 
        <LessonItem 
          key={lessonIdx}
          idx={lessonIdx+1}
          moduleId={idx}
          lesson={lesson} 
          onLessonPlay={onLessonPlay}
          hasCourseAccess={hasCourseAccess}
          isOpen={openComplete}/>  
      )}
      </Collapse>
      <div className={borderClass}/>
    </>
  )
}

function LessonTitle({lesson, lessonId, moduleId}){
  const lessonNum = `${moduleId}.${lessonId}`
  return(
    <div className={style.title}>
      <p className="big">{lessonNum} {lesson.title}</p>
    </div>
  )
}

function LessonItem({lesson, idx, moduleId, hasCourseAccess, onLessonPlay, isOpen}){
  const viewFreeBadge = !hasCourseAccess && lesson.free
  const showPlay = lesson.video !== ""
  const complete = isLessonComplete(lesson)
  const handlePlay = () => onLessonPlay(lesson)
  let percentage = 0
  if(isOpen) percentage = lesson.percentage

  return(
    <Col xs={12} className={style.lessonRoot} onClick={handlePlay}>
      <div className={style.shado}>
        <Row className={style.lessonFirstRow}>
          <Col xs={10} md={9} className={style.titleWrap}>
            <LessonTitle 
              lesson={lesson} 
              lessonId={idx} 
              moduleId={moduleId}
            />
            <div className={onlyMobile}>
              <FreeLessonButton view={viewFreeBadge}/>
            </div>
            <div className={cx(onlyDesktop, style.desktopDescription)}>
              <p>{lesson.description}</p>
            </div>
          </Col>
          <Col md={2} className={cx(onlyDesktopFlex, flexCenter)}>
            <div>
              <FreeLessonButton view={viewFreeBadge}/>
            </div>
          </Col>
          <Col xs={2} md={1} className={cx("d-flex", flexCenter)}>
            {showPlay ?
              <PlayButton complete={complete} percentage={percentage}/> :
              <Lock/>
            }
          </Col>
        </Row>
        {lesson.description &&
        <Row className={cx(onlyMobile, style.mobileDescription)}>
          <Col xs={12}>
            <div>
              <p>{lesson.description}</p>
            </div>
          </Col>
        </Row>
        }
      </div>      
    </Col>
  )
}

class CourseContent extends Component{
  // Introduce logged state because we have to check
  // when user just logged and we wait for data (disable module click).
  
  // waitForUser is set to true when login dialog is open
  // will set ot false in componentDidUpdate
  state={
    moduleCollapse: null,
    waitForUser: false,
    selectedLesson: null,
    logged: false,
  }

  constructor(props){
    super(props)
    
    // Listen to this event when user close login dialog
    this.loginExitListener = () => {
      this.setState({waitForUser: false, selectedLesson: null})
    }

    this.loginSuccessListener = () => {
      this.setState({logged: true})
      props.onCourseUpdate()
    }

    this.loginLogoutListener = () => {
      this.setState({logged: false})
    }

    this.checkoutSuccessListener = () => {
      props.onCourseUpdate()
    }
    
    loggingEmitter.on(AccessEvent.Exit, this.loginExitListener)
    loggingEmitter.on(AccessEvent.Success, this.loginSuccessListener)
    loggingEmitter.on(AccessEvent.Logout, this.loginLogoutListener)
    checkoutEmitter.on(CheckoutEvent.Success, this.checkoutSuccessListener)
  }

  handleLessonPlay = lesson => {
    const {
      user, 
      history, 
      hasCourseAccess, 
      course,
      facultyCta,
    } = this.props
    if(_.isEmpty(user)){
      this.setState({waitForUser: true, selectedLesson: lesson})
      logIn()
    }else if (lesson.video !== ""){
      const nextUrl = `${getViewCourseUrl(course)}?lesson=${lesson.id}`
      history.push(nextUrl)
    }else if (!hasCourseAccess){
      const courseInfo = coursePlansInfo(course)
      const hasCoursePlan = courseInfo.coursePlan !== null
      const hasFacultyPlan = courseInfo.facultyPlan !== null
      const canBuyFromLanding = !_.isEmpty(facultyCta)
      if (canBuyFromLanding){
        if (facultyCta.url !== ""){
          window.location.href = facultyCta.url
        }else{
          // TODO
          // Show or do something?
        }
        
      }else if (hasCoursePlan && !canBuyFromLanding){
        courseCheckout(course)  
      }else if(hasFacultyPlan && !canBuyFromLanding){
        checkout(courseInfo.faculties[0])
      }
    }
  }

  toggleModuleOpen = cModule => {
    let moduleCollapse = cModule.id
    if (this.state.moduleCollapse === cModule.id){
      moduleCollapse = null
    }
    this.setState({moduleCollapse})
  }

  render(){
    const {
      course, 
      hasCourseAccess,
      user} = this.props
    const {moduleCollapse, logged} = this.state
    let disableModules = false
    // Wait for user to allow interaction with module
    if (logged && _.isEmpty(user)) disableModules = true
    let rowClass = style.moduleRow
    if (disableModules) rowClass = cx(rowClass, style.rowDisabled)
    
    if (_.isEmpty(course)) return null

    const modulesLessons = getModulesWithLessons(course)
    
    return(
      <Container className={style.root}>
        <h3>Contenuti</h3>
        <Row className={rowClass}>
        {modulesLessons.map((moduleItem, idx) => 
          <ModuleItem 
            key={idx}
            idx={idx+1}
            moduleItem={moduleItem} 
            isOpen={moduleCollapse === moduleItem.module.id}
            toggleOpen={this.toggleModuleOpen}
            onLessonPlay={this.handleLessonPlay}
            hasCourseAccess={hasCourseAccess}/>  
        )}
        {disableModules &&
        <Col xs={12} className={style.lessonOverlay}>
          <div></div>
        </Col>
        }
        </Row>
      </Container>
    )
  }

  componentDidMount(){
    const {user} = this.props
    if(!_.isEmpty(user)){
      this.setState({logged: true})
    }
  }
  
  componentWillUnmount(){
    loggingEmitter.removeListener(AccessEvent.Exit, this.loginExitListener)
    loggingEmitter.removeListener(AccessEvent.Success, this.loginSuccessListener)
    loggingEmitter.removeListener(AccessEvent.Logout, this.loginLogoutListener)
    checkoutEmitter.removeListener(CheckoutEvent.Success, this.checkoutSuccessListener)
  }

  componentDidUpdate(prevProps){
    const {
      isDataReady, 
      user, 
      course, 
      history, 
      onCourseUpdate,
      courseReady} = this.props
    const {waitForUser, selectedLesson} = this.state
    
    // User Checkout
    if (!_.isEmpty(prevProps.user) && _.isEmpty(user) ){
      onCourseUpdate()
    }

    // User click on a lesson and login, 
    // now we have user and all data is ready
    if (waitForUser && !_.isEmpty(user) && isDataReady && courseReady){
      if (selectedLesson){
        const lessonUpdated = course.lessons.find(l => l.id === selectedLesson.id)
        if (lessonUpdated.video !== ""){
          const nextUrl = `${getViewCourseUrl(course)}?lesson=${selectedLesson.id}`
          history.push(nextUrl)
        }
      }
        
      this.setState({waitForUser: false, selectedLesson: null})
    }
  }
}

const mapState = state => {
  return{
    isDataReady: selectors.getUserDataReady(state),
  }
}

export default withRouter(connect(mapState)(CourseContent))