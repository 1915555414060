import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Modal, ModalHeader, ModalBody} from 'reactstrap'

import * as actions from '../../actions';
import * as selectors from '../../reducers/selectors';
import {checkoutEmitter, CheckoutEvent, success} from './utilities'
import Checkout from './Checkout';

class CheckoutManager extends Component{
  constructor(props){
    super(props)

    this.state = {
      checkoutInProgress: false,
      plan: null,
      popup: false,
      faculty: null,
      course: null,
    }

    checkoutEmitter.on(CheckoutEvent.Checkout, (faculty) => {
      this.setState({checkoutInProgress: true, faculty: faculty})
      const plan = this.props.getFacultyPlan(faculty.id)  
      if (plan) this.setState({plan: plan.id})
    })

    checkoutEmitter.on(CheckoutEvent.CourseCheckout, (course) => {
      this.setState({checkoutInProgress: true, course: course})
      const plan = this.props.getCoursePlan(course.id)  
      if (plan) this.setState({plan: plan.id})
    })
  }

  hideCheckout = () => {
    this.setState({
      plan: null, 
      checkoutInProgress: false, 
      course: null, 
      faculty: null})
  }

  updateUserData = () => {
    const {user, loadAccesses, loadFaculties} = this.props
    this.setState({
      plan: null, 
      checkoutInProgress: false, 
      popup: true})
    success()
    loadFaculties()
    loadAccesses(user.id)
  }

  hidePopup = () => {
    this.setState({
      popup: false,
      faculty: null,
      course: null
    })
  }
  
  render(){
    const {plan, faculty, popup, course} = this.state
    const {isDataReady} = this.props
    if(!isDataReady) return null
    const planName = (faculty && faculty.name) || (course && course.name)
    return(
      <>
        <Checkout
          plan={plan}
          show={plan !== null}
          dismiss={this.hideCheckout}
          success={this.updateUserData}
        />
        <Modal 
          isOpen={popup} 
          toggle={this.hidePopup} 
          centered={true}
        >
          <ModalHeader toggle={this.hidePopup}></ModalHeader>
          <ModalBody>
            <p className="big text-center">Complimenti hai appena acquistato {planName}</p>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

const mapState = state => {
  return {
    user: selectors.getUser(state),
    isDataReady: selectors.getUserDataReady(state),
    getFacultyPlan: selectors.getFacultyPlan(state),
    getCoursePlan: selectors.getCoursePlan(state),
  }
}

const mapDispatch = dispatch => {
  return {
    loadFaculties: () => dispatch(actions.loadFaculties(true)),
    loadAccesses: user => dispatch(actions.loadAccesses(user)),
  }
}

export default connect(mapState, mapDispatch)(CheckoutManager)