import { store } from '../index';
import * as actions from '../actions';
import { client } from '../api/Client';
import * as selectors from '../reducers/selectors';
import _ from 'lodash';

export const timeMarginToCompleteLesson = 10

export const setFavourite = ({course_id, value}) => {
  store.dispatch(actions.setFavourite({course_id, value}))
  const user = selectors.getUser(store.getState())
  if(value){
    client.addFavourite(user.id, course_id)
  }else{
    client.removeFavourite(user.id, course_id)
  }
}

const shouldSkipVideoProgress = ({total, current, seconds}) => {
  const roundSeconds = Math.floor(seconds)
  // can't send seconds=0 to backend
  const shouldSkip =
    roundSeconds === 0 ||
    isVideoCompleteFromProgress({total, current}) ||
    seconds <= current
  return shouldSkip
}

export const isVideoCompleteFromProgress = ({total, current}) => {
  if(!current || ((total - current) > timeMarginToCompleteLesson)) return false
  return true
}

export const setLessonProgress = ({lesson, seconds}) => {  
  const state = store.getState()
  const {total, current} = getLessonProgress(lesson)
  if (shouldSkipVideoProgress({total, current, seconds})) return
  
  const roundSeconds = Math.floor(seconds)
  const user = selectors.getUser(state)
  client.setLessonProgress(user.id, lesson.id, roundSeconds)
    .then(response => {
      if(response.status === 200){
        store.dispatch(actions.setReaction({lesson, seconds: roundSeconds}))
      }
    })
}

export const setPillProgress = ({pill, seconds}) => {  
  const {total, current} = getPillProgress(pill)
  if (shouldSkipVideoProgress({total, current, seconds})) return
  
  const roundSeconds = Math.floor(seconds)
  const user = selectors.getUser(store.getState())
  client.setPillProgress(user.id, pill.id, roundSeconds)
    .then(response => {
      if(response.status === 200){
        store.dispatch(actions.setPercentage({pill, seconds: roundSeconds}))
      }
    })
}

export const getLessonProgress = lesson => {
  const {course_id} = lesson
  const reactions = selectors.getReactions(store.getState())
  const percentages = reactions[course_id] ? reactions[course_id].percentages : {}
  const lessonReaction = percentages[lesson.id]
  return lessonReaction || {}
}

export const isLessonComplete = lesson => {
  const {total, current} = getLessonProgress(lesson)
  return isVideoCompleteFromProgress({total, current})
}

export const getPillProgress = pill => {
  const percentages = selectors.getPillPercentage(store.getState())
  const pillProgress = percentages[pill.id]
  return pillProgress || {}
}

export const isPillComplete = pill => {
  const {total, current} = getPillProgress(pill)
  return isVideoCompleteFromProgress({total, current})
}

// TODO
// find a better way of calculate percentage
// cause we have a 'timeMarginToCompleteLesson' 
// and a lesson can be check as completed even if reaction.current != reaction.total
export const coursePercentage = course => {
  const reactions = selectors.getReactions(store.getState())
  let percentage = 0
  const courseReactions = reactions[course.id]
  if (courseReactions){
    percentage = Math.round(courseReactions.seconds / course.seconds * 100)
  }
  return percentage
}

// TODO
// see above
export const courseIsComplete = course => {
  const percentageLimit = 98
  const percentage = coursePercentage(course)
  if(percentage >= percentageLimit) return true
  return false
}

export const courseIsStarted = course => {
  const reactions = selectors.getReactions(store.getState())
  const courseReactions = reactions[course.id]
  if (courseReactions && courseReactions.seconds > 0){
    return true
  }
  return false
} 

export const courseNextLesson = course => {
  // if course is complete, return null
  // No reactions -> return first lesson
  // Is there a lesson with reaction not complete? -> return first not completed
  // Return first not viewed lesson

  if(courseIsComplete(course)) {
    return null
  }

  const reactions = selectors.getReactions(store.getState())
  const courseReactions = reactions[course.id]

  const orderedLessons = _.sortBy(course.lessons, ['id']) 

  const getFirstNotCompleteLessonId = () => {
    const notCompleteEntry = Object.entries(courseReactions.percentages).find(entry => !isVideoCompleteFromProgress(entry[1]))
    if (notCompleteEntry) return parseInt(notCompleteEntry[0])
    return null
  }

  const getNotStartedLessons = () => {
    return orderedLessons.filter(el => {
      return !isLessonComplete(el)
    })
  }

  const nextLesson = () => {
    // Course is not completed
    
    // new course, take the first lesson
    if(!courseReactions) return orderedLessons[0]
    
    // we have some reactions
    // maybe there is some not-completed lessons in reactions data
    const firstNotCompleteLessonId = getFirstNotCompleteLessonId()
    if(firstNotCompleteLessonId) return orderedLessons.find(el => el.id === firstNotCompleteLessonId)

    // now we are sure there is at least one complete lesson,
    // and some other never started, we take the first of the never-started
    const notStartedLessons = getNotStartedLessons()
    return notStartedLessons[0]
  }

  return nextLesson()
}