import React, {Component} from 'react';
import ReactPlayer from 'react-player';
import {Row, Col} from 'reactstrap';

import {ClickableProgress} from '../UI';
import {secondsToTime} from '../../utilities/time';
import backgroundImage from '../../assets/images/edgeaudio-575x405.jpg';
import playButton from '../../assets/images/play_button@2x.png';
import pauseButton from '../../assets/images/pause.png';

import style from './AudioPlayer.module.css';

function ProgressTime({duration, progress}){
  if(!duration) return null
  return(
    <div className={style.progressTime}>
      <p>{secondsToTime(progress)}/{secondsToTime(duration)}</p>
    </div>
  )
}

function PlayControlButton({play, callback}){
  const image = play ? pauseButton : playButton
  const alt = play ? "pause" : "play"
  return(
    <div className={style.controlButtonWrap} onClick={callback}>
      <img src={image} className="w-100 h-100" alt={alt}/>
    </div>
  )
}

class AudioPlayer extends Component{

  state={
    playing: false,
    played: 0,
    playedSeconds: 0,
    isStart: false,
  }

  soundCloudConfig = {
    options: {
      buying: false,
      sharing: false,
      download: false,
      show_artwork: false,
      show_playcount: false,
      show_user: false,
      start_track: false,
    }
  }

  ref = React.createRef()

  duration = 0

  togglePlay = () => {
    this.setState(prev => {
      return {playing: !prev.playing, isStart: true}
    })
  }

  handleProgress = ({played, playedSeconds}) => {
    this.setState({played, playedSeconds: Math.round(playedSeconds)})
  }

  handleProgressClick = progress => {
    this.ref.current.seekTo(progress, 'fraction')
  }

  handleDuration = duration => {
    this.duration = Math.round(duration)
  }

  customPlayerStyle = {
    backgroundImage: `url("${backgroundImage}")`,
  }

  useCustomPlayer = true

  render(){
    const {playing, played, isStart, playedSeconds} = this.state
    const {audio} = this.props
    let playerStyle = {}
    if (this.useCustomPlayer){
      playerStyle = {
        width: '100px', 
        height: '100px', 
        position: 'absolute',
        top: 0,
      }
    }
    
    return(
      <Row>
        <Col xs={12}>
          <div className={style.wrapperRoot}>
            <ReactPlayer
              url={audio}
              width='100%'
              height='100%'
              style={playerStyle}
              playing={playing}
              onProgress={this.handleProgress}
              onDuration={this.handleDuration}
              progressInterval={1000}
              ref={this.ref}
              config={{
                soundcloud: this.soundCloudConfig
              }}
            />
            <div className={style.customPlayerWrapper}  style={this.customPlayerStyle}>
              <div className={style.clickableArea} onClick={this.togglePlay} />
            </div>
            <PlayControlButton play={playing} callback={this.togglePlay}/>
            <div className={style.dataWrapper}>
              <ProgressTime duration={this.duration} progress={playedSeconds}/>
              <div className={style.progressWrapper}>
                <ClickableProgress 
                  value={played} 
                  onClick={this.handleProgressClick} 
                  disabled={!isStart}
                  className={style.progress}/>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default AudioPlayer