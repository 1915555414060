import event from 'events';
import _ from 'lodash';

import {store} from '../../index';
import * as selectors from '../../reducers/selectors';

export const CheckoutEvent = {
  Checkout: 'checkout',
  Success: 'checkout_success',
  CourseCheckout: 'course-checkout',
}

export const checkoutEmitter = new event()

export function checkout(faculty){
  checkoutEmitter.emit(CheckoutEvent.Checkout, faculty)
}

export function success(){
  checkoutEmitter.emit(CheckoutEvent.Success)
}

export function courseCheckout(course){
  checkoutEmitter.emit(CheckoutEvent.CourseCheckout, course)
}

// get course Plans and related faculties with its plans
// facultyPlan is related to first faculty in list, order by id
export function coursePlansInfo(course){
  const state = store.getState()
  const result = {
    plans: [],
    faculties: [],
    coursePlan: null,
    facultyPlan: null,
  }
  
  if (_.isEmpty(course)) return result
  const coursePlans = selectors.getCoursePlans(state)(course.id)
  const courseFaculties = selectors.getCourseFaculties(state)(course.id)
  const faculties = courseFaculties.map(e => {
    const faculty = _.pick(e, ['id', 'name', 'academy_id'])
    faculty.plans = selectors.getFacultyPlans(state)(e.id)
    return faculty
  })
  result.plans = coursePlans
  result.faculties = _.sortBy(faculties, 'id').filter(f => f.plans.length > 0)
  result.coursePlan = selectors.getCoursePlan(state)(course.id)
  result.facultyPlan = result.faculties[0] && selectors.getFacultyPlan(state)(result.faculties[0].id)
  return result 
}