import React, { Component } from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import {isEmpty, find} from 'lodash';
import cx from 'classnames';
import querySearch from 'stringquery';

import * as selectors from '../../reducers/selectors';
import { setLessonProgress, getLessonProgress } from '../../utilities/reactions';
import { api as client } from '../../api';
import CourseTitleBox from '../CourseTitleBox/CourseTitleBox';
import CourseLessonsBox from '../CourseLessonsBox/CourseLessonsBox';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import CourseDescriptionBox from '../CourseDescriptionBox/CourseDescriptionBox'
import {onlyMobile, onlyDesktop} from '../../utilities/styleClasses';
import {Spinnable} from '../UI';

import materialImage from '../../assets/images/material_image.png';
import materialArticle from '../../assets/images/material_text.png';
import materialDoc from '../../assets/images/material_doc.png';
import materialVideo from '../../assets/images/material_video.png';
import materialAudio from '../../assets/images/material_audio.png';

import style from './LoggedCoursePage.module.css';

const MaterialType = {
  Document: 'document',
  Article: 'article',
  Image: 'image',
  Video: 'video',
  Audio: 'audio',
}

const MaterialsMap = {
  '1': MaterialType.Document,
  '2': MaterialType.Article,
  '3': MaterialType.Image,
  '4': MaterialType.Video,
  '5': MaterialType.Audio,

}

const MaterialIconMap = {
  [MaterialType.Document]: materialDoc,
  [MaterialType.Article]: materialArticle,
  [MaterialType.Image]: materialImage,
  [MaterialType.Video]: materialVideo,
  [MaterialType.Audio]: materialAudio,
}

function MaterialLogo({material}){
  const image = MaterialIconMap[MaterialsMap[material.kind.toString()]]
  return(
    <img 
      src={image} 
      alt={material.name} 
      className={style.materialLogo}/>
  )
}

function Spin(){
  return(
    <div className={style.videoBoxSpin}>
      <Spinner color="primary" />
    </div>
  )
}

function VideoOverlay({className, teacher, lesson}){
  return(
    <div className={cx(style.absoluteFiller, onlyDesktop, style.overlay, className)}>
      <Row className="ml-0 mr-0 mt-3">
        {teacher &&
        <Col xs={1}>
          <div 
            className={style.olerlayTeacherImg} 
            style={{backgroundImage: `url("${teacher.profile}")`}}/>
        </Col>}
        <Col xs={11}>
          <Row>
            <Col xs={12}>
              <div className={cx(style.overlayText, style.overlayLesson)}>
                <p className="big"><strong>{lesson.title}</strong></p>
              </div>
            </Col>
            {teacher &&
            <Col xs={12}>
              <div className={cx(style.overlayText, style.overlayTeacherName)}>
                <p><strong>{teacher.name}</strong></p>
              </div>
            </Col>}
          </Row>
        </Col>
      </Row>
    </div>
  )
}

function Description({lesson}){
  const text = "Concetti chiave"
  return(
    <Col xs={12} className={style.videoBoxDescription}>
      <div className={style.videoBoxSpacer}>
        <h5 className={onlyDesktop}>{text}</h5>
        <h4 className={onlyMobile}>{text}</h4>
        <p className="big">{lesson.description}</p>
      </div>
    </Col>
  )
}

function Materials({materials}){
  if (!materials || (materials.length === 0)) return null
  return(
    <Col xs={12} className={style.materialsWrapper}>
      <Row>
        <Col xs={12}>
          <p 
            className={cx("big", style.videoBoxSpacer)} 
            style={{fontWeight: '900'}}>
              Allegati
          </p>
        </Col>
        {materials.map(item => {
          return (
            <Col xs={12} lg={3} key={item.id}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <div className={cx("d-flex", style.videoBoxSpacer)}>
                  <MaterialLogo material={item}/>
                  <p>{item.name}</p>
                </div>
              </a>
            </Col>)
        })}
      </Row>
    </Col>
  )
}

class VideoBox extends Component{
  state = {
    overlayReady: false,
  }
  timeoutId = null
  teacherOverlayDelay = 500

  overlayClassName = () => {
    const {play} = this.props
    const {overlayReady} = this.state
    if(!overlayReady) return style.overlayClose
    return play ? style.overlayClose : style.overlayOpen
  }

  render(){
    const {error, isReady, videoProps, videoProps:{selectedLesson}} = this.props
    const teacher = selectedLesson.teachers ? selectedLesson.teachers[0] : null
    const videoColPaddingTop = 1/(videoProps.aspectRatio) * 100
    const videoColStyle = {
      paddingTop: `${videoColPaddingTop}%`
    }
    return(
      <Row className={style.videoBoxRoot}>
        <Col xs={12} className={cx(onlyMobile, style.mobileLessonTitle)}>
          <div>
            <p className="big">{selectedLesson.title}</p>
          </div>
        </Col>
        <Col xs={12} className={style.videoCol} style={videoColStyle}>
          <div className={cx(style.absoluteFiller, style.videoWrapper)}>
            {error && <p className="text-center m-5"><strong>Some error occurred loading this video</strong></p>}
            {!isReady && !error && <Spin/>}
            <VideoPlayer 
              {...videoProps} 
              id={Number(selectedLesson.video)}/>
          </div>
          {isReady && 
          <VideoOverlay 
            className={this.overlayClassName()} 
            teacher={teacher} 
            lesson={selectedLesson}/>}
        </Col>
        {isReady && <Description lesson={selectedLesson}/>}
        {isReady && <Materials materials={selectedLesson.materials}/>}
      </Row>
    )
  }  

  componentDidUpdate(prevProps, props){
    const {videoProps: {selectedLesson: oldLesson}, isReady: isReadyOld} = prevProps
    const {videoProps: {selectedLesson: newLesson}, isReady} = this.props
    if(oldLesson.id !== newLesson.id){
      this.setState({overlayReady: false})
    }
    if (isReady !== isReadyOld && isReady){
      this.timeoutId = setTimeout(() => {
        this.setState({overlayReady: true})
        this.timeoutId = null
      }, this.teacherOverlayDelay)
    }
  }

  componentWillUnmount(){
    if(this.timeoutId) clearTimeout(this.timeoutId)
  }
}

class LoggedCoursePage extends Component{
  aspectRatioDefault = Math.round(16/9 * 1000)/1000
  
  state = {
    course: {},
    selectedLesson: null,
    play: false,
    waitForProgress: false,
    isVideoReady: false,
    nextSelectedLesson: null,
    startOn: 0,
    courseLoading: false,
    videoAspectRatio: this.aspectRatioDefault,
    error: false,
    videoHeight: null,
    videoWidth: null,
  }

  handleCourseClick = () => {
    const {selectedLesson, play} = this.state
    const {history, location: {pathname}} = this.props
    if(!selectedLesson) return
    
    history.replace(pathname)
    if (play) {
      // wait for handlePauseProgress to skip
      this.setState({play: false, waitForProgress: true, nextSelectedLesson: null})
    }else{
      this.setState({selectedLesson: null, isVideoReady: false, startOn: 0, error: false})
    }
  }

  handleLessonClick = (lesson) => {
    const {selectedLesson, play, waitForProgress, isVideoReady, error} = this.state
    const {history, location: {pathname}} = this.props
    const selectedLessonId = selectedLesson ? selectedLesson.id : null
    
    if (lesson.id === selectedLessonId || 
        waitForProgress ||
        (selectedLessonId && !isVideoReady && !error)) {
      return
    }
    
    const startOn = this.getStartOnTime(lesson)
    if (play) {
      // wait for handlePauseProgress to skip
      this.setState({play: false, waitForProgress: true, nextSelectedLesson: lesson})
    }else{
      history.replace(pathname+"?lesson="+lesson.id)
      this.setState({selectedLesson: lesson, isVideoReady: false, startOn, error: false})
      this.setVideoAspectRatio(lesson.video)
    }
  }

  handleVideoReady = () => {
    this.setState({isVideoReady: true})
  }

  handleVideoPlay = () => {
    const {selectedLesson} = this.state
    this.setState({play: true, nextSelectedLesson: selectedLesson})
  }

  handleVideoPause = () => {
    this.setState({play:false, waitForProgress: true})
  }

  handleVideoEnded = () => {
    this.setState({play: false})
    const {selectedLesson} = this.state
    setLessonProgress({lesson: selectedLesson, seconds: selectedLesson.duration})
  }

  handleVideoProgress = ({playedSeconds}) => {
    const {selectedLesson, play} = this.state    
    if (!play) return
    setLessonProgress({lesson: selectedLesson, seconds: playedSeconds})
  }

  // call after a pause, lesson-change or skip-to-course-description
  handlePauseProgress = (seconds) => {
    const {selectedLesson, nextSelectedLesson} = this.state
    const {history, location: {pathname}} = this.props
    setLessonProgress({lesson: selectedLesson, seconds: seconds})
    const lessonProgress = nextSelectedLesson ? this.getStartOnTime(nextSelectedLesson) : 0
    const isVideoReady = nextSelectedLesson ? nextSelectedLesson.id ===  selectedLesson.id : false
    if (nextSelectedLesson){
      history.replace(pathname+"?lesson="+nextSelectedLesson.id)
      this.setVideoAspectRatio(nextSelectedLesson.video)
    }
    this.setState({waitForProgress: false, selectedLesson: nextSelectedLesson, startOn: lessonProgress, isVideoReady})
  }

  handleVideoError = err => {
    this.setState({error: true})
  }

  setVideoAspectRatio = video_id => {
    client.getVimeoInfo(video_id)
      .then(r => {
        const {data: {width, height}} = r
        this.setState({videoWidth: width, videoHeight: height})
        const ratioRound = Math.round((width/height) * 1000)/1000
        // evita di presentare un video stretto e lungo, megio bande nere laterali
        if (ratioRound > this.aspectRatioDefault){
          this.setState({videoAspectRatio: ratioRound})
        }
      })
  }

  getStartOnTime = (lesson) => {
    const {current, total} = getLessonProgress(lesson)
    if (!current || current === total) return 0
    return current
  }

  faculty() {
    const {faculties, isDataReady} = this.props
    const {course} = this.state
    if (!isDataReady || isEmpty(course)) return {}
    return faculties.find(f => {
      return find(f.courses, {'id': course.id})
    })
  }

  render(){
    const {
      course, 
      selectedLesson, 
      waitForProgress, 
      startOn, 
      play, 
      isVideoReady, 
      videoAspectRatio,
      error} = this.state
    const {user, getFacultyCta, hasCourseAccess} = this.props

    let ctaData = {}
    const faculty = this.faculty()
    if (!isEmpty(faculty)) {
      ctaData = getFacultyCta(faculty.id)
    }

    const videoProps = {
      startOn: startOn,
      playing: play,
      selectedLesson: selectedLesson,
      aspectRatio: videoAspectRatio,
      onPauseProgress: this.handlePauseProgress,
      onProgress: this.handleVideoProgress ,
      onEnded: this.handleVideoEnded,
      onPause: this.handleVideoPause,
      onPlay: this.handleVideoPlay,
      onReady: this.handleVideoReady,
      onError: this.handleVideoError,
    }

    const showVideoBox = selectedLesson || waitForProgress
    
    return(
      <Spinnable item={course}> 
        <div className={style.root}>
          <Container fluid className={style.rootContainer}>
            <Row className={style.titleRow}>
              <Col xs={12} >
                <CourseTitleBox 
                  course={course} 
                  faculty={this.faculty()}/>
              </Col>
            </Row>
            <Row className={style.lessonBoxRow}>
              <Col xs={12} lg={3} className={style.lessonsBoxWrapper}>
                <CourseLessonsBox 
                  course={course} 
                  selectedLesson={selectedLesson}
                  onCourseClick={this.handleCourseClick}
                  onLessonClick={this.handleLessonClick}/>    
              </Col>
              <Col xs={12} lg={9} className={style.videoBoxWrapper}>
                {showVideoBox ? 
                <VideoBox 
                  videoProps={videoProps} 
                  isReady={isVideoReady}
                  play={play}
                  error={error}/>:
                <CourseDescriptionBox 
                  course={course} 
                  user={user}
                  onLessonStart={this.handleLessonClick}
                  hasCourseAccess={hasCourseAccess}
                  facultyCta={ctaData}/>
                }
              </Col>
            </Row>
          </Container>
        </div>       
      </Spinnable>
    )
  }

  loadCourse = () => {
    const {hasCourseAccess, match: {params: {courseId}}, location: {search, pathname}, history} = this.props
    this.setState({courseLoading: true})
    client.getCourse(courseId)
      .then(response => {
        const {course} = response.data
        const {lesson: lessonId} = querySearch(search)
        let selectedLesson = null
        let startOn = 0
        const courseAccess = hasCourseAccess(course)
        if (lessonId){
          selectedLesson = course.lessons.find(el => el.id.toString() === lessonId)
          if (selectedLesson && !courseAccess && selectedLesson.video === ""){
            selectedLesson = null
          }
          // TODO 
          // check if user has course access OR selectedLesson is free !!!
          // if nop, don't set selectedLesson, user will see CourseDescription with CTA
          if (selectedLesson){
            startOn = this.getStartOnTime(selectedLesson)
            this.setVideoAspectRatio(selectedLesson.video)
          }else{
            // lesson query param is not good,
            // clean url
            history.replace(pathname)
          }
        }
        
        this.setState({courseLoading: false, course, selectedLesson, startOn})
      })
      .catch(r => {
        console.error(r)
      })
  }

  componentDidUpdate(){
    const {isDataReady} = this.props
    const {
      courseLoading, 
      course, 
    } = this.state
    if (isDataReady && !courseLoading && isEmpty(course)){
      this.loadCourse()
    }
  }

  componentDidMount(){
    const {isDataReady} = this.props
    if (isDataReady){
      this.loadCourse()
    }
  }

}

const mapState = (state) => {
  return {
    user: selectors.getUser(state),
    isDataReady: selectors.getUserDataReady(state),
    faculties: selectors.getFaculties(state),
    hasCourseAccess: selectors.hasCourseAccess(state),
    getFacultyCta: selectors.getFacultyCta(state),
  }
}

export default connect(mapState)(withRouter(LoggedCoursePage))