import React from 'react';
import { Row, Col } from 'reactstrap';
import cx from 'classnames';

import {FacultyLogo} from '../UI';
import {onlyMobile, onlyDesktop} from '../../utilities/styleClasses';
import style from './CourseTitleBox.module.css';

function CourseTitleBox({course, faculty}){

  return (
    <Row className={style.root}>
      <Col xs={1} className={onlyDesktop}>
        <FacultyLogo 
          faculty={faculty} 
          className={style.facultyLogo}/>
      </Col>

      <Col className="d-flex flex-column">
        <Row>
          <Col xs={1} className={onlyMobile}>
            <FacultyLogo 
              faculty={faculty} 
              className={style.facultyLogo}/>
          </Col>
          
          <Col>
            <p className={cx("big", style.facultyName)}>
              {faculty.name && faculty.name.toUpperCase()}
            </p>
          </Col>
        </Row>
        <h3 className={onlyDesktop}>{course.name}</h3>
        <h4 className={onlyMobile}>{course.name}</h4>
      </Col>
    </Row>
  )
}

export default CourseTitleBox