import React, {Component} from 'react';
import {Container, Col, Row, Modal, ModalHeader, ModalBody} from 'reactstrap';
import Slider from "react-slick";
import moment from 'moment';
import 'moment/locale/it';
import cx from 'classnames';
import _ from 'lodash';
import {connect} from 'react-redux';

import CustomCard from '../UI/Card/Card';
import ArrowRight from '../UI/ArrowRight/ArrowRight';
import ArrowLeft from '../UI/ArrowLeft/ArrowLeft';
import LongArrowLeft from '../UI/LongArrowLeft/LongArrowLeft';
import LongArrowRight from '../UI/LongArrowRight/LongArrowRight';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import {AudioBadge} from '../UI';
import {onlyDesktopFlex, onlyDesktop, onlyMobile} from '../../utilities/styleClasses';
import {truncateText} from '../../utilities/text';
import {logIn} from '../AccessManager/utilities';
import {checkout} from '../Checkout/utilities';
import * as selectors from '../../reducers/selectors';

import coverDefault from '../../assets/images/gradient-background.jpg';
import coverAudio from '../../assets/images/edgeaudio-575x405.jpg';

import style from './News.module.css';
import './news.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ArticlePublished({article, className=''}){
  if(!article) return null
  const published = moment(article.published_at).locale('it')
  return(
    <p className={cx(style.articlePublished, className)}>
      <span>{published.format('D')}</span>
      <span className="text-capitalize"> {published.format('MMMM')}</span>
      <span> {published.format('YYYY')}</span>
    </p>
  )
}

function ArticleCard({article, onClick}){
  // https://github.com/jariz/react-truncate-html)
  const isAudioArticle = article.audio !== ""
  const cover = isAudioArticle ? article.cover || coverAudio : article.cover || coverDefault;
  const imageContainer = {
    backgroundImage: `url("${cover}")`
  }

  const audioBadgeClass = () => {
    return isAudioArticle ? "" : style.audioDisabled;
  }

  const title = truncateText({text: article.title, maxLength: 60}).text

  return (
    <CustomCard 
      className={cx(style.articleRoot, "articleRoot")} 
      onClick={() => onClick(article)}
    >
      <Col xs={12}>
        <div className={style.imageContainer} style={imageContainer}/>
        <div className={cx(style.audioBadge, audioBadgeClass())}>
          <AudioBadge/>
        </div>
      </Col>
      <Col xs={12} className={style.articlePublishedWrapper}>
        <ArticlePublished article={article}/>
      </Col>
      <Col xs={12} className={style.articleTitle}>
        <p>{title}</p>
      </Col>
      <Col>
        <div className={style.articleCta}>
          <p className="label">VAI ALLA NEWS</p>
        </div>
      </Col>
    </CustomCard>
  )

}

function ArticleModal({article, onClose}){
  const getMarkup = () => {
    return {
      __html: article ? article.body : '',
    }
  }
  const markup = getMarkup()
  const isOpen = article ? true : false

  const hasAudio = article ? article.audio !== "" : false

  return(
    <Modal 
      isOpen={isOpen} 
      toggle={onClose} 
      centered={true} 
      size="lg"
      className="articleModal"
    >
      <ModalHeader toggle={onClose} className={style.modalHeader}/>
      <ModalBody>
        <Container>
          <Row>
            <Col xs={12}>
              <ArticlePublished article={article}/>
            </Col>
            <Col xs={12}>
              <h3>{article && article.title}</h3>
            </Col>
            <Col xs={12}>
              {hasAudio && <AudioPlayer audio={article.audio}/>}
            </Col>
            <Col xs={12}>
              <div dangerouslySetInnerHTML={markup} />
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  )
}

function CustomArrow({className, component, onClick}){
  return(
    <div className={className} onClick={onClick}>
      {component}
    </div>
  )
}

function LongArrow({direction}){
  // binding with News component
  const settings = {
    prev:{
      onClick: this.handlePrevSlide,
      className: this.prevClass(),
      component: <LongArrowLeft />
    },
    next:{
      onClick: this.handleNextSlide,
      className: this.nextClass(),
      component: <LongArrowRight />
    }
  }
  const conf = settings[direction]
  return(
    <div className={style.sliderArrowWrap}>
      <div onClick={conf.onClick} className={conf.className}>{conf.component}</div>
    </div>
  )
}

class ArticleSlider extends Component{
  // slide change for Desktop is handled in render method with slickGoTo method
  // based on 'current' props from parent News component
  // so we have to tell parent component to update its state (onPrev, onNext)

  state={
    slide: 0,
  }

  afterChange = (idx) => {
    const {toggleWait, mobile} = this.props
    if (!mobile && this.state.slide !== idx){
      this.setState({slide: idx}, () => {
        toggleWait()
      })
    }
  }

  settings = {
    focusOnSelect: false,
    infinite: false,
    slidesToShow: this.props.slideToShow,
    slidesToScroll: 1,
    speed: 500,
    arrow: false,
    swipe: false,
    afterChange: this.afterChange,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          arrow: true,
          swipe: true,
          nextArrow: (
            <CustomArrow 
              component={<ArrowRight/>}/>
          ),
          prevArrow: (
            <CustomArrow 
              component={<ArrowLeft/>}/>
          ),
        }
      },
    ]
  }

  render(){
    const {articles, onClick, current, mobile} = this.props
    const {slide} = this.state
    if(!mobile && current !== slide && this.slider){
      this.slider.slickGoTo(current)
    }

    return(
      <div className={style.sliderRoot}>
        <Slider {...this.settings} ref={c => (this.slider = c)}>
        {articles.map((item, idx) => (
          <div key={idx} className={style.sliderItem}>
            <ArticleCard article={item} onClick={onClick}/>
          </div>
        ))}
        </Slider>
      </div>
    )
  }
}

class News extends Component{
  state={
    article: null,
    slide: 0,
    waitForChange: false,
  }

  slideToShow = 3

  articles = () => {
    const {faculty} = this.props
    return faculty ? faculty.articles : []
  }

  handleArticleClick = article => {
    const {faculty, user, hasAccess, getFacultyCta} = this.props
    const facultyCta = getFacultyCta(faculty.id)
    const doCheckout = !hasAccess(faculty) && _.isEmpty(facultyCta)
    const doLandingRedirect = !hasAccess(faculty) && !_.isEmpty(facultyCta)
    if (_.isEmpty(user)){
      return logIn()
    }else if(doCheckout){
      return checkout(faculty)
    }else if (doLandingRedirect && facultyCta.url !== ""){
      window.location.href = facultyCta.url
    }
    this.setState({article: article})
  }

  closeModal = () => {
    this.setState({article: null})
  }

  waitForChange = () => {
    const {waitForChange} = this.state
    return waitForChange
  }

  goToNext = () => {
    if (this.waitForChange()) return
    this.setState(prevState => {
      return {slide: prevState.slide+1, waitForChange: true}
    })
  }

  goToPrev = () => {
    if (this.waitForChange()) return
    this.setState(prevState => {
      return {slide: prevState.slide-1, waitForChange: true}
    })
  }

  handleNextSlide = () => {
    const {slide} = this.state
    if (this.waitForChange()) return
    if (slide < (this.articles().length - this.slideToShow)){
      this.goToNext()
    }
  }

  handlePrevSlide = () => {
    const {slide} = this.state
    if (this.waitForChange()) return
    if (slide > 0){
      this.goToPrev()
    }
  }

  toggleWait = () => {
    this.setState(prev => {
      return {waitForChange: !prev.waitForChange}
    })
  }

  nextClass = () => {
    let arrowClass = style.sliderArrowRight
    if (this.state.slide >= this.articles().length - this.slideToShow){
      arrowClass += ` ${style.disabled}`
    }
    return arrowClass
  }

  prevClass = () => {
    let arrowClass = style.sliderArrowLeft
    if(this.state.slide === 0){
      arrowClass  += ` ${style.disabled}`
    }
    return arrowClass
  }

  LongArrowBind = LongArrow.bind(this)

  render(){
    const {article: selectedArticle, slide} = this.state
    const text = "Le ultime notizie dal mondo del marketing selezionate e scritte da Marco Montemagno e dal suo team."
    const articles = this.articles()
    const sliderProps = {
      articles: articles,
      onClick: this.handleArticleClick,
      slideToShow: this.slideToShow,
      onNext: this.goToNext,
      onPrev: this.goToPrev,
      toggleWait: this.toggleWait,
    }

    if (articles.length === 0) return null

    return(
      <>
        <ArticleModal 
          article={selectedArticle} 
          onClose={this.closeModal}/>
        <Container fluid className={style.containerFluid}>
          <Container className={style.container}>
            <Row>
              <Col xs={12} lg={3}>
                <div className={cx(style.newsIntro, "newsIntro")}>
                  <h3>Ultime News</h3>
                  <p>{text}</p>
                  <div className={cx(onlyDesktopFlex, "flex-grow-1")}>
                    <div className="d-flex mt-auto">
                      <this.LongArrowBind direction="prev"/>
                      <this.LongArrowBind direction="next"/>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={9} className="articleSlider">
                <div className={onlyDesktop}>
                  <ArticleSlider 
                    {...sliderProps}
                    current={slide}
                    mobile={false}/>
                </div>
                <div className={onlyMobile}>
                  <ArticleSlider 
                    {...sliderProps}
                    current={slide}
                    mobile={true}/>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        
      </>
    )
  }
}

const mapState = state => {
  return {
    getFacultyCta: selectors.getFacultyCta(state),
  }
}

export default connect(mapState)(News)