export const SET_PROGRESS = 'SET_PROGRESS';
export const SET_PERCENTAGE = 'SET_PERCENTAGE';
export const SET_FAVOURITE = 'SET_FAVOURITE';
export const SET_SESSION_USER = 'SET_SESSION_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_USER_READY = 'SET_USER_READY';

export const LOAD_REACTIONS = 'LOAD_REACTIONS';
export const LOAD_FACULTIES = 'LOAD_FACULTIES';
export const LOAD_PLANS = 'LOAD_PLANS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGIN = 'USER_LOGIN';
export const SET_USER_DATA_READY = 'SET_USER_DATA_READY';
export const SET_DATA_READY = 'SET_DATA_READY';
export const LOAD_DATA = 'LOAD_DATA';
export const CLEAR_REACTIONS = 'CLEAR_REACTIONS';
export const LOAD_FACULTY_PLANS = 'LOAD_FACULTY_PLANS';
export const LOAD_COURSE_PLANS = 'LOAD_COURSE_PLANS';
export const LOAD_ACCESSES = 'LOAD_ACCESSES';
export const CLEAR_ACCESSES = 'CLEAR_ACCESSES';
export const LOAD_FACULTY_CTA = 'LOAD_FACULTY_CTA';

export const COURSE_LANDING_WAIT_FOR_USER = 'COURSE_LANDING_WAIT_FOR_USER';

export const SET_MOBILE_BOTTOM_SPACER = 'SET_MOBILE_BOTTOM_SPACER';