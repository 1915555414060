import React from 'react';
import cx from 'classnames';

import {Page} from '../AppBar/AppBar';

import style from './AppWrapper.module.css';

function AppWrapper({children, page}){
  let otherClass = ""
  if (page === Page.ViewCourse){
    otherClass = style.noScroll
  }

  return(
    <div className={cx(style.appWrapper, otherClass)}>
      {children}
    </div>
  )
}

export default AppWrapper