import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'reactstrap';

function LinkButton({text, link, className}){
  return(
    <Button tag={Link} to={link} className={className}>
      {text}
    </Button>
  )
}

export default LinkButton