import * as actionsType from './actionsTypes';

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
		acc[type] = `${base}_${type}`
		return acc
	}, {})
}

function action(type, payload) {
  return {type, ...payload}
}

export const USER = createRequestTypes('USER')
export const FACULTIES = createRequestTypes('FACULTIES')
export const REACTIONS = createRequestTypes('REACTIONS')
export const PLANS = createRequestTypes('PLANS')
export const FACULTY_PLANS = createRequestTypes('FACULTY_PLANS')
export const COURSE_PLANS = createRequestTypes('COURSE_PLANS')
export const ACCESSES = createRequestTypes('ACCESSES')
export const FACULTY_CTA = createRequestTypes('FACULTY_CTA')

export const user = {
  request: id => action(USER[REQUEST], {id}),
  success: (id, response) => action(USER[SUCCESS], {id, response}),
  failure: (id, error) => action(USER[FAILURE], {id, error}),
};

export const reactions = {
  request: id => action(REACTIONS[REQUEST], {id}),
  success: (id, response) => action(REACTIONS[SUCCESS], {id, response}),
  failure: (id, error) => action(REACTIONS[FAILURE], {id, error}),
}

export const faculties = {
  request: academy => action(FACULTIES[REQUEST], {academy}),
  success: (academy, response) => action(FACULTIES[SUCCESS], {academy, response}),
  failure: (academy, error) => action(FACULTIES[FAILURE], {academy, error}),
}

export const plans = {
  request: () => action(PLANS[REQUEST], {}),
  success: (response) => action(PLANS[SUCCESS], {response}),
  failure: (error) => action(PLANS[FAILURE], {error}),
}

export const facultyPlans = {
  request: faculty => action(FACULTY_PLANS[REQUEST], {faculty}),
  success: (faculty, response) => action(FACULTY_PLANS[SUCCESS], {faculty, response}),
  failure: (faculty, error) => action(FACULTY_PLANS[FAILURE], {faculty, error}),
}

export const coursePlans = {
  request: course => action(COURSE_PLANS[REQUEST], {course}),
  success: (course, response) => action(COURSE_PLANS[SUCCESS], {course, response}),
  failure: (course, error) => action(COURSE_PLANS[FAILURE], {course, error}),
}

export const accesses = {
  request: user => action(ACCESSES[REQUEST], {user}),
  success: (user, response) => action(ACCESSES[SUCCESS], {user, response}),
  failure: (user, error) => action(ACCESSES[FAILURE], {user, error}),
}

export const facultyCta = {
  request: faculty => action(FACULTY_CTA[REQUEST], {faculty}),
  success: (faculty, response) => action(FACULTY_CTA[SUCCESS], {faculty, response}),
  failure: (faculty, error) => action(FACULTY_CTA[FAILURE], {faculty, error}),
}

export const setSessionUser = id => action(actionsType.SET_SESSION_USER, {id})
export const loadReactions = id => action(actionsType.LOAD_REACTIONS, {id})
export const loadFaculties = (force=false) => action(actionsType.LOAD_FACULTIES, {academy: 'up', force: force})
export const loadPlans = () => action(actionsType.LOAD_PLANS, {})
export const loadFacultyPlans = (faculty) => action(actionsType.LOAD_FACULTY_PLANS, {faculty})
export const loadCoursePlans = (course) => action(actionsType.LOAD_COURSE_PLANS, {course})
export const loadAccesses = (user) => action(actionsType.LOAD_ACCESSES, {user})
export const loadFacultyCta = (faculty) => action(actionsType.LOAD_FACULTY_CTA, {faculty})

export const setReaction = ({lesson, seconds}) => ({
  type: actionsType.SET_PROGRESS,
  payload: {lesson, seconds}
})

export const setPercentage = ({pill, seconds}) => ({
  type: actionsType.SET_PERCENTAGE,
  payload: {pill, seconds}
})

export const setFavourite = ({course_id, value}) => ({
  type: actionsType.SET_FAVOURITE,
  payload: {course_id, value}
})

export const clearUser = () => ({
  type: actionsType.CLEAR_USER
})

export const setUserReady = (value) => ({
  type: actionsType.SET_USER_READY,
  value: value
})

export const clearReactions = () => ({
  type: actionsType.CLEAR_REACTIONS
})

export const logout = () => ({
  type: actionsType.USER_LOGOUT
})

export const login = (user) => ({
  type: actionsType.USER_LOGIN,
  user: user
})

export const setUserDataReady = (value) => ({
  type: actionsType.SET_USER_DATA_READY,
  value: value
})

export const setDataReady = (value) => ({
  type: actionsType.SET_DATA_READY,
  value: value
})

export const loadData = () => ({
  type: actionsType.LOAD_DATA
})

export const courseLandingWaitUser = value => ({
  type: actionsType.COURSE_LANDING_WAIT_FOR_USER,
  value: value
})

export const setMobileBottomSpacer = value => ({
  type: actionsType.SET_MOBILE_BOTTOM_SPACER,
  value: value
})

export const clearAccesses = () => ({
  type: actionsType.CLEAR_ACCESSES
})