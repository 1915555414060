import React, {useState, useEffect} from 'react';
import { Row, Col, Container } from 'reactstrap';
import moment from 'moment';
import {reduce} from 'lodash';

import style from './LiveCountdown.module.css';

function LiveCountdown({start, onEnd}){
  
  const getTimeLeft = () => {
    const now = moment().milliseconds(0) + (10*1000) //Tobia - via overlay 10 secondi prima della diretta
    const days = start.diff(now, 'days')
    const hours = start.diff(now, 'hours')
    const minutes = start.diff(now, 'minutes')
    const seconds = start.diff(now, 'seconds')
    return {
      days: days,
      hours: (hours - days * 24),
      minutes: (minutes - hours * 60),
      seconds: (seconds - minutes * 60)
    }
  }

  const [timeLeft, setTimeLeft] = useState(getTimeLeft())
  
  const tick = () => {
    const actualTimeLeft = getTimeLeft()
    const res = reduce(Object.values(actualTimeLeft), (re, val) => {
      return re && val <= 0
    }, true)

    if(res) {
      onEnd()
    }else{
      setTimeLeft(getTimeLeft())
    }
  }

  useEffect(() => {
    const intervalID = setInterval(
      () => tick(),
      1000
    );

    return () => {
      clearInterval(intervalID);
    }
  })
  
  const {days, hours, minutes, seconds} = timeLeft
  const get2D = num => {
    return (num.toString().length < 2 ? "0"+num : num).toString();
  }
  const CustCol = ({children}) => <Col xs={3} className="text-center">{children}</Col>
  const NumCol = ({value}) => <CustCol><h2>{get2D(value)}</h2></CustCol>
  const TextCol = ({value}) => <CustCol><p>{value}</p></CustCol>

  const num = [days, hours, minutes, seconds]
  const text = ["GIORNI", "ORE", "MINUTI", "SECONDI"]

  return(
    <div className={style.countDownWrap}>
      <Container>
        <Row>
          <Col xs={12} className="text-center customColumn">
            <h5>Per vedere la <span className="text-red-1">diretta</span> ricollegati tra</h5>
          </Col>
          <Col 
            xs={12} 
            lg={{size: 6, offset: 3}}
          >
            <Row className={style.countdownValues}>
              {num.map((e, idx) => <NumCol key={idx} value={e}/>)}
              {text.map((e, idx) => <TextCol key={idx} value={e}/>)}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LiveCountdown
