export const getModulesWithLessons = course => {
  const addLesson = lesson => {
    const modulesLessonsItem = modulesLessons.find(el => el.module.id === lesson.module_id)
    modulesLessonsItem.lessons.push(lesson)
  }

  const modulesLessons = []
  // preserve module order
  course.modules.forEach(m => {
    modulesLessons.push({module: m, lessons: []})
  })
  course.lessons.forEach(lesson => {
    addLesson(lesson)
  }); 

  return modulesLessons.filter(el => el.lessons.length > 0)
}