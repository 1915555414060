import React from 'react';
import cx from 'classnames';

import style from './Card.module.css';

function Card({children, onClick, className}){
  // Bootstrap css card gives you a display flex container
  // Use <Col> component inside, if you want regular padding,
  // or pure <div> to skip this rule
  // Use component className to add some rule to root <div>
  const root = cx(
    "card shadow border-0", 
    style.root, 
    className)
    
  return(
    <div onClick={onClick} className={root}>
      {children}
    </div>
  )
}

export default Card