import React from 'react';

function UpLogo ({color='#000'}){
  return(
    <svg viewBox="0 0 40 28">
      <g id="UP-Academy-versione-review" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="home---1" transform="translate(-150.000000, -16.000000)" fill={color}>
              <g id="Navbar-/-#2" transform="translate(150.000000, 10.000000)">
                  <g id="Navbar">
                      <g id="Atoms-/-Logo-/-Brand-/-Sign-white">
                          <g id="Logo-2" transform="translate(0.000000, 6.285714)">
                              <g id="Logo" transform="translate(0.000000, 0.326531)">
                                  <path d="M28.3058138,14.2773026 L28.3239782,14.5079438 C28.3239782,14.5079438 32.9727476,15.9907092 33.8040978,12.761982 C34.0212808,11.9027745 33.9480967,8.42387289 33.8040978,7.53135884 C33.2546906,4.21498299 28.3058138,5.88757034 28.3058138,5.88757034 L28.3058138,6.02104677 L28.3058138,14.2773026 Z M28.3058138,24.9261171 L21.7342508,26.8571429 C21.7342508,26.8571429 21.7589965,8.69583425 21.7342508,8.26259931 C21.7050299,7.83287032 21.939061,5.82721796 21.4831083,5.12001828 C21.0203111,4.41356987 19.622179,4.77368075 19.622179,4.77368075 L19.3939394,1.08216852 C19.3939394,1.08216852 20.7438963,0.407774487 21.9880259,0.407774487 C23.6428288,0.407774487 24.6529272,0.584323984 25.4060916,0.89810628 C26.474632,0.65744803 27.8498611,0.375720111 28.947096,0.251509401 C31.0539028,0.00759562895 40,-1.65171921 40,8.31769277 L40,11.980907 C40,22.7316444 28.514836,19.4370555 28.2897554,19.4002431 L28.3058138,24.9261171 Z M11.9321228,0.0416448325 L18.1693805,0.0045707743 C18.1693805,0.0045707743 18.3024249,11.2141408 18.3297489,11.6554745 C18.3534977,12.0904598 18.1269901,14.1097263 18.574898,14.8220053 C19.0184647,15.5337765 20.3838942,14.9294185 20.3838942,14.9294185 L20.6060606,18.8927877 C20.6060606,18.8927877 19.2909377,19.5728174 18.0800032,19.5728174 C16.0733555,19.5728174 15.0414309,19.2957777 14.2495481,18.8221946 C13.3417303,19.1180253 12.2911642,19.4080156 11.4374834,19.5159366 C9.49646415,19.7655517 1.60547139,20.789913 1.60164094,12.7704895 L1.60164094,12.686438 L1.55822911,12.6805976 L1.55822911,4.64339883 L0,4.64339883 L0,0 L1.55822911,0 L3.74899404,0 L7.9701555,0 L7.9701555,13.4540742 L7.88103358,13.4451865 C7.89405712,13.4776898 7.90503776,13.5053684 7.90503776,13.5294919 C8.04038051,15.7252411 11.9321228,14.4571052 11.9321228,14.4571052 L11.9321228,13.693278 L11.9321228,0.0416448325 Z" id="Combined-Shape"></path>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  )
}

export default UpLogo