export const getUser = ({session}) => {
  return session.user
}

export const getReactions = ({entities}) => {
  return entities.reactions
}

export const getPillPercentage = ({entities}) => {
  return entities.pill_percentages
}

export const getFaculties = ({entities}) => {
  return entities.faculties
}

export const getFaculty = ({entities: {faculties}}) => faculty_id => {
  return faculties.find((f) => f.id === parseInt(faculty_id))
}

export const getCourses = ({entities: {faculties}}) => {
  let courses = []
  if (faculties) {
    faculties.forEach(faculty => {
      if (faculty.courses !== null){
        courses = courses.concat(faculty.courses)
      }
    })
  }
  return courses
}

export const getUserReady = ({session}) => {
  return session.userReady
}

export const getPlans = ({entities}) => {
  return entities.plans
}

export const getPlan = ({entities}) => plan => {
  return entities.plans.find(p => p.id === plan)
}

export const getFacultyPlans = ({entities: {faculty_plans, plans}}) => faculty_id => {
  const single_faculty_plans = faculty_plans[faculty_id]
  if (!single_faculty_plans) return []
  let out_plans = []
  single_faculty_plans.forEach(plan_id => {
    const plan = plans.find(plan => plan.id === plan_id)
    if (plan) out_plans.push(plan)
  })
  return out_plans
}

// Now select first yearly plan if got more than one
export const getFacultyPlan =  (state) => faculty_id => {
  const plans = getFacultyPlans(state)(faculty_id)
  if (plans.length === 0) return null
  let selectedPlan = plans[0]
  if (plans.length > 1){
    selectedPlan = plans.find(p => p.period_unit === 'y')
  }
  return selectedPlan
}

export const getFacultyCta = ({entities: {faculty_cta}}) => faculty_id => {
  if (faculty_cta[faculty_id]) {
    return faculty_cta[faculty_id]
  }
  return {}
}

export const getCoursePlans = ({entities: {course_plans, plans}}) => course_id => {
  const single_course_plans = course_plans[course_id]
  if (!single_course_plans) return []
  let out_plans = []
  single_course_plans.forEach(plan_id => {
    const plan = plans.find(plan => plan.id === plan_id)
    if (plan) out_plans.push(plan)
  })
  return out_plans
}

// Now select first yearly plan if got more than one
export const getCoursePlan =  (state) => course_id => {
  const plans = getCoursePlans(state)(course_id)
  if (plans.length === 0) return null
  let selectedPlan = plans[0]
  if (plans.length > 1){
    selectedPlan = plans.find(p => p.period_unit === 'y')
  }
  return selectedPlan
}

// Show access of faculties linked to a plan and whitelist entry
export const getFacultyAccesses = ({session: {facultyAccesses}}) => {
  return facultyAccesses
}

// Show access of courses linked to a plan
export const getCourseAccesses = ({session: {courseAccesses}}) => {
  return courseAccesses
}

// Show admin access to academy
export const getAcademyAccesses = ({session: {academyAccesses}}) =>{
  return academyAccesses
}

export const getCourseFaculties = ({entities: {faculties}}) => course_id => {
  const facultiesWithCourses = faculties.filter(el => el.courses !== null)
  return facultiesWithCourses.filter(el => typeof el.courses.find(course => course.id === Number(course_id)) !== 'undefined' )
}

export const hasFacultyAccess = ({session: {academyAccesses, facultyAccesses}}) => faculty => {
  if (!faculty) return false
  const isAdmin = academyAccesses.includes(faculty.academy_id)
  const canAccess = facultyAccesses.includes(faculty.id)
  return isAdmin || canAccess
}

export const hasCourseAccess = ({session: {academyAccesses, facultyAccesses, courseAccesses}, entities}) => course => {
  if (!course) return false
  const isAdmin = academyAccesses.includes(course.academy_id)
  const canAccessCourse = courseAccesses.includes(course.id)

  const courseFaculties = getCourseFaculties({entities})(course.id)
  const facultyAccess = courseFaculties.find(faculty => facultyAccesses.includes(faculty.id))
  const canAccessFaculties = typeof(facultyAccess) !== 'undefined'
  return isAdmin || canAccessCourse || canAccessFaculties
}

export const getUserDataReady = ({session}) => {
  return session.userDataReady
}

export const getDataReady = ({entities}) => {
  return entities.data_ready
}

export const coursesWithReactions = ({entities: {reactions}}) => {
  return Object.keys(reactions).map(el => Number(el))
}

export const facultiesWithReactions = ({entities}) => {
  const faculties = []
  const courses = coursesWithReactions({entities})
  courses.forEach(el => {
    const courseFaculties = getCourseFaculties({entities})(el)
    faculties.push(...courseFaculties)
  })
  return faculties.map(el => el.id)
}

export const courseLandingWaitForUser = ({courseLandingWaitUser}) => {
  return courseLandingWaitUser
}

export const mobileBottomSpacer = ({mobileBottomSpacer}) => {
  return mobileBottomSpacer
}