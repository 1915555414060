import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import _, {isEmpty} from 'lodash';
import cx from 'classnames';

import * as selectors from '../../reducers/selectors';
import LargeFacultyCard from '../LargeFacultyCard/LargeFacultyCard';
import { ActionButton, ActionType } from '../UI/Buttons';
import { logIn } from '../AccessManager/utilities';
import { path, upster, upper } from './content';
import UpLogo from '../UI/UpLogo/UpLogo';
import Upster from './Upster';
import {onlyDesktop, onlyMobile} from '../../utilities/styleClasses';

import style from './Home.module.css';

function FacultyList({faculties}) {
  return (
    <Container className={style.facultyList}>
      <Row style={{justifyContent: 'space-around'}}>
        {faculties.map((item, i) => 
        <Col xs={12} key={i}>
          <LargeFacultyCard 
            key={i} 
            faculty={item}/>
        </Col> 
        )}
      </Row>
    </Container>
  )
}

function HomeHeader({user}) {
  const hText = (<span>La formazione per i <br/>digital builder del futuro</span>)
  return (
    <Container fluid>
      <Row className={style.headerRow}>
        <Col className={style.headerCol}>
          <div className={cx("d-flex justify-content-center", style.logoBox)}>
            <div className={style.logo}>
              <UpLogo color="white"/>
            </div>
          </div>
          <h1 className={onlyDesktop}>{hText}</h1>
          <h2 className={onlyMobile}>{hText}</h2>
          <p className={style.p1}>Diventa un Upper e fai salire di livello le tue skill digitali</p>
          <div className={style.ctaWrapper}>
            {isEmpty(user) &&
            <ActionButton 
              action={ActionType.cta} 
              text="ENTRA GRATIS" 
              callback={logIn}/>
            }
          </div>
        </Col>
      </Row>
    </Container>
  )
}

function PathItem({item}) {
  return (
    <div className={cx("d-flex flex-column align-items-center", style.pathItem)}>
      <img
        className={cx("img-fluid", style.pathImage)}
        alt={item.title}
        src={item.url}/>
      <div className={cx(style.pathNumber, style.pathFont)}>
        {item.number}
      </div>
      <div className={cx(style.pathTitle, style.pathFont)}>
        {item.title}
      </div>
      <div className={style.pathBody}>
        {item.body}
      </div>
    </div>
  )
}

function HomePath() {
  return (
    <Container className={style.path}>
      <Row>
        {path.map((item, idx) => 
        <Col key={idx} xs="6" lg="3" className="mb-4 mb-lg-0">
          <PathItem
            item={item}/>
        </Col>
        )}
      </Row>
    </Container>
  )
}

function HomeUpster() {
  return (
    <Container className={style.path}>
      <Row className="justify-content-center">
        <Col xs="12" xl="4">
          <h1>{upster.body.title}</h1>
          <p className={style.upsterBody}>{upster.body.description}</p>
        </Col>
        <Col xs="auto" className="d-flex flex-row flex-wrap justify-content-center align-items-center">
          {upster.group.map((guru, idx) => 
          <Upster key={idx} upster={guru}/>
          )}
        </Col>
      </Row>
    </Container>
  )
}

function Testimonial({student}) {
  return (
    <div className={cx("mb-4 mb-md-0", style.testimonial)}>
      <div className="d-flex">
        <div
          className={style.upperImage}
          style={{backgroundImage: `url("${student.url}")`}}/>
        <div className="d-flex flex-column justify-content-center">
          <div className={style.upperName}>
            <p className="big">{student.name}</p>
          </div>
          <div className={style.upperPosition}>
            <p className="big">{student.position}</p>
          </div>
        </div>
      </div>
      <div className={style.upperReview}>
        <p className="big">{student.review}</p>
      </div>
    </div>
  )
}

function HomeReputation() {
  return (
    <Container>
      <Row className={style.upperIntro}>
        <Col>
          <h1>{upper.body.title}</h1>
          <p>{upper.body.description}</p>
        </Col>
      </Row>
      <Row>
        {upper.group.map((student, idx) => 
        <Col key={idx}>
          <Testimonial
            student={student}/>
        </Col>
        )}
      </Row>
    </Container>
  )
}

class HomeSection extends Component {

  render() {
    const {style, children} = this.props
    return (
      <div className={cx(style, 'w-100')}>
        {children}
      </div>
    )
  }
}

class Home extends Component {
  render(){
    const {faculties, user} = this.props
    const hideAlmostEverything = true

    return (
      <>
        <HomeHeader user={user}/>
        {!_.isEmpty(user) &&
          <HomeSection style={style.zig}>
            <FacultyList faculties={faculties}/>
          </HomeSection>
        }
        
        {!hideAlmostEverything &&
        <>
          <HomeSection style={style.zag}>
            <HomePath />
          </HomeSection>
          <HomeSection style={style.zig}>
            <HomeUpster />
          </HomeSection>
          <HomeSection style={style.zag}>
            <HomeReputation />
          </HomeSection>
        </>
        }
      </>
    )
  }
}

const mapState = (state) => {
  return {
    faculties: selectors.getFaculties(state),
    user: selectors.getUser(state),
  }
}

export default withRouter(connect(mapState)(Home))
