import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import {isEmpty} from 'lodash';

import * as selectors from '../../reducers/selectors';
import UserInfo from '../UserInfo/UserInfo';
import Spinnable from '../UI/Spinnable/Spinnable';
import CourseCard from '../CourseCard/CourseCard';

import style from './Dashboard.module.css';

export const FacultyStatus = {
  New: 0,
  Purchased: 1,
  Started: 2,
}

function CourseList({faculties}){
  let courses = []
  faculties.filter(el => el.courses !== null).forEach(faculty => {
    courses = courses.concat(faculty.courses)
  })
  
  return(
    <Container className={style.courseListRoot}>
      <Row className={style.cardsRow}>
      {courses.map((course, idx) => 
        <Col xs={12} md={6} lg={4} xl={3} key={idx}>
          <CourseCard course={course}/>
        </Col>
      )}
      </Row>
    </Container>
  )
}

class Dashboard extends Component{
  
  getUserStats = () => {
    const {reactions} = this.props
    const stats = {lessons: 0, hours: 0}
    let seconds = 0
    Object.values(reactions).forEach(courseReaction => {
      stats.lessons += courseReaction.lessons
      seconds += courseReaction.seconds
    })
    stats.hours = Math.floor(seconds/3600)
    return stats
  }

  render(){
    const {
      user, 
      faculties, 
      isDataReady} = this.props
    const item = isDataReady ? {ok: 'ok'} : {}
    const totStats = this.getUserStats()

    if (isEmpty(user)) {
      return null
    }
    
    return(
      <Spinnable item={item}>
        <UserInfo 
          user={user}
          lessons={totStats.lessons}
          hours={totStats.hours}/>
        <CourseList faculties={faculties}/>
      </Spinnable>
    )
  }
}

const mapState = state => {
  return {
    faculties: selectors.getFaculties(state),
    user: selectors.getUser(state),
    isDataReady: selectors.getUserDataReady(state),
    reactions: selectors.getReactions(state),
  }
}

export default connect(mapState)(Dashboard)