import React from 'react';
import {Button} from 'reactstrap';
import {connect} from 'react-redux';
import cx from 'classnames';

import * as selectors from '../../../../reducers/selectors';
import {logIn} from '../../../AccessManager/utilities';
import {checkout, courseCheckout} from '../../../Checkout/utilities';

import {Mode} from '..';
import './actionButton.css';
import '../button.css';

export const Action = {
  default: 'actionDefault',
  cta: 'actionCta',
}

const mapState = state => {
  return{
    getFacultyPlan: selectors.getFacultyPlan(state),
  }
}

function ActionButton({
    text, 
    callback, 
    action=Action.default, 
    mode=Mode.default, 
    className='', 
    disabled=false,
  }){
  const composedClass = cx(
    action,
    className,
    'customActionButton',
    mode,
  )
  return(
    <Button 
      onClick={callback} 
      className={composedClass} 
      disabled={disabled}
    >
      {text}
    </Button>
  )
}

function LoginButton({text="ENTRA GRATIS"}){
  return(
    <ActionButton 
      action={Action.cta} 
      mode = {Mode.default}
      text={text} 
      callback={logIn}/>
  )
}

function UnconnectedBuyFacultyButton({
    faculty, 
    getFacultyPlan, 
    mode=Mode.default, 
    className='', 
    disable=false,
  }){
  const plan = getFacultyPlan(faculty.id)
  let text = 'ISCRIVITI'
  if (plan && plan.one_shot) text = 'ACQUISTA'
  let callback = () => checkout(faculty)
  if (disable) callback = () => {}
  return(
    <ActionButton 
      action={Action.cta} 
      mode = {mode}
      text={text}
      callback={callback}
      className={className}/>
  )
}

const BuyFacultyButton = connect(mapState)(UnconnectedBuyFacultyButton)

function BuyCourseButton({course, mode=Mode.default, className='', text='INIZIA ADESSO'}){
  return(
    <ActionButton 
      action={Action.cta} 
      mode = {mode}
      text={text}
      callback={() => courseCheckout(course)}
      className={className}/>
  )
}

export default ActionButton
export {LoginButton, BuyFacultyButton, BuyCourseButton}