import React from 'react';

function FacultyLogo({faculty, className}){
  return(
    <div className={className}>
      <img
        className={"img-fluid"}
        src={faculty && faculty.logo}
        alt={faculty && faculty.name}/>
    </div>
  )
}

export default FacultyLogo