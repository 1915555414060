import React, {Component} from 'react';
import {Navbar, Nav, NavItem, NavLink, NavbarBrand, Container, Row, Col} from 'reactstrap';
import {Link, withRouter} from 'react-router-dom'
import {connect} from 'react-redux';
import {isEmpty, throttle, memoize} from 'lodash';
import cx from 'classnames';

import * as selectors from '../../reducers/selectors';
import {ActionButton, ActionType, Mode as buttonMode} from '../UI/Buttons';
import UpLogo from '../UI/UpLogo/UpLogo';
import {logIn, logOut} from '../AccessManager/utilities';
import {onlyDesktop} from '../../utilities/styleClasses';

import style from './AppBar.module.css';
import { pathSegments } from '../../App';

export const appBarId = 'navWrapper';

export const Page = {
  Home: 'home',
  Academies: 'accademie',
  Academy: 'academy',
  Courses: 'courses',
  Course: 'course',
  Dashboard: 'dashboard',
  ViewCourse: 'viewcourse',
  Events: 'liveEvents',
  PillSerie: 'pillserie',
  Tm: 'terms-conditions',
}

const LogoColor = {
  white: '#FFF',
  blue: '#25248E',
}

const Mode = {
  fixedWhite: {
    pageClassName: style.fixedWhite,
    logoColor: LogoColor.blue,
  },
  fixedBlue: {
    pageClassName: style.fixedBlue,
    logoColor: LogoColor.white,
  },
  smart: {
    pageClassName: style.smart,
    logoColor: LogoColor.white,
  },
}

function ButtonActionLink({text, action, className, buttonClassName}){
  return function (){
    return (
      <NavItem className={className}>
        <ActionButton 
          action={action} 
          mode = {buttonMode.small}
          text={text} 
          callback={() => this.handleButtonClick(text)}
          className={buttonClassName}/>
      </NavItem>
    )
  }
}

function TextActionLink({text, className}) {
  return function (){
    return (
      <NavItem className={cx(style.textActionLink_root, className)}>
        <NavLink 
          onClick={() => this.handleButtonClick(text)} 
          className={style.textActionLink_navLink}
        >
          {text}
        </NavLink>
      </NavItem>
    )
  }
}

function TextNavLink({text, href, className}){
  return function(){
    return (
      <NavItem className={className}>
        <NavLink 
          tag={Link} 
          to={href}
          className={style.textNavLink_navLink}
        >
          {text}
        </NavLink>
      </NavItem>
    ) 
  }
}

const actionsText = {
  login: 'LOGIN',
  logout: 'LOGOUT',
  entraGratis: 'ENTRA GRATIS',
}

class AppBar extends Component{
  state = {
    headerHeight: 0,
    wantLogIn: false,
    scrollFurtherBar: null,
    rightNavItems: [],
    leftNavItems: [],
  }
  mode = null;

  bindNavItems = () => {
    const {rightItems, leftItems} = this.props
    this.setState({
      rightNavItems: rightItems.map(item => item.bind(this)),
      leftNavItems: leftItems.map(item => item.bind(this))
    })
  }

  handleButtonClick = (text) => {
    switch(text){
      case actionsText.entraGratis:
      case actionsText.login:
        logIn()
        break
      case actionsText.logout:
        logOut()
        break
      default:
        break
    }
  }

  getPageClassName = () => {
    const {mode: {pageClassName}} = this.props
    return pageClassName
  }

  getSmartPageClassName = () => {
    const {scrollFurtherBar} = this.state
    if(!scrollFurtherBar){
      return style.smartFixedHide
    }
    return style.smartFixed
    
  }

  getLogoColor = () => {
    const {mode: {logoColor}} = this.props
    return logoColor
  }

  getSmartLogoColor = () => {
    return LogoColor.blue
  }

  getAppBar = (smart=false) => {
    const {userReady} = this.props
    const {rightNavItems, leftNavItems} = this.state
    const pageClassName = smart ? this.getSmartPageClassName() : this.getPageClassName()
    const logoColor = smart ? this.getSmartLogoColor() : this.getLogoColor()
    return(
      <div 
        id={smart ? `${appBarId}-smart` : appBarId} 
        className={pageClassName}
      >
        <Container>
          <Row>
            <Col>
              <Navbar>
                <NavbarBrand tag={Link} to="/">
                  <div className={style.logoWrapper}>
                    <UpLogo color={logoColor}/>
                  </div>
                </NavbarBrand>
                {userReady && 
                <>
                <Nav className="mr-auto">
                  {leftNavItems.map((Item, idx) => <Item key={idx}/>)}
                </Nav>
                <Nav>
                  {rightNavItems.map((Item, idx) => <Item key={idx}/>)}
                </Nav>
                </>
                }
              </Navbar>    
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  render(){
    const {headerHeight} = this.state
    const {mode} = this.props
    const isModeFixed = mode === Mode.fixedBlue || mode === Mode.fixedWhite

    let pageIsChanged = false

    if (this.mode !== mode){
      pageIsChanged = true
      this.mode = mode
    }

    const headerHeightStyle = {
      height: `${headerHeight}px`
    }

    // With a smart mode we got two bar, 
    // with different class name and behaviour
    return (
      <>
      {this.getAppBar()}
      {isModeFixed && 
        <div className={style.fixedHeightReplace} style={headerHeightStyle}/>
      }
      {!pageIsChanged && mode === Mode.smart && this.getAppBar(true)}
      </>
    )
  }

  setScrollFurtherBar(position){
    const{headerHeight, scrollFurtherBar} = this.state
    if(position > headerHeight && !scrollFurtherBar){
      this.setState({scrollFurtherBar: true})
    }
    if (position < headerHeight && scrollFurtherBar){
      this.setState({scrollFurtherBar: false})
    }
  }

  componentDidMount(){
    const navElement = document.getElementById(appBarId)
    if (navElement) this.setState({headerHeight: navElement.offsetHeight})
    
    window.addEventListener('scroll', throttle(() => this.setScrollFurtherBar(window.scrollY), 200));

    this.bindNavItems()
  }

  componentDidUpdate(prevProps, prevState){
    const navElement = document.getElementById(appBarId)
    const {rightItems, leftItems, mode} = this.props
    if (prevProps.mode !== mode){
      this.setState({scrollFurtherBar: null})
    }
    if (navElement && prevState.headerHeight !== navElement.offsetHeight){
      this.setState({headerHeight: navElement.offsetHeight})
    }
    if (prevProps.rightItems !== rightItems || prevProps.leftItems !== leftItems){
      this.bindNavItems()
    }
  }
}

const getRightItems = (user) => {
  const items = []
  
  if (isEmpty(user)){
    items.push(
      TextActionLink({
        text: actionsText.login,
      })
    )
    items.push(
      ButtonActionLink({
        text: actionsText.entraGratis, 
        action: ActionType.cta, 
        buttonClassName: style.ctaEntraGratis, 
        className: onlyDesktop,
      })
    )
  }else{
    items.push(
      TextActionLink({
        text: actionsText.logout,
      })
    )
  }
  return items
}

const getLeftItems = (user) => {
  const items = []
  
  if (isEmpty(user)){
 
  }else{
    items.push(
      TextNavLink({
        text: 'Iniziative',
        href: `/${pathSegments.dashboard}`,
        className: cx(style.userName),
      })
    )
  }
  return items
}

const getRightItemsMemo = memoize(getRightItems)
const getLeftItemsMemo = memoize(getLeftItems)

export function getPageFromPathname(pathname){
  // TODO
  // doesn't match /dashboard/ (with final slash)
  const regex = new RegExp(`(^/)(?:(${pathSegments.academies}|${pathSegments.dashboard}|${pathSegments.tm})(?:/(?:([^/]+?))(?:/(${pathSegments.courses}|${pathSegments.viewcourse}|${pathSegments.liveEvents}|${pathSegments.pillSerie})(?:/(?:([^/]+?)))?)?)?)?$`)
  const resArr = pathname.match(regex)
  if (resArr === null) return Page.Home
  const [, home, firstLevel, academy, secondLevel, course] = resArr
  
  let academies, dashboard, tm = null
  if (firstLevel === pathSegments.academies) {
    academies = true
  }else if (firstLevel === pathSegments.dashboard){
    dashboard = true
  }else if (firstLevel === pathSegments.tm){
    tm = true
  }
  
  let courses, viewcourse, events, pillSerie = null
  if (secondLevel === pathSegments.courses) {
    courses = true
  }else if (secondLevel === pathSegments.viewcourse){
    viewcourse = true
  }else if (secondLevel === pathSegments.liveEvents){
    events = true
  }else if (secondLevel === pathSegments.pillSerie){
    pillSerie = true
  }
  if (home && academies && academy && courses && course) return Page.Course
  if (home && academies && academy && courses) return Page.Courses
  if (home && academies && academy && viewcourse) return Page.ViewCourse
  if (home && academies && academy && events) return Page.Events
  if (home && academies && academy && pillSerie) return Page.PillSerie
  if (home && academies && academy) return Page.Academy
  if (home && academies) return Page.Academies
  if (home && dashboard) return Page.Dashboard
  if (home && tm) return Page.Tm
  return Page.Home
}

// With a 'smart' behaviour we got a transparent bar that disappear on scroll
// while another one comes down
function getModeFromPage(page){
  switch (page){
    case Page.Home:
    case Page.Academy:
    case Page.Events:
      return Mode.smart
    case Page.Dashboard:
      return Mode.fixedWhite
    default:
      return Mode.fixedWhite
  }
}

const mapState = (state, ownProps) => {
  const page = getPageFromPathname(ownProps.location.pathname)
  const mode = getModeFromPage(page)
  const user = selectors.getUser(state)
  return {
    user,
    userReady: selectors.getUserReady(state),
    rightItems: getRightItemsMemo(user),
    leftItems: getLeftItemsMemo(user),
    mode,
  }
}

export default withRouter(connect(mapState)(AppBar));
