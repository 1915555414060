import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import cx from 'classnames';

import style from './UserInfo.module.css';

function UserInfo({user, lessons, hours}){
  const name = user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.last_name
  return (
    <Container fluid className={style.root}>
      <Container >
        <Row>
          <Col xs={12} className={style.textCol}>
            <Row>
              <Col>
                <h1>Ciao {name}</h1>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4} lg={3}>
                <p className="big">
                  <span className={cx("oi oi-menu", style.icon)} aria-hidden="true"/>
                  <span className={style.gray}> Video seguiti: </span>
                  <span><strong>{lessons}</strong></span> 
                </p>
              </Col>
              <Col xs={12} md={4} lg={3}>
                <p className="big">
                  <span className={cx("oi oi-calendar", style.icon)} aria-hidden="true"/>
                  <span className={style.gray}> Ore seguite: </span>
                  <span><strong>{hours}</strong></span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default UserInfo