import React, { Component } from 'react';
import { ListGroup, Collapse } from 'reactstrap';
import cx from 'classnames';

import {onlyDesktop, onlyMobile} from '../../utilities/styleClasses';
import { isLessonComplete } from '../../utilities/reactions';
import ListItem from '../ListItem/ListItem';
import {getModulesWithLessons} from '../../utilities/lessons';
import {CollapseIcon} from '../UI'

import './CourseLessonsBox.css';
import style from './CourseLessonsBox.module.css';

function LessonListItem (props) {
  const {item: lesson, idx} = props
  return (
    <ListItem 
      {...props} 
      text={`${idx+1}. ${lesson.title}`} 
      disabled={lesson.video === ""}
      activeclassname={style.activeItem}/>
  )
}

function ModuleListItem ({item, isOpen, toggleOpen, onLessonClick, selectedLesson}) {
  // TODO
  // optimize ?, every update this method is call for every lesson
  const getLessonBox = lesson => {
    let videoDuration = Math.floor(lesson.duration / 60);
    if (isLessonComplete(lesson)){
      return <span className="oi oi-circle-check" aria-hidden="true"></span>
    }else{
      return <span className={style.videoDuration}>{videoDuration} min</span>
    }
  }
  const {module: courseModule, lessons} = item

  return(
    <>
      <ListItem 
        text={courseModule.title} 
        callback={() => toggleOpen(courseModule.id)}
        box={<CollapseIcon isOpen={isOpen}/>}
      />
      <Collapse isOpen={isOpen}>
      {lessons.map((e, idx) => 
        <LessonListItem 
          key={e.id} 
          idx={idx}
          active={selectedLesson === e.id}
          item={e} 
          className="listLessonItem"
          callback={() => onLessonClick(e)} 
          box={getLessonBox(e)}/>
      )}
      </Collapse>
    </>
  )
}

class CourseLessonsBox extends Component {
  state = {
    mobileOpen: false,
    collapse: null,
  }

  handleCourseClick = () => {
    this.toggleOpen()
    this.props.onCourseClick()
  }

  handleLessonClick = lesson => {
    this.toggleOpen()
    this.props.onLessonClick(lesson)
  }

  handleModuleClick = moduleId => {
    let collapse = moduleId
    if (this.state.collapse === moduleId){
      collapse = null
    }
    this.setState({collapse})
  }

  getLessons = () => {
    return this.props.course.lessons || []
  }

  toggleOpen = () => {
    this.setState({mobileOpen: !this.state.mobileOpen})
  }

  render (){
    const {mobileOpen, collapse} = this.state
    const {selectedLesson, course} = this.props
    const selectedLessonId = selectedLesson ? selectedLesson.id : null
    const modulesLessons = getModulesWithLessons(course)

    const listItems = (
      <>
        <ListItem 
          text="Obiettivo" 
          className="courseObjective listItem"
          callback={this.handleCourseClick} 
          active={!selectedLessonId}/>
        {modulesLessons.map(m => 
          <ModuleListItem 
            key={m.module.id} 
            item={m}
            onLessonClick={this.handleLessonClick} 
            selectedLesson={selectedLessonId}
            isOpen={m.module.id === collapse}
            toggleOpen={this.handleModuleClick}/>
        )}
      </>
    )
    
    return(
      <ListGroup className={style.root}>
        <ListItem 
          text="In questa iniziativa" 
          className={cx(onlyMobile, "mobileToggleItem")} 
          callback={this.toggleOpen} 
          box={<CollapseIcon isOpen={mobileOpen}/>}/>
        <div className={onlyDesktop}>
          {listItems}
        </div>
        <div className={onlyMobile}>
          <Collapse isOpen={mobileOpen}>
            {listItems}
          </Collapse>
        </div>
      </ListGroup>
    )
  }

  componentDidUpdate(prevProps, prevState){
    const {selectedLesson} = this.props
    
    if (prevProps.selectedLesson == null && selectedLesson){
      this.setState({collapse: selectedLesson.module_id})
      return
    }

    if (prevProps.selectedLesson && selectedLesson && selectedLesson.id !== prevProps.selectedLesson.id){
      this.setState({collapse: selectedLesson.module_id})
    }
  }
  
  componentDidMount(){
    const {selectedLesson} = this.props
    if (selectedLesson) this.setState({collapse: selectedLesson.module_id})
  }
}

export default CourseLessonsBox