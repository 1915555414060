import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Progress} from 'reactstrap';
import cx from 'classnames';

import style from './ClickableProgress.module.css';
import './clickableProgress.css'

// https://css-tricks.com/sliding-nightmare-understanding-range-input/
// https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/

// http://danielstern.ca/range.css/#/
// https://github.com/Stryzhevskyi/rangeSlider


class ClickableProgress extends Component{
  ref = React.createRef()

  handleClick = (e) => {
    const {onClick, disabled} = this.props
    if (!onClick || disabled) return
    const el = this.ref.current
    const boundingRect = el.getBoundingClientRect()
    const elPosX = boundingRect.left
    const xClick = e.pageX - elPosX
    const xClickRound = Math.round((xClick / boundingRect.width) * 100) / 100
    onClick(xClickRound)
  }

  animateClass = () => {
    const {animated} = this.props
    return animated ? '' : 'no-animate'
  }

  dynamicRootClass = () => {
    const {animated, disabled} = this.props
    const animatedClass = animated ? '' : 'no-animate'
    const disabledClass = disabled ? style.disabled : ''
    return cx(animatedClass, disabledClass)
  }

  render(){
    const {value, className} = this.props
    const rootClassName = cx(className, style.root, "clickable-proress", this.dynamicRootClass())
    return(
      <div ref={this.ref} className={rootClassName}>
        <Progress 
          value={value*100} 
          onClick={this.handleClick}/>
        {/* <input type='range' min='0' max='1' value={value.toString()} step='0.01'/> */}
      </div>
    )
  }

}

ClickableProgress.propTypes = {
  value: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  animated: PropTypes.bool,
  disabled: PropTypes.bool,
}

ClickableProgress.defaultProps = {
  animated: true,
  disabled: false,
}

export default ClickableProgress