import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import {Link} from 'react-router-dom';
import cx from 'classnames';
import {getTmUrl} from '../../utilities/nav';

import style from './Footer.module.css';
import UpLogo from '../UI/UpLogo/UpLogo';

function Footer(){
  return(
    <Container fluid className={style.wrapper}>
      <Row className="justify-content-center">
        <Col
          className={cx("d-flex flex-column align-items-center", style.content)}
          xs="auto">
          <div className={style.logo}>
            <UpLogo color="#25248E" />
          </div>
          <div>
            ©2019 - UP S.R.L. | Tutti i diritti riservati 
          </div>
          <div>
            <div className={style.tcwrap}>
              <Link to={getTmUrl()} className={style.termsconditions}>Terms and Conditions</Link>  
            </div>
            <a href="https://www.iubenda.com/privacy-policy/97438028" className="iubenda-white no-brand iubenda-embed" title="Privacy Policy ">Privacy Policy</a>
            <a href="https://www.iubenda.com/privacy-policy/97438028/cookie-policy" className="iubenda-white no-brand iubenda-embed" title="Cookie Policy ">Cookie Policy</a>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
