import method1 from '../../assets/images/metodo_1.png'
import method2 from '../../assets/images/metodo_2.png'
import method3 from '../../assets/images/metodo_3.png'
import method4 from '../../assets/images/metodo_4.png'

const path = [
  {
    url: method1,
    number: 1,
    title: "Scegli la tua strada",
    body: "Vuoi migliorare le tue conoscenze di marketing o le tue abilità di programmatore? Iscriviti ad una Academy, scegli il corso che preferisci e  comincia a imparare.",
  },
  {
    url: method2,
    number: 2,
    title: "Segui il tuo ritmo",
    body: "Segui le lezioni come vuoi tu: i nostri corsi seguono il tuo ritmo. Inizia una lezione, fermala quando vuoi e ricominciala in qualsiasi momento.",
  },
  {
    url: method3,
    number: 3,
    title: "Supera l’esame finale",
    body: "Cosa sarebbe un corso senza un esame? Segui le lezioni e affronta quiz ed esami intermedi per migliorare la tua comprensione.",
  },
  {
    url: method4,
    number: 4,
    title: "Sali di livello",
    body: "Superato con successo l’esame finale avrai modo di ottenere un Badge che certificherà le tue competenze. I nostri badge sono certificati da OpenBadge e riconosciuti a livello globale.",
  },
];

const upster = {
  body: {
    title: "I nostri UpSters",
    description: "Up + Master: gli Upster sono i nostri docenti, influencer e guru che potrai seguire nelle nostre Academy. Abbiamo scelto i migliori, i più brillanti e interessanti professionisti che hanno sviluppato i loro corsi appositamente per Up Academy. Segui i loro corsi o iscriviti a tutti i loro contenuti per ottenere il massimo risultato.",
  },
  group: [
    {
      url: "https://gmsrp.cachefly.net/images/18/02/17/8d3abab4c55a7d90c9bb53e80686d529/960.jpg",
      name: "Ronaldinho",
      position: "Trequartista presso Me Stesso",
      description: "Ottimo calciatore",
    },
    {
      url: "https://gmsrp.cachefly.net/images/18/02/17/8d3abab4c55a7d90c9bb53e80686d529/960.jpg",
      name: "Ronaldinho",
      position: "Trequartista presso Me Stesso",
      description: "Ottimo calciatore",
    },
    {
      url: "https://gmsrp.cachefly.net/images/18/02/17/8d3abab4c55a7d90c9bb53e80686d529/960.jpg",
      name: "Ronaldinho",
      position: "Trequartista presso Me Stesso",
      description: "Ottimo calciatore",
    },
  ],
};

const upper = {
  body: {
    title: "Gli Uppers dicono di noi",
    description: "I nostri studenti parlano dei corsi che hanno seguito.",
  },
  group: [
    {
      url: "https://4books.com/static/nanabianca/images/testimonial.png",
      name: "Annibale Ferrante",
      position: "Amministratore, Maan Consulting",
      review: "Ho imparato molto grazie ad Up Academy. Non ho trovato le solite lezioni ma veri e propri corsi strutturati con obiettivi e traguardi.",
    },
    {
      url: "https://4books.com/static/nanabianca/images/testimonial-3.png",
      name: "Giorgio Crippas",
      position: "SdM, Interactive Passion",
      review: "Una piacevole scoperta. Up Academy è stata capace di insegnarmi nuove competenze e soprattutto darmi nuove idee. Grazie Up Academy!",
    },
  ],
};

export { path, upster, upper }
