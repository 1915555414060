import React from 'react';
import {Row, Col} from 'reactstrap';
import cx from 'classnames';

import style from './TeacherPreview.module.css';

const background = (url, dimension) => {
  return {
    backgroundImage: `url("${url}")`,
    height : `${dimension}px`,
    minHeight : `${dimension}px`,
    width : `${dimension}px`,
    minWidth : `${dimension}px`,
  }
}

function TeacherPreview({
    teacher, 
    rootClass,
    nameClass, 
    positionClass, 
    pictureClass,
    imageCol=2, 
    textCol=10, 
    mdImageCol=2,
    mdTextCol=10,
    imageDimension=40, 
    }) {
  if (typeof teacher === 'undefined') {
    return null
  }
  return (
    <Row className={rootClass}>
      <Col xs={imageCol} md={mdImageCol} className="d-flex">
        <div
          className={cx(style.picture, pictureClass)}
          style={background(teacher.profile, imageDimension)}
        />
      </Col>
      <Col xs={textCol} md={mdTextCol} className="d-flex">
        <div className="my-auto">
          <div className={nameClass}>
            {teacher.name}
          </div>
          <div className={positionClass}>
            {teacher.position}
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default TeacherPreview
