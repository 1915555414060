import React, { Component } from 'react'
import { toast } from 'react-toastify';

import { api } from '../../api'

let env = process.env.NODE_ENV

if (env === 'production' && process.env.REACT_APP_ENV === 'staging'){
  env = 'staging'
}

const dev = 'slashers-test'
const staging = 'slashers-test'
const prod = 'slashers'

const site = (env === 'production') ? prod : (env === 'staging') ? staging : dev
const domain = (env === 'production') ? "https://community.slashers.me" : ""

const config = {
  ELEMENT: 'cb-script',
  SITE: site,
  SCRIPT: 'https://js.chargebee.com/v2/chargebee.js', 
  DOMAIN: domain,
}

class Checkout extends Component {

  constructor(props) {
    super(props)

    this.state = {
      ready: false,
    }

    this.checkout = this.checkout.bind(this)
  }

  checkout() {
    const {plan, dismiss, success} = this.props

    const cb = window.Chargebee.getInstance()
    cb.openCheckout({
      hostedPage: () => {
        return api.createCheckout(plan)
          .then(({data}) => data)
      },
      error: (error) => {
        toast.error("Subscription checkout error")
      },
      close: dismiss,
      success: (page) => {
        api.completeCheckout(page)
          .then((resp) => {
            cb.closeAll()
            success(resp.data.subscription)
          })
      },
    })
  }

  setReady() {
    this.setState({ ready: true })
  }

  render() {
    const {ready} = this.state
    const {show} = this.props
    return (
      <>
        {ready && show && this.checkout()}
      </>
    )
  }

  componentDidMount() {
    if (document.getElementById(config.ELEMENT)) {
      // The chargebee script has already been added so we
      // are ready to proceed with the modal checkout
      this.setReady()
      return;
    }

    const el = document.createElement('script')
    el.setAttribute('id', config.ELEMENT)
    el.setAttribute('src', config.SCRIPT)
    el.onload = () => {
      window.Chargebee.init({
        'site': config.SITE,
        'domain': config.DOMAIN,
      });
      window.Chargebee.registerAgain()

      this.setReady()
    }
    document.body.appendChild(el)
  }
}

export default Checkout
