const SECONDS_IN_HOUR = 60 * 60;

export const courseDuration = (course) => {
  return course.lessons.reduce((a, b) => a + b.duration, 0)
}

export const facultyDuration = (faculty) => {
  const courseDurations = (faculty.courses || []).map(courseDuration)
  const seconds = courseDurations.reduce((a, b) => a + b, 0)
  return Math.ceil(seconds / (SECONDS_IN_HOUR))
}

export const courseStats = (courseReaction) => {
  const stats = {seconds: 0, lessons: 0}
  Object.values(courseReaction.percentages).forEach(lessonData => {
    stats.lessons ++
    stats.seconds += lessonData.current
  })
  return stats
}
