import React, {Component} from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { 
  Modal, 
  ModalBody, 
  ModalHeader, 
  Row, 
  Col, 
  Collapse, 
  Container, 
  Spinner} from 'reactstrap';
import {isEmpty} from 'lodash';

import * as selectors from '../../reducers/selectors';
import {IntroSection} from '../UI';
import {getPillSerieUrl} from '../../utilities/nav';
import CustomSlider from '../CustomSlider/CustomSlider';
import {IntroDescription} from '../Faculty/Faculty';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import VimeoPreview from '../VimeoPreview/VimeoPreview';
import {
  isPillComplete, 
  getPillProgress, 
  setPillProgress} from '../../utilities/reactions';
import {
  ActionButton, 
  Mode as ButtonMode, 
  ActionType, 
  BuyFacultyButton,
  RedButton} from '../UI/Buttons';
import {FacultyPrice} from '../FacultyTop/FacultyTop';
import {truncateText} from '../../utilities/text';
import {secondsToTime} from '../../utilities/time';
import {client} from '../../api/Client';

import {logIn} from '../AccessManager/utilities';
import {checkout} from '../Checkout/utilities';

import facultyStyle from '../Faculty/Faculty.module.css';
import style from './FacultyExtra.module.css';
import './facultyExtra.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { onlyDesktop, onlyMobile } from '../../utilities/styleClasses';

function facultyHasPill(faculty){
  // TODO
  // better check
  const series = faculty ? faculty.pill_series : []
  if (
    series[0] && 
    series[0].pills && 
    series[0].pills[0] && 
    series[0].pills[0].title
    ) return true
  return false
}

function LoginCta(){
  return(
    <ActionButton 
      text="ENTRA GRATIS PER SBLOCCARE" 
      mode={ButtonMode.small} 
      action={ActionType.cta}/>
  )
}

function SliderSeriesItem ({item}) {
  const {seriesClick, haveUser} = this
  const hasCta = !haveUser()
  const backgroundStyle = {
    backgroundImage: `url(${item.cover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }
  // TODO
  // sync with backend value
  const description = truncateText({text: item.description, maxLength: 80}).text
  const title = truncateText({text: item.title, maxLength: 60}).text

  const ctaClass = hasCta && style.hasCta
  
  // TODO
  // The Col with style.seriesSliderWrapper 
  // with its css is a hell and needs a refactor,
  // sometimes is broken with login
  return(
    <>
      <div style={backgroundStyle} className={style.sliderSerieBackground}/>
      <div 
        onClick={seriesClick}
        className={cx(style.serieSliderTextContainer, ctaClass)}
      >
        <Container className="h-100">
          <Row className="h-100">
            <Col className={cx(style.seriesSliderWrapper)}>
              <div className={style.sliderSerieItemGradient}/>
              <div className={style.seriesSliderText}>
                <h4><strong>{title}</strong></h4>
                <p className="big">{description}</p>
                {item.pills && 
                <p className="big">
                  <strong>{item.pills.length}</strong> puntate
                </p>
                }
                {hasCta &&
                <div className={cx(style.ctaWrapper, onlyMobile)}>
                  <LoginCta/>
                </div>  
                }
              </div>
              <div className={cx(style.ctaWrapper, onlyDesktop)}>
                {hasCta && <LoginCta/>}
              </div>  
            </Col>
          </Row>
        </Container>  
      </div> 
    </>
  ) 
}

export function PillDuration({item, className}){
  let progress = (
    <div className={cx(style.pillProgress, className)}>
      <span 
        className="oi oi-circle-check" 
        aria-hidden="true"
      />
    </div>
  )
  if (!isPillComplete(item)){
    progress = (
      <div className={cx(style.pillProgress, className)}>
        <p className={cx("big", style.pillTime)}>
          <strong>{secondsToTime(item.duration)}</strong>
        </p>
      </div>
    )
  }
  return progress
}

export function getPillStart(selectedPill){
  if (!selectedPill || isPillComplete(selectedPill)) return 0
  const progress = getPillProgress(selectedPill)
  if (isEmpty(progress)) return 0
  return progress.current
}

// Show only with user data
function SliderPillsItem ({item, number}) {
  const {faculty, hasAccess, getFacultyCta} = this.props
  const facultyPlan = this.plan()

  const descriptionResult = truncateText({text: item.description, maxLength: 75})  
  const title = truncateText({text: item.title, maxLength: 55}).text
  const hasFacultyAccess = hasAccess(faculty)
  let rootClass = cx(style.pillWrapper, 'card')
  if (!hasFacultyAccess) {
    rootClass = cx(rootClass, style.noAccess)
  }
  
  const ctaData = getFacultyCta(faculty.id)
  const showDescription = this.state.serieHasDescription[item.pill_serie_id.toString()]
  
  const showBuyButton = facultyPlan !== null && !hasFacultyAccess && isEmpty(ctaData)
  const showBuyFromLanding = !hasFacultyAccess && !isEmpty(ctaData)

  return(
    <div 
      onClick={() => {
        this.handlePillClick(item)
      }} 
      className={rootClass}
    >
      <Col xs={12}>
        <div>
          <VimeoPreview 
            pill={item} 
            number={number}
            className={style.vimeoPreview}
          />
        </div>
        <div className={cx("d-flex", style.pillTitle)}>
          <p className="big">
            <strong>{title}</strong>
          </p>
          <PillDuration item={item}/>
        </div>
        {showDescription &&
        <div className={style.pillDescriptionWrap}>
          <p className={cx("big", style.pillDescription)}>
            {descriptionResult.text}
          </p>
          {descriptionResult.truncated && 
          <p style={{textDecoration: 'underline'}}>Read more</p>
          }
        </div>
        }
        {showBuyButton &&
          <div className={style.pillBuyCtaWrap}>
            <BuyFacultyButton 
              faculty={faculty} 
              mode={ButtonMode.small}
              disable={true}
            />
            <FacultyPrice 
              className={style.facultyPrice} 
              plan={facultyPlan}
            />
          </div>
        }
        {showBuyFromLanding && ctaData.url !== "" &&
          <div className={style.buyFromLanding}>
            <RedButton>
              {ctaData.cta}
            </RedButton>
          </div>
        }
      </Col>
    </div>
  )
}

export class PillModal extends Component{
  aspectRatioDefault = Math.round(16/9 * 1000)/1000
  state={
    ready: false,
    play: false,
    aspectRatio: this.aspectRatioDefault,
  }

  handleReady = () => {
    const {pill} = this.props
    this.setState({ready: true})
    client.getVimeoInfo(pill.video)
      .then(r => {
        const {data: {width, height}} = r
        const ratioRound = Math.round((width/height) * 1000)/1000
        // evita di presentare un video stretto e lungo, megio bande nere laterali 
        if (ratioRound > this.aspectRatioDefault){
          this.setState({aspectRatio: ratioRound})
        }
      })
  }

  toggle = () => {
    const {toggle} = this.props
    this.setState({ready: false})
    toggle()
  }

  handleVideoProgress = ({playedSeconds}) => {
    const {pill} = this.props
    if (!this.state.play) return
    setPillProgress({pill, seconds: playedSeconds})
  }

  handlePauseProgress = seconds => {
    const {pill} = this.props
    setPillProgress({pill, seconds})
  }

  handleVideoEnded = () => {
    const {pill} = this.props
    this.setState({play: false})
    setPillProgress({pill, seconds: pill.duration})
  }

  handlePause = () => {
    this.setState({play: false})
  }

  handlePlay = () => {
    this.setState({play: true})
  }
  
  render(){
    const {pill, startOn} = this.props
    const {ready, aspectRatio} = this.state
    const modalIsOpen = pill !== null

    const pillModalColPaddingTop = 1/(aspectRatio) * 100
    const pillModalColStyle = {
      paddingTop: `${pillModalColPaddingTop}%`
    }

    return(
      <Modal 
        isOpen={modalIsOpen} 
        toggle={this.toggle} 
        centered={true} 
        className="pillModal"
      >
        <ModalHeader toggle={this.toggle}>{pill && pill.title}</ModalHeader>
        <ModalBody>
          {modalIsOpen && 
          <>
            <Row style={{height: '100%'}}>
              <Col 
                className={style.pillModalCol} 
                style={pillModalColStyle}
              >
                <div className={style.pillModalVideo}>
                  <VideoPlayer 
                    id={Number(pill.video)} 
                    onReady={this.handleReady}
                    startOn={startOn}
                    onProgress={this.handleVideoProgress}
                    onPauseProgress={this.handlePauseProgress}
                    onPause={this.handlePause}
                    onPlay={this.handlePlay}
                    onEnded={this.handleVideoEnded}/>
                  {!ready && <Spinner/>}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className={style.pillModalDescription}>
                <p>{pill.description}</p>
              </Col>
            </Row>
          </>}
        </ModalBody>
      </Modal>
    )
  }
}

function PillSerieSlider(
  {
    settings, 
    faculty, 
    user, 
    onSelection, 
    ItemComponent, 
    selectionClass,
  }){
  
  let selectedClass = null
  if (facultyHasPill(faculty) && !isEmpty(user)) {
    selectedClass = selectionClass
  }
  return(
    <CustomSlider 
      settings={settings}
      data={faculty.pill_series} 
      onSelection={onSelection} 
      ItemComponent={ItemComponent}
      selectedClass={selectedClass}
      displayBlock={true}
      goTo={!isEmpty(user)}
      doNothing={isEmpty(user)}
    />
  )
}

function PillsSlider(
  {
    settings,
    data,
    onSelection, 
    ItemComponent, 
    facultyAccess,
    serieId,
  }){
  const wrapperClass = facultyAccess ? '' : style.pillWrapperNoAccess
  return(
    <CustomSlider
      dataKey={serieId}
      settings={settings}
      data={data}
      ItemComponent={ItemComponent}
      onSelection={onSelection}
      wrapperClassName={wrapperClass}
      goTo={false}
    />
  )
}

class FacultyExtra extends Component{
  constructor(props){
    super(props)
    this.SliderSeriesItemBind = SliderSeriesItem.bind(this)
    this.SliderPillsItemBind = SliderPillsItem.bind(this)
    this.state={
      seriesId: null,
      pillId: null,
      serieHasDescription: {},
    }
    const {faculty} = this.props
    const series = faculty ? faculty.pill_series : []
    
    series.forEach(s => {
      const hasDescription = s.pills.some(p => p.description !== "")
      this.state.serieHasDescription[s.id.toString()] = hasDescription
    });
  }

  
  
  description = `Be curious! Non perdere la tua curiosità e segui gli 
    approfondimenti dell’Academy, scelti personalmente da Marco 
    Montemagno e dalla sua redazione.`

    seriesResponsiveSettings = {
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  }

  pillsResponsiveSettings = {
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  }
  
  pillsSettings = {
    slidesToShow: 3,
    ...this.pillsResponsiveSettings,
  }

  seriesSettings = {
    onSwipe: () => this.handleSeriesSelection(),
    ...this.seriesResponsiveSettings,
  }

  haveUser = () => {
    const {user} = this.props
    return !isEmpty(user)
  }

  hasFacultyAccess  = () => {
    const {faculty, hasAccess} = this.props
    return hasAccess(faculty)
  }
  
  // Call on: 
  // swipe
  // after a click on Serie
  // arrow click (or swipe) if a serie is selected
  handleSeriesSelection = (item) => {
    const {user, faculty} = this.props
    const id = item ? item.id : null
    if (facultyHasPill(faculty) && !isEmpty(user)) {
      this.setState({seriesId: id})
    }
  }

  // Call on serie Click from slider item SliderSeriesItem
  // that is bind to this scope
  seriesClick = () => {
    if (this.hasFacultyAccess() || this.haveUser()) return
    logIn()
  }

  handlePillClick = item => {
    const {faculty, getFacultyCta} = this.props
    const ctaData = getFacultyCta(faculty.id)
    const canCheckout = !this.hasFacultyAccess() && isEmpty(ctaData)
    const canBuyFromLanding = !this.hasFacultyAccess() && !isEmpty(ctaData)
    if(canCheckout){
      checkout(faculty)
      return
    }else if (canBuyFromLanding){
      window.location.href = ctaData.url
    }else{
      this.setState({pillId: item.id})
    }
  }

  handlePillsSelection = (item) => {
    if(!item) {
      this.setState({pillId: null})
    }
  }

  modalToggle = () => {
    this.setState({pillId: null})
  }

  plan = () => {
    const {faculty, getFacultyPlan} = this.props
    return getFacultyPlan(faculty.id)
  }

  render(){
    const {faculty, user} = this.props
    const {seriesId, pillId} = this.state
    const series = faculty ? faculty.pill_series : []
    
    if (series.length === 0) return null

    let selectedSerie = null
    let selectedPill = null
    let sliderWrapperClass = ""
    let serieUrl = ""
    if (seriesId){
      selectedSerie = series.find(el => el.id === seriesId)
      serieUrl = getPillSerieUrl(selectedSerie)
      sliderWrapperClass = style.selected
      if(pillId) {
        selectedPill = selectedSerie.pills.find(el => el.id === pillId)
      }
    }

    const pillsSliderAccessClass = this.hasFacultyAccess() ? "" : style.facultyNoAccess
    
    return(
      <>
        <PillModal 
          toggle={this.modalToggle} 
          pill={selectedPill}
          startOn={getPillStart(selectedPill)}/>
        <IntroSection
          className={cx(facultyStyle.coursesIntro, style.intro)}
          title={<h2>Contenuti <span className="text-red-1">Extra</span></h2>}
          description={
            <IntroDescription 
              description={this.description} 
              className={style.extraIntroDescription}/>
          }
        >
          <div className={cx("facultyExtra", style.sliderWrapper, sliderWrapperClass)}>
            <PillSerieSlider 
              settings={this.seriesSettings}
              user={user}
              faculty={faculty}
              ItemComponent={this.SliderSeriesItemBind}
              onSelection={this.handleSeriesSelection} 
              selectionClass={style.seriesSelected}
            />
          </div>
        </IntroSection>
        {this.haveUser() &&
          <Collapse isOpen={seriesId !== null}>
            <Container fluid className={cx(style.pillsSliderWrapper, pillsSliderAccessClass)}>
              <Container className="pills">
                <h4 className={style.selectedSerieTitle}>
                  <strong>{selectedSerie && selectedSerie.title}</strong>
                </h4>
                <div className="negativeMargin">
                  {seriesId !== null &&
                  <PillsSlider
                    settings={this.pillsSettings}
                    serieId={seriesId}
                    data={selectedSerie ? selectedSerie.pills : []}
                    ItemComponent={this.SliderPillsItemBind}
                    onSelection={this.handlePillsSelection}
                    facultyAccess={this.hasFacultyAccess()}
                  />
                  }
                  {this.hasFacultyAccess() &&
                  <div className={style.showAll}>
                    <Link to={serieUrl}>
                      <ActionButton 
                        action={ActionType.cta} 
                        mode = {ButtonMode.default}
                        text={"Vedi tutti"}   
                        className={style.showAllButton}/>  
                    </Link>
                  </div>
                  }
                </div>
              </Container>
            </Container>
          </Collapse>
        }
      </>
    )
  }
}

const mapState = (state) => {
  return {
    getFacultyPlan: selectors.getFacultyPlan(state),
    getFacultyCta: selectors.getFacultyCta(state),
  }
}

export default connect(mapState)(FacultyExtra) 