import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import {Row, Col} from 'reactstrap';
import {connect} from 'react-redux';
import cx from 'classnames';
import {isEmpty} from 'lodash';

import ProgressCircle from '../UI/ProgressCircle/ProgressCircle';
import {CourseStatsBadge} from '../UI';
import CustomCard from '../UI/Card/Card';
import TeacherPreview from '../TeacherPreview/TeacherPreview';
import {
  coursePercentage, 
  courseIsComplete, 
  courseIsStarted, 
  courseNextLesson} from '../../utilities/reactions';
import {truncateText} from '../../utilities/text';
import {courseCheckout, checkout, coursePlansInfo} from '../Checkout/utilities';
import {logIn} from '../AccessManager/utilities';
import {getCourseUrl, getViewCourseUrl} from '../../utilities/nav';
import * as selectors from '../../reducers/selectors';

import style from './CourseCard.module.css';
import 'react-circular-progressbar/dist/styles.css';

function Cta ({
    user, 
    course, 
    hasAccess, 
    history,
    getFacultyCta,
  }){
  const courseInfo = coursePlansInfo(course)
  const hasCoursePlan = courseInfo.coursePlan !== null
  const hasFacultyPlan = courseInfo.facultyPlan !== null
  if (!hasCoursePlan && !hasFacultyPlan) return null
  let facultyCta = {}
  if (courseInfo.faculties[0]){
    facultyCta = getFacultyCta(courseInfo.faculties[0].id)
  }
  const goToLanding = !isEmpty(facultyCta)

  const hasUser = !isEmpty(user)
  const goToCourse = () => {
    const nextLesson = courseNextLesson(course)
    const courseUrl = getViewCourseUrl(course)
    const lessonPath = nextLesson ? `?lesson=${nextLesson.id}` : ''
    history.push(courseUrl + lessonPath)
  }
  let message = "entra gratis"
  let action = () => logIn()
  if (hasUser){
    if(hasAccess){
      action = goToCourse
      if(courseIsComplete(course)){
        message = "completato"
      }else{
        if(courseIsStarted(course)){
          message = "continua"
        }else{
          message = "inizia adesso"
        }
      }
    }else{
      if (goToLanding){
        if (facultyCta.url === "") {
          return null
        }
        action = () => window.location.href = facultyCta.url
        message = facultyCta.cta
      }else if (hasCoursePlan){
        action = () => courseCheckout(course)
        message = `acquista a ${courseInfo.coursePlan.price_string}`
      }else if (hasFacultyPlan){
        action = () => checkout(courseInfo.faculties[0])
        message = courseInfo.facultyPlan ? `acquista a ${courseInfo.facultyPlan.price_string}` : ''
      }
    }
  }
  return (
    <div className={style.ctaRoot} onClick={action}>
      <p className="label">{message}</p>
    </div>
  )
}

function CourseCard({
    user,   
    course, 
    hasCourseAccess, 
    history,
    getFacultyCta,
  }){
  const imageContainer = {
    backgroundImage: `url("${course.cover}")`
  }

  const percentage = coursePercentage(course)
  const courseUrl = getCourseUrl(course)
  const courseName = truncateText({text: course.name, maxLength: 58}).text
  const hasAccess = hasCourseAccess(course)
  
  return (
    <CustomCard className={style.root}> 
      <Link 
        to={courseUrl} 
        className={cx("noDecoration", style.linkWrapper)}
      >
        <Col xs={12}>
          <div 
            className={style.imageContainer} 
            style={imageContainer}
          />
        </Col>
        <Col xs={12} className={style.courseNameWrapper}>
          <p className={style.courseName}>{courseName}</p>
        </Col>
        <Col>
          <CourseStatsBadge course={course}/>
        </Col>
        <Col className={style.teacherWrapper}>
          <Row style={{flexDirection: 'row'}}>
            {course.teachers && 
            <Col xs={10}>
              <TeacherPreview 
                teacher={course.teachers[0]} 
                nameClass={style.teacherName} 
                rootClass={style.teacherRoot}
                positionClass={style.teacherPosition}
                mdImageCol={3}
                mdTextCol={9}
                imageCol={3}
                textCol={9}
                imageDimension={43}/>
            </Col>
            }
            <Col xs={2} className={style.progressColumn}>
              <div className={style.progressWrapper}>
                <ProgressCircle percentage={percentage}/>
              </div>         
            </Col>
          </Row>
        </Col>
      </Link>
      <Col>
        <Cta 
          user={user} 
          course={course}
          hasAccess={hasAccess}
          history={history}
          getFacultyCta={getFacultyCta}
        />
      </Col>
    </CustomCard>
  )
}

const mapState = (state) => {
  return {
    hasCourseAccess: selectors.hasCourseAccess(state),
    user: selectors.getUser(state),
    getFacultyCta: selectors.getFacultyCta(state),
  }
}

export default withRouter(connect(mapState)(CourseCard))
