import React from 'react'
import { Container, Row, Col } from 'reactstrap';

import style from './TermsConditions.module.css';

function TermsConditions(){
  return (
    <Container>
      <Row>
        <Col>
        <div className={style.main}>
          <h4>Condizioni di utilizzo della piattaforma &ldquo;UP Academy&rdquo; disponibile al link upacademy.eu (la &ldquo;Piattaforma&rdquo;) di titolarit&agrave; di Up S.r.l. (&ldquo;UP&rdquo;)</h4>
          <p><strong>&nbsp;</strong></p>
          <p>UP fornisce, tramite la Piattaforma, un servizio che consente ai propri utenti di accedere a contenuti video, audio o in formato .PDF (i &ldquo;<strong>Contenuti</strong>&rdquo;) di titolarit&agrave; di societ&agrave; connesse/affiliate ad UP (UP e le societ&agrave; a essa connesse, congiuntamente, gli &ldquo;<strong>Affiliati</strong>&rdquo;) in streaming, tramite dispositivi con connessione internet.</p>
          <p>&nbsp;</p>
          <p>Le presenti condizioni generali (le &ldquo;<strong>CG</strong>&rdquo;) disciplinano l&rsquo;utilizzo della Piattaforma e la fruizione dei Contenuti da parte degli utenti (gli &ldquo;<strong>Utenti</strong>&rdquo;) e regolano il rapporto tra UP e gli Utenti, i quali, utilizzando alla Piattaforma come previsto di seguito, dichiarano di aver letto ed espressamente accettato integralmente le CG. Qualora l&rsquo;Utente non intenda accettare anche uno soltanto dei termini e delle condizioni delle CG, &egrave; invitato ad astenersi dall&rsquo;utilizzo della Piattaforma e dalla fruizione dei Contenuti.</p>
          <p>&nbsp;</p>
          <p>L&rsquo;Utente riconosce e accetta che UP potr&agrave; <strong>modificare</strong> le CG in ogni momento, a propria discrezione, mettendone a conoscenza gli Utenti tramite la pubblicazione delle stesse sulla Piattaforma. La mera visita alla Piattaforma e/o la fruizione dei Contenuti successivamente alla modifica varr&agrave; come completa accettazione da parte dell&rsquo;Utente della modifica effettuata. L&rsquo;Utente, quindi, riconosce e accetta che sar&agrave; proprio onere verificare periodicamente la Piattaforma al fine di prendere visione di eventuali modifiche delle CG.&nbsp;</p>
          <p>&nbsp;</p>
          <p><strong><u>Regole di utilizzo della Piattaforma e dei Contenuti</u></strong></p>
          <p><u>Registrazione e pagamenti</u></p>
          <ol>
          <li>L&rsquo;Utente riconosce e accetta che l&rsquo;accesso ai Contenuti potr&agrave; avvenire esclusivamente previ: i) acquisto di un servizio fornito da un Affiliato sulla relativa piattaforma (il &ldquo;<strong>Servizio</strong>&rdquo;); ii) pagamento del corrispettivo secondo i termini e le condizioni stabiliti da ciascun Affiliato in relazione ai propri Servizi; iii) registrazione alla Piattaforma (la &ldquo;<strong>Registrazione</strong>&rdquo;). La fruizione dei Contenuti e l&rsquo;utilizzazione della Piattaforma non comporteranno costi aggiuntivi per l&rsquo;Utente rispetto a quanto gi&agrave; corrisposto agli Affiliati in relazione ai Servizi.</li>
          <li>Nell&rsquo;ambito dell&rsquo;acquisto di un Servizio, o ai fini del godimento di eventuali periodi di prova gratuita concessi dagli Affiliati a propria esclusiva discrezionalit&agrave;, in relazione ai Servizi, l&rsquo;Affiliato richieder&agrave; all&rsquo;Utente di fornire un indirizzo e-mail. L&rsquo;Utente riconosce e accetta che, ai fini della Registrazione, UP gli richieder&agrave; di inserire nell&rsquo;apposita sezione della Piattaforma l&rsquo;indirizzo e-mail col quale &egrave; stato acquistato il Servizio, nonch&eacute; di creare una password sicura per il proprio account sulla Piattaforma, nel quale dovranno essere inserite le informazioni personali e di contatto dell&rsquo;Utente.</li>
          <li>La fruizione dei Contenuti &egrave; riservata a Utenti che abbiano compiuto almeno 18 anni. Gli Utenti minorenni potranno usufruire dei Contenuti esclusivamente sotto la supervisione di un soggetto maggiorenne.</li>
          <li>Con riguardo all&rsquo;acquisto e al pagamento dei Servizi, necessari per accedere ai Contenuti, l&rsquo;Utente si impegna a leggere e ad attenersi ai termini e condizioni stabiliti da ciascun Affiliato sulla rispettiva piattaforma. Inoltre, l&rsquo;Utente riconosce e accetta che i pagamenti e la relativa gestione dei dati necessari per effettuare i medesimi pagamenti potrebbero essere interamente gestiti da societ&agrave; terze diverse da UP e dagli Affiliati, pertanto, per le condizioni relative ai pagamenti, si rimanda ai termini e condizioni disponibili all&rsquo;interno dei siti Internet di ciascuno dei soggetti terzi coinvolti, indicati sulle piattaforme degli Affiliati.</li>
          <li>L&rsquo;Utente riconosce e accetta che, periodicamente, gli Affiliati potrebbero far variare i prezzi dei Servizi e che tali variazioni potranno riguardare anche eventuali abbonamenti gi&agrave; acquistati dagli Utenti. In questo caso, gli Affiliati invieranno una comunicazione agli Utenti, che potranno accettare la variazione o inviare una disdetta, nel rispetto di quanto previsto dai termini e condizioni stabiliti da ciascun Affiliato. In ogni caso, l&rsquo;Utente riconosce e accetta che, al fine di continuare a fruire dei Contenuti, sar&agrave; necessario pagare le nuove tariffe di volta in volta stabilite dagli Affiliati.</li>
          <li>Una volta completata la Registrazione, l&rsquo;Utente si impegna a mantenere il controllo del proprio account di accesso alla Piattaforma e riconosce e accetta che sar&agrave; responsabile delle attivit&agrave; svolte utilizzando tale account. A mero titolo esemplificativo, l&rsquo;Utente non dovr&agrave; comunicare i propri dati di accesso alla Piattaforma ad alcun terzo e dovr&agrave; aggiornare periodicamente la password di accesso scelta in fase di registrazione. UP si riserva in ogni caso la facolt&agrave; di chiudere o sospendere l&rsquo;account dell&rsquo;Utente in caso di furti di identit&agrave; prontamente segnalati dall&rsquo;Utente, attivit&agrave; fraudolente o comunque in violazione delle CG. In tali casi, qualora l&rsquo;Utente volesse richiedere un rimborso per il periodo di sospensione e, in generale, in relazione a qualsivoglia rimborso relativo ai Servizi, l&rsquo;Utente si impegna a far riferimento ai termini e condizioni stabiliti dagli Affiliati e/o a rivolgersi a questi ultimi. In ogni caso, l&rsquo;Utente riconosce e accetta che, in relazione al periodo di sospensione della fruizione dei Contenuti e in relazione a qualsivoglia Servizio, non sar&agrave; dovuto da UP all&rsquo;Utente alcun rimborso, totale o parziale, dell&rsquo;importo corrisposto agli Affiliati.</li>
          <li>L&rsquo;Utente riconosce e accetta che, qualora avesse acquistato un abbonamento a un Servizio e avesse intenzione di disdire tale abbonamento in conformit&agrave; con i termini e condizioni stabiliti da ciascun Affiliato, continuer&agrave; ad avere accesso esclusivamente ai Contenuti fino alla fine del periodo di abbonamento per il quale &egrave; gi&agrave; stato corrisposto pagamento dall&rsquo;Utente.</li>
          </ol>
          <p>&nbsp;</p>
          <p><u>Utilizzo dei Contenuti </u></p>
          <ol start="8">
          <li>L&rsquo;Utente riconosce e accetta che tutti i diritti di propriet&agrave; intellettuale e industriale relativi alla Piattaforma, ai Contenuti e ai Servizi, ivi inclusi eventuali marchi, nomi a dominio, grafiche, musiche, design o diritti di immagine, sono di titolarit&agrave; o nella disponibilit&agrave; esclusiva di UP e degli Affiliati e sono protetti, a mero titolo esemplificativo, contro l&rsquo;uso, la copia o la diffusione non autorizzati.</li>
          <li>I Servizi, la Piattaforma e i Contenuti sono destinati esclusivamente ad un uso personale da parte degli Utenti, con espressa esclusione di qualsivoglia utilizzo commerciale (a mero titolo esemplificativo e non esaustivo, utilizzo dei Contenuti a scopo pubblicitario diretto o indiretto, utilizzo dei Contenuti per proiezioni pubbliche).</li>
          <li>Durante la validit&agrave; della Registrazione e a fronte del pagamento dei corrispettivi dovuti per i Servizi, UP concede agli Utenti un diritto limitato, non esclusivo e non trasferibile, di accedere alla Piattaforma e di visualizzare i Contenuti nei limiti sopra indicati. L&rsquo;Utente riconosce e accetta che, salvo quanto precisato al presente articolo, nessun diritto, titolo o interesse gli viene trasferito in ragione dell&rsquo;utilizzo della Piattaforma e/o dell&rsquo;acquisto di un Servizio.</li>
          <li>L&rsquo;Utente potr&agrave; utilizzare i Contenuti esclusivamente a fronte di pagamento dei Servizi e, qualora ai fini dell&rsquo;accesso agli stessi gli Affiliati prevedano l&rsquo;acquisto di un abbonamento, per il periodo di durata di tale abbonamento o per l&rsquo;eventuale periodo di prova gratuito. In caso di disdetta, come sopra disciplinata, o di mancato rinnovo dell&rsquo;abbonamento, l&rsquo;Utente non avr&agrave; pi&ugrave; accesso ai Contenuti.</li>
          <li>Fatto salvo quanto sopra specificato, l&rsquo;Utente dichiara e garantisce che i) non prover&agrave; a scaricare, conservare, copiare, utilizzare, trasferire, affittare, concedere in sub-licenza, prestare, modificare, adattare, tentare di modificare o alterare il codice sorgente, effettuare operazioni di reverse engineering, decompilare, smontare o altrimenti operare, in tutto o in qualsiasi parte, sui Contenuti e/o sulla Piattaforma, ii) non utilizzer&agrave; i Contenuti per scopi illeciti, in violazione di quanto previsto dalle CG e/o di diritti di terzi e/o di qualsivoglia normativa vigente, iii) non prover&agrave; ad aggirare, rimuovere, alterare, disattivare, degradare o bloccare le protezioni dei Contenuti previste da UP, n&eacute; a caricare, postare o altrimenti inviare qualsiasi materiale progettato per interrompere, distruggere o limitare le funzionalit&agrave; dei servizi forniti attraverso la Piattaforma e/o della stessa Piattaforma.</li>
          <li>I Contenuti sono stati realizzati anche mediante il supporto di soggetti terzi ed hanno esclusivamente scopo informativo/divulgativo/di intrattenimento. L&rsquo;Utente riconosce e accetta, quindi, che i Contenuti possono essere incompleti, imprecisi, non aggiornati e non soddisfare le necessit&agrave; ed esigenze dello stesso. Pertanto, l&rsquo;Utente riconosce e accetta che UP non potr&agrave; essere ritenuta in alcun modo responsabile dei risultati ottenuti dall&rsquo;Utente in seguito all&rsquo;applicazione di quanto divulgato attraverso i Contenuti, siano tali risultati positivi o negativi. A tal proposito, l&rsquo;Utente rinuncia sin d&rsquo;ora a qualsivoglia pretesa nei confronti di UP, anche a titolo di rimborso e risarcitorio, in relazione a eventuali danni sofferti dall&rsquo;Utente in conseguenza della fruizione dei Contenuti e/o all&rsquo;utilizzo della Piattaforma.</li>
          <li>L&rsquo;Utente si impegna sin da ora a manlevare e tenere indenne UP per qualsivoglia danno, perdita, costo, onere o spesa, direttamente o indirettamente sofferti da UP in conseguenza dell&rsquo;utilizzo dei Contenuti e/o della Piattaforma da parte dell&rsquo;Utente in violazione delle CG.</li>
          </ol>
          <p>&nbsp;</p>
          <p><u>Sospensione e Interruzione del Servizio</u></p>
          <ol start="15">
          <li>In caso di violazione o presunte violazioni da parte dell'Utente della legge, di diritti di terzi, ivi inclusi gli Affiliati, o delle CG, UP potr&agrave;, a propria esclusiva discrezione, sospendere o interrompere la fruizione dei Contenuti, chiudere l'account dell'Utente, impedirne l'accesso alla Piattaforma o intraprendere qualsiasi altra azione utile a tutelare i diritti di UP o dei terzi. In nessuno dei casi di cui al presente articolo, l&rsquo;Utente avr&agrave; diritto di avanzare alcuna pretesa, anche a titolo di rimborso o risarcitorio, nei confronti di UP.</li>
          </ol>
          <p>&nbsp;</p>
          <p><strong><u>Responsabilit&agrave; e manleva</u></strong></p>
          <ol start="16">
          <li>L&rsquo;Utente si impegna a manlevare e a tenere indenne UP, gli Affiliati, nonch&eacute; i rispettivi rappresentanti, partner e dipendenti da qualsiasi pretesa, danno (diretto o indiretto), onere, costo, spesa, ivi incluse le spese legali, avanzati da terzi in conseguenza dell&rsquo;utilizzo da parte dell&rsquo;Utente dei Contenuti e della Piattaforma in modo non conforme alle CG e/o alla normativa in vigore applicabile ai Contenuti.</li>
          <li>Fatto salvo il caso di dolo o colpa grave, l'Utente riconosce e accetta che UP non sar&agrave; in alcun modo responsabile verso l&rsquo;Utente per eventuali danni, perdite, costi, oneri e spese, diretti o indiretti, incluse le eventuali spese legali, subiti e/o sopportati dall&rsquo;Utente in connessione con il pagamento dei Servizi e/o, in ogni caso, in connessione con la fruizione dei Contenuti e con l&rsquo;utilizzo della Piattaforma.</li>
          <li>UP non sar&agrave; in alcun modo responsabile verso l'Utente per eventuali ritardi o inadempimenti alle proprie obbligazioni nella fornitura dei servizi connessi alla Piattaforma e dei Contenuti nel caso in cui tali ritardi o inadempimenti derivino da cause di forza maggiore e/o comunque da qualsiasi altro evento che esuli dalla ragionevole sfera di controllo di UP.</li>
          <li>Fatti salvi i rapporti con gli Affiliati, UP non &egrave; necessariamente affiliata e/o partner con le persone fisiche e/o giuridiche titolari dei siti web verso i quali esistano dei collegamenti all&rsquo;interno della Piattaforma e/o dei Contenuti e non &egrave; responsabile ad alcun titolo per il contenuto di detti siti web. Tali collegamenti sono effettuati esclusivamente per comodit&agrave; degli Utenti e l'accesso a tali siti avviene a discrezione ed esclusiva responsabilit&agrave; degli Utenti stessi. Un collegamento dalla Piattaforma o dai Contenuti a un qualsiasi altro sito web non comporta che UP approvi, appoggi o raccomandi in alcun modo detto sito web ovvero abbia alcun controllo su un qualsiasi elemento del contenuto di detto sito web.</li>
          </ol>
          <p>&nbsp;</p>
          <p><strong><u>Privacy</u></strong></p>
          <ol start="20">
          <li>Nell&rsquo;adempimento dei propri obblighi contrattuali, UP si impegna a rispettare le disposizioni di cui al D.Lgs 196/2003, integrato con le modifiche introdotte dal D.Lgs. 10 agosto 2018 n. 101 (il &ldquo;<strong>Codice</strong>&rdquo;) e del Reg. UE 2016/679 (il &ldquo;<strong>Regolamento</strong>&rdquo;, il Codice ed il Regolamento insieme, la &ldquo;<strong>Normativa Privacy</strong>&rdquo;) e, a tale scopo, si impegna ad adottare ogni misura di sicurezza fisica, logistica ed organizzativa necessaria ed adeguata a garantire il rispetto dell&rsquo;integrit&agrave; ed esattezza dei dati personali trattati, nonch&eacute; la liceit&agrave; del loro trattamento. I dati potranno essere trattati esclusivamente nella misura strettamente necessaria alle attivit&agrave; da compiere ai sensi delle CG.</li>
          <li>UP rispetta e tutela la privacy dei propri Utenti. UP non utilizzer&agrave; i dati personali degli Utenti per finalit&agrave; di marketing e/o invio di comunicazioni commerciali e/o vendita diretta senza avere ottenuto il previo espresso consenso, nel rispetto di quanto previsto dalla Normativa Privacy. Per meglio conoscere e comprendere come UP utilizza i dati personali degli Utenti, l&rsquo;Utente potr&agrave; leggere attentamente l&rsquo;Informativa Privacy di cui al seguente link: [inserire link all&rsquo;informativa privacy].</li>
          <li>L&rsquo;Utente, accedendo al proprio account, potr&agrave; in qualsiasi momento correggere, rettificare o aggiornare i propri dati personali. Ove l&rsquo;Utente decida di bloccare e/o cancellare il proprio account, tutti i dati memorizzati relativi all&rsquo;Utente saranno cancellati da UP. Qualora la cancellazione completa dei dati dell&rsquo;Utente non fosse ammessa e/o la conservazione di una copia delle informazioni relative all&rsquo;Utente fosse necessaria per legge, i dati saranno bloccati in relazione ad ogni l&rsquo;ulteriore trattamento.</li>
          </ol>
          <p>&nbsp;</p>
          <p><strong><u>Risoluzione e cancellazione Registrazione</u></strong></p>
          <ol start="23">
          <li>UP si riserva il diritto di risolvere con effetto immediato le CG, mediante semplice invio di raccomandata A/R all&rsquo;indirizzo indicato in fase di Registrazione, o comunque presente nell&rsquo;account dell&rsquo;Utente, qualora l&rsquo;Utente violi una delle disposizioni di cui alle CG, fatto salvo il risarcimento del maggior danno.</li>
          <li>L&rsquo;Utente potr&agrave; cancellare la Registrazione e, dunque, il proprio account sulla Piattaforma, in qualsiasi momento, seguendo le relative informazioni fornite da UP sulla stessa Piattaforma. L&rsquo;Utente riconosce e accetta che, in caso di cancellazione della Registrazione, non potr&agrave; pi&ugrave; fruire dei Contenuti n&eacute; di qualsivoglia servizio fornito da UP tramite la Piattaforma.</li>
          </ol>
          <p>&nbsp;</p>
          <p><strong><u>Varie </u></strong></p>
          <ol start="25">
          <li>L&rsquo;Utente non potr&agrave; cedere n&eacute; in tutto n&eacute; in parte le CG e la Registrazione a terzi.</li>
          <li>Le CG contengono l&rsquo;intero accordo tra le parti in relazione ai Contenuti e alla Piattaforma.</li>
          <li>Le CG sono disciplinate dalla legge italiana.</li>
          <li>Fermo restando quanto previsto a tutela dei consumatori in materia di foro competente, qualsiasi controversia sorgesse tra le parti in relazione alla validit&agrave;, interpretazione, esecuzione e risoluzione delle CG, e/o comunque in connessione con le CG, sar&agrave; di esclusiva competenza del Foro di Milano, con esclusione di ogni altro, anche concorrente o alternativo.</li>
          <li>Qualunque comunicazione inerente le CG indirizzata a UP dovr&agrave; essere inviata a mezzo PEC all&rsquo;indirizzo upacademy@legalmail.it, e/o a mezzo raccomandata A/R all&rsquo;indirizzo Via Giovanni Battista Piranesi 10, 20137 Milano, Milano, IT, anticipata via e-mail all&rsquo;indirizzo fornitori@upacademy.eu. Qualsiasi comunicazione inerente le CG indirizzata all&rsquo;Utente dovr&agrave; essere inviata a mezzo raccomandata A/R all&rsquo;indirizzo di residenza indicato dall&rsquo;Utente in sede di Registrazione o tramite modifica del proprio account sulla Piattaforma.</li>
          <li>Qualora qualsiasi termine o altra disposizione delle CG fossero dichiarati nulli, annullabili o inapplicabili, tutte le altre condizioni e disposizioni delle CG rimarranno, comunque, pienamente valide ed efficaci. Nel caso in cui vi sia l&rsquo;annullamento o la nullit&agrave; di qualche termine o disposizione poich&eacute; invalido, contrario a norme imperative o inapplicabile, le parti si impegnano a negoziare in buona fede per modificare le CG in modo tale da realizzare nel miglior modo possibile l&rsquo;originale intenzione delle parti e garantirne la sopravvivenza.</li>
          <li>L&rsquo;eventuale tolleranza da parte di UP verso comportamenti dell&rsquo;Utente posti in essere in violazione di una qualunque disposizione delle CG non costituisce rinuncia ai diritti derivanti dalla disposizione violata, n&eacute; al diritto di esigere il corretto adempimento di tutte le disposizioni delle CG stesse.</li>
          <li>Il mancato o ritardato esercizio di un diritto spettante a UP ai sensi delle CG non comporta rinuncia allo stesso.</li>
          </ol>
          <p><strong>&nbsp;</strong></p>
          <p>&nbsp;</p>
        </div>
        </Col>
      </Row>
    </Container>
  )
}

export default TermsConditions