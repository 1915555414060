import { combineReducers } from 'redux';

import entities from './entities';
import session from './session';
import courseLandingWaitUser from './courseLandingWaitUser';
import mobileBottomSpacer from './mobileBottomSpacer';

export default combineReducers({
  session,
  entities,
  courseLandingWaitUser,
  mobileBottomSpacer,
})
