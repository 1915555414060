import {combineReducers} from 'redux';
import produce from 'immer';
import * as actionsType from '../actions/actionsTypes';
import {courseStats} from '../api/utilities';
import { SET_DATA_READY } from '../actions/actionsTypes';

const initialCourseState = {
  favourite: false,
  percentages: {},
  lessons: 0,
  seconds: 0,
}

const getCourseState = (state, course_id) => {
  return state[course_id] || initialCourseState
}

const reactions = (state={}, action) => {
  if (action.response && action.response.course_reactions){
    return action.response.course_reactions
  }else if (action.type === actionsType.SET_PROGRESS){
    const {seconds, lesson: {course_id, duration, id: lesson_id}} = action.payload
    const courseState = getCourseState(state, course_id)
    const newCourseState = produce(courseState, draft => {
      if (!draft.percentages[lesson_id]) {
        draft.percentages[lesson_id] = {current:0, total: duration}
      }
      draft.percentages[lesson_id].current = seconds
      const stats = courseStats(draft)
      Object.assign(draft, stats)
    })

    return produce(state, draftState => {
      draftState[course_id] = newCourseState
    })
  }else if (action.type === actionsType.SET_FAVOURITE){
    const {course_id, value: favourite} = action.payload
    const courseState = getCourseState(state, course_id)
    const newCourseState = produce(courseState, draft => {
      draft.favourite = favourite
    })

    return produce(state, draftState => {
      draftState[course_id] = newCourseState
    })
  }else if(action.type === actionsType.CLEAR_REACTIONS){
    return {}
  }else{
    return state
  }
}

const pill_percentages = (state={}, action) => {
  if (action.response && action.response.pill_percentages){
    return action.response.pill_percentages
  }else if(action.type === actionsType.SET_PERCENTAGE){
    const {seconds, pill} = action.payload
    return produce(state, draft => {
      if (!draft[pill.id]) draft[pill.id] = {current: 0, total: pill.duration}
      draft[pill.id].current = seconds
    })
  }else if(action.type === actionsType.CLEAR_REACTIONS){
    return {}
  }else{
    return state
  }
}

const faculties = (state = [], action) => {
  if (action.response && action.response.faculties) {
    return action.response.faculties
  }

  return state
}

const plans = (state = [], action) => {
  if (action.response && action.type  === 'PLANS_SUCCESS' && action.response.plans) {
    return action.response.plans
  }

  return state
}

const faculty_plans = (state = {}, action) => {
  if (action.response && action.type  === 'FACULTY_PLANS_SUCCESS' && action.response.plans) {
    return produce(state, draft => {
      draft[action.faculty] = action.response.plans
    })
  }

  return state
}

const faculty_cta = (state = {}, action) => {
  if (action.response && action.type === 'FACULTY_CTA_SUCCESS' && action.response.cta !== '') {
    return produce(state, draft => {
      draft[action.faculty] = action.response
    })
  }
  return state
}

const course_plans = (state = {}, action) => {
  if (action.response && action.type  === 'COURSE_PLANS_SUCCESS' && action.response.plans) {
    return produce(state, draft => {
      draft[action.course] = action.response.plans
    })
  }

  return state
}

const data_ready = (state = false, action) => {
  if (action.type === SET_DATA_READY) {
    return action.value
  }

  return state
}

const entities = combineReducers({
  reactions,
  faculties,
  plans,
  pill_percentages,
  faculty_plans,
  course_plans,
  data_ready,
  faculty_cta,
})

export default entities
