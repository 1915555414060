import React from 'react';
import {Col} from 'reactstrap';
import cx from 'classnames';

import CustomCard from '../UI/Card/Card';
import VimeoPreview from '../VimeoPreview/VimeoPreview';
import {PillDuration} from '../FacultyExtra/FacultyExtra';
import {truncateText} from '../../utilities/text';

import style from './PillCard.module.css';

function PillCard({pill, number, pillClick}){
  const descriptionMax = 25
  const titleMax = 55
  const descriptionResult = truncateText({text: pill.description, maxLength: descriptionMax})  
  const title = truncateText({text: pill.title, maxLength: titleMax}).text
  return(
    <CustomCard className={style.root} onClick={() => pillClick(pill)}>
      <Col xs={12}>
        <div>
          <VimeoPreview 
            pill={pill} 
            number={number}
            className={style.vimeoPreview}
          />
        </div>
        <PillDuration 
          item={pill} 
          className={style.pillDuration}
        />
        <div className={cx(style.pillTitle)}>
          <p className="big">
            <strong>{title}</strong>
          </p>
          <p className={cx("big", style.pillDescription)}>
            {descriptionResult.text}
          </p>
        </div>
      </Col>
    </CustomCard>
  ) 
}

export default PillCard