import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from "redux-saga";
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import rootReducers from './reducers';
import { sagas } from './sagas';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

let env = process.env.NODE_ENV

if (env === 'production' && process.env.REACT_APP_ENV === 'staging'){
  env = 'staging'
}

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// dev tools middleware
let reduxDevTools = null
let dsn = "https://f62dd2677f4a4311aa0950533ccc661d@sentry.io/2410726"

if (env !== 'production'){
  reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
  dsn = ""
}

let composeFunction = () => compose(applyMiddleware(sagaMiddleware))
if (reduxDevTools) {
  composeFunction = () => compose(applyMiddleware(sagaMiddleware), reduxDevTools)
}

export const store = createStore(
  rootReducers, 
  composeFunction()
)

sagaMiddleware.run(sagas)

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </Provider>
);

Sentry.init({dsn: dsn})

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
