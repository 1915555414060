import { take, put, call, select, fork, all } from 'redux-saga/effects'
import { isEmpty } from 'lodash';

import * as actions from '../actions'
import * as actionsTypes from '../actions/actionsTypes'
import { client } from '../api/Client'
import { getUser, getFaculties, getPlans } from '../reducers/selectors'

const {user} = actions

function* fetchSingle(entity, apiFn, id) {
  try{
    yield put(entity.request(id))
    const {data, error} = yield call(apiFn, id)
    if (data) {
      yield put(entity.success(id, data))
    } else {
      yield put(entity.failure(id, error))
    }
  }
  catch(error){
    yield put(entity.failure(id, error))
  }
}

function* fetchFlat(entity, apiFn) {
  try{
    yield put(entity.request())
    const {data, error} = yield call(apiFn)
    if (data) {
      yield put(entity.success(data))
    } else {
      yield put(entity.failure(error))
    }
  }
  catch(error){
    yield put(entity.failure(error))
  }
}

export const fetchUser = fetchSingle.bind(null, user, client.getUser.bind(client))
export const fetchReactions = fetchSingle.bind(null, actions.reactions, client.getReactions.bind(client))
export const fetchFaculties = fetchSingle.bind(null, actions.faculties, client.getFaculties.bind(client))
export const fetchPlans = fetchFlat.bind(null, actions.plans, client.getPlans.bind(client))
export const fetchFacultyPlans = fetchSingle.bind(null, actions.facultyPlans, client.getFacultyPlans.bind(client))
export const fetchCoursePlans = fetchSingle.bind(null, actions.coursePlans, client.getCoursePlans.bind(client))
export const fetchAccesses = fetchSingle.bind(null, actions.accesses , client.getAccesses.bind(client))
export const fetchFacultyCta = fetchSingle.bind(null, actions.facultyCta, client.getFacultyCta.bind(client))

function* loadUser(id) {
  const user = yield select(getUser)
  if (isEmpty(user)) {
    yield call(fetchUser, id)
  }
}

function* loadReactions(id) {
  yield call(fetchReactions, id)
}

function* loadFaculties(academy, force) {
  const faculties = yield select(getFaculties)
  if (force || isEmpty(faculties)) {
    yield call(fetchFaculties, academy)
  }
}

function* loadAccesses(user) {
  yield call(fetchAccesses, user)
}

function* loadPlans() {
  const plans = yield select(getPlans)
  if (isEmpty(plans)) {
    yield call(fetchPlans)
  }
}

function* loadFacultyPlans(faculty) {
  yield call(fetchFacultyPlans, faculty)
}

function* loadCoursePlans(course) {
  yield call(fetchCoursePlans, course)
}

function* loadFacultyCta(faculty) {
  yield call(fetchFacultyCta, faculty)
}

function* watchLoadAccesses() {
  while (true) {
    const {user} = yield take(actionsTypes.LOAD_ACCESSES)
    yield fork(loadAccesses, user)
  }
}

function* watchLoadPlans() {
  while (true) {
    yield take(actionsTypes.LOAD_PLANS)
    yield fork(loadPlans)
  }
}

function* watchLoadFacultyPlans() {
  while (true) {
    const {faculty} = yield take(actionsTypes.LOAD_FACULTY_PLANS)
    yield fork(loadFacultyPlans, faculty)
  }
}

function* watchLoadFacultyCta() {
  while (true) {
    const {faculty} = yield take(actionsTypes.LOAD_FACULTY_CTA)
    yield fork(loadFacultyCta, faculty)
  }
}

function* watchLoadCoursePlans() {
  while (true) {
    const {course} = yield take(actionsTypes.LOAD_COURSE_PLANS)
    yield fork(loadCoursePlans, course)
  }
}

function* watchLoadUser() {
  while (true) {
    const {id} = yield take(actionsTypes.SET_SESSION_USER)
    yield fork(loadUser, id)
  }
}

function* watchLoadReactions() {
  while (true) {
    const {id} = yield take(actionsTypes.LOAD_REACTIONS)
    yield fork(loadReactions, id)
  }
}

function* watchLoadFaculties() {
  while (true) {
    const {academy, force} = yield take(actionsTypes.LOAD_FACULTIES)
    yield fork(loadFaculties, academy, force)
  }
}

function* watchUserSuccess() {
  while (true) {
    yield take('USER_SUCCESS')
    yield put(actions.setUserReady(true))
  }
}

function* watchLogout() {
  while (true) {
    yield take(actionsTypes.USER_LOGOUT)
    yield put(actions.setUserDataReady(false))
    client.clearSession()
    yield put(actions.clearUser())
    yield put(actions.clearAccesses())
    yield put(actions.clearReactions())
    yield put(actions.loadFaculties(true))
    yield take('FACULTIES_SUCCESS')
    yield put(actions.setUserDataReady(true))
  }
}

function* watchLogin() {
  while(true){
    const {user} = yield take(actionsTypes.USER_LOGIN)
    yield put(actions.setUserReady(false))
    yield put(actions.setUserDataReady(false))
    yield put(actions.setDataReady(false))
    yield put(actions.setSessionUser(user))
    yield take('USER_SUCCESS')
    yield put(actions.loadFaculties(true))
    yield take('FACULTIES_SUCCESS')
    const faculties = yield select(getFaculties)
    yield call(load_entities_plans, faculties)
    yield put(actions.setDataReady(true))
    yield put(actions.loadAccesses(user))
    yield take('ACCESSES_SUCCESS')
    yield put(actions.loadReactions(user))
    yield take('REACTIONS_SUCCESS')
    const plans = yield select(getPlans)
    if (isEmpty(plans)) {
      yield put(actions.loadPlans())
      yield take('PLANS_SUCCESS')
    }
    yield put(actions.setUserDataReady(true))
  }
}

function* load_entities_plans(faculties){
  for (var i=0; i<faculties.length; i++){
    const faculty = faculties[i]
    yield put(actions.loadFacultyPlans(faculty.id))
    yield take('FACULTY_PLANS_SUCCESS')
    yield put(actions.loadFacultyCta(faculty.id))
    yield take('FACULTY_CTA_SUCCESS')
    yield call(load_courses_plans, faculty.courses || [])
  }
}

function* load_courses_plans(courses){
  for (var i=0; i<courses.length; i++){
    yield put(actions.loadCoursePlans(courses[i].id))
    yield take('COURSE_PLANS_SUCCESS')
  }
}

function* watchLoadData() {
  while(true){
    yield take(actionsTypes.LOAD_DATA)
    yield put(actions.setDataReady(false))
    yield put(actions.loadPlans())
    yield take('PLANS_SUCCESS')
    yield put(actions.loadFaculties(true))
    yield take('FACULTIES_SUCCESS')
    const faculties = yield select(getFaculties)
    yield call(load_entities_plans, faculties)
    yield put(actions.setDataReady(true))
  }
}

export function* sagas() {
  yield all([
    fork(watchLoadUser),
    fork(watchLoadReactions),
    fork(watchUserSuccess),
    fork(watchLoadFaculties),
    fork(watchLoadAccesses),
    fork(watchLoadPlans),
    fork(watchLogout),
    fork(watchLogin),
    fork(watchLoadData),
    fork(watchLoadFacultyPlans),
    fork(watchLoadCoursePlans),
    fork(watchLoadFacultyCta),
  ])
}
