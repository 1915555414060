import React from 'react';
import cx from 'classnames';
import {Row, Col} from 'reactstrap';
import {Link} from 'react-router-dom';

import TeacherPreview from '../TeacherPreview/TeacherPreview';
import CustomCard from '../UI/Card/Card';
import { OutlineButton } from '../UI/Buttons';
import {FacultyStatsBadge, CategoryBadge, FacultyLogo} from '../UI'
import {onlyDesktop, onlyMobile} from '../../utilities/styleClasses';
import {getFacultyUrl} from '../../utilities/nav';

import style from './LargeFacultyCard.module.css';

const background = (url) => {
  return {
    backgroundImage: `url("${url}")`,
  }
}

function LargeFacultyCard({faculty}) {
  const statsCommon = {
    iconClassName: style.statsIcon, 
    textClassName: style.statsText, 
    valueClassName: style.statsValue,
    badgeClassName: style.statSingle,
  }

  const category = (
    <div className={cx(style.contentWrapper, style.category)}>
      {faculty.categories.map((item, i) => (
        <CategoryBadge key={i} category={item}/>
      ))}
    </div>
  )

  const logo = (
    <div className={style.logo}>
      <FacultyLogo faculty={faculty}/>
    </div>
  )

  const showFacultyStats = false

  return(
    <CustomCard className={style.card}>
      <Link 
        to={getFacultyUrl(faculty)} 
        className={cx("noDecoration", style.linkWrapper)}
      >
        <Col xs={12} lg={3} className="px-0">
          <div 
            className={style.imageWrapper} 
            style={background(faculty.preview)}
          />
        </Col>
        <Col xs={12} lg={9}>
          <Row style={{height: '100%'}}>
            <Col xs={12}>
              <Row>
                <Col xs={12} className={onlyMobile}>
                  {logo}
                </Col>
                <Col xs={12}  lg={10}>
                  <Row>
                    <Col xs={12}>
                      <div className={cx(style.contentWrapper, style.name)}>
                        <h2>{faculty.name}</h2>
                      </div>
                    </Col>
                    <Col xs={12} className={onlyDesktop}>
                      {category}
                    </Col>
                  </Row>
                </Col>
                <Col lg={2} className={onlyDesktop}>
                  {logo}
                </Col>
                <Col xs={12} className={onlyDesktop}>
                  <div className={cx(style.contentWrapper, style.stats)}>
                    {showFacultyStats &&
                      <FacultyStatsBadge 
                        {...statsCommon}
                        faculty={faculty} 
                        className={style.statsRoot}
                      />  
                    }
                  </div>
                </Col>
                <Col xs={12}>
                  <div className={cx(style.contentWrapper, style.description)}>
                    <p className={cx(onlyDesktop, "big")}>
                      {faculty.description}
                    </p>    
                    <p className={cx(onlyMobile)}>
                      {faculty.description}
                    </p>    
                  </div>
                </Col>
                <Col xs={12} className={onlyMobile}>
                  {category}
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="d-flex">
              <div className={cx(style.contentWrapper, style.putMeDown, style.teacherWrapper)}>
                <Row>
                  <Col xs={12}  lg={6}>
                    <TeacherPreview
                      teacher={faculty.dean}
                      nameClass={style.teacherName} 
                      positionClass={style.teacherPosition}
                    />    
                  </Col>
                  <Col  lg={6} className={onlyDesktop}>
                    <div className={style.moreInfo}>
                      <OutlineButton text="+ Informazioni" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Link>
    </CustomCard>
  )
}

export default LargeFacultyCard
