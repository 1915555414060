import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';

import {loggingEmitter, AccessEvent} from './utilities';
import SignIn from './SignIn';
import SignUp from './SignUp';

import style from './AccessDialog.module.css';

const Mode = {
  Off:    0,
  SignIn: 1,
  SignUp: 2,
};

class AccessDialog extends Component {

  constructor(props) {
    super(props);

    this.initialState = {
      fullName: '',
      email: '',
      password: '',
      loading: false,
      error: null,
      wait: false,
    };

    this.state = this.initialState;

    loggingEmitter.on(AccessEvent.GotToken, () => {
      this.setState({wait: true})
    })

    this.setFullName = this.setFullName.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.modalToggle = this.modalToggle.bind(this);
    this.google = this.google.bind(this);
    this.facebook = this.facebook.bind(this);

    this.onSignIn = this.onSignIn.bind(this);
    this.onSignUp = this.onSignUp.bind(this);
    this.onMailSubmit = this.onMailSubmit.bind(this);

    // For a summary of error code see Firebase.js
    //
    // Don't show 'auth/cancelled-popup-request', 
    // got this message when user want login with a popup already open. We use it
    //
    // Don't show 'auth/popup-closed-by-user', we don't care
    this.skippedSocialErrCode = [
      "auth/cancelled-popup-request",
      "auth/popup-closed-by-user",
    ]
  }

  setFullName(evt) {
    this.setState({
      fullName: evt.target.value,
    })
  }

  setEmail(evt) {
    this.setState({
      email: evt.target.value,
      error: null,
    })
  }

  setPassword(evt) {
    this.setState({
      password: evt.target.value,
      error: null,
    })
  }

  resetPassword() {
    const {providers: {mail: {reset}}} = this.props;
    const {email} = this.state

    this.withLoading(reset(email))
  }

  toggleLoading() {
    this.setState(prev => {
      return {
        loading: !prev.loading,
      }
    })
  }

  withLoading(prom) {
    this.toggleLoading();

    prom.then(res => {
      this.toggleLoading();
      this.setState(this.initialState)
      return res;
    }).catch(error => {
      this.toggleLoading();
      this.setState({error: error, wait: false})
    })
  }

  onSignIn() {
    const {providers: {mail: {signIn}}} = this.props;
    const {email, password} = this.state;

    if (email && password) {
      this.setState({wait: true})
      this.withLoading(signIn(email, password))
    }
  }

  onSignUp() {
    const {providers: {mail: {signUp}}} = this.props;
    const {fullName, email, password} = this.state;

    if (fullName && email && password) {
      this.setState({wait: true})
      this.withLoading(signUp(fullName, email, password))
    }
  }

  onMailSubmit(){
    const {providers: {mail: {check}}} = this.props;
    const {email} = this.state;

    if (email) {
      this.toggleLoading()
      return check(email)
        .then(r => {
          this.toggleLoading()
          return r
        })
        .catch(e => {
          this.toggleLoading()
          this.setState({error: e})
          throw e
        })
    }
  }

  modalToggle(){
    const {toggle} = this.props;
    this.setState(this.initialState)
    toggle()
  }

  google(){
    const {providers: {google}, hide} = this.props;
    hide()
    return google()
      .then(r => {
        this.setState(this.initialState)
      })
      .catch(err => {
        if (!this.skippedSocialErrCode.includes(err.code)){
          this.setState({error: err, wait: false})
        }
      })
  }

  facebook(){
    const {providers: {facebook}, hide} = this.props;
    hide()
    return facebook()
      .then(r => {
        this.setState(this.initialState)
      })
      .catch(err => {
        if (!this.skippedSocialErrCode.includes(err.code)){
          this.setState({error: err, wait: false})
        }
      })
  }

  render() {
    const {mode, providers: {mail}} = this.props;
    const {wait} = this.state
    const bodyClass = wait ? style.blur : ""

    return (
      <Modal
        isOpen={mode !== Mode.Off}
        toggle={this.modalToggle}
        centered={true}
        className={style.modal}
        fade={false}
      >
        {mode !== Mode.Off &&
        <ModalHeader 
          toggle={this.modalToggle} 
          style={{borderBottom: 0}}/>
        }
        {mode !== Mode.Off &&
        <ModalBody className={bodyClass}>
          {mode === Mode.SignIn && (
          <SignIn
            email={this.state.email}
            password={this.state.password}
            providers={{
              google: this.google,
              facebook: this.facebook,
              mail: {
                address: this.setEmail,
                password: this.setPassword,
                reset: this.resetPassword,
                submit: this.onSignIn,
                mailSubmit: this.onMailSubmit,
                switchToSignUp: mail.switchToSignUp,
              },
            }}
            loading={this.state.loading}
            error={this.state.error}
          />
          )}
          {mode === Mode.SignUp && (
          <SignUp
            fullName={this.state.fullName}
            email={this.state.email}
            password={this.state.password}
            mail={{
              fullName: this.setFullName,
              address: this.setEmail,
              password: this.setPassword,
              submit: this.onSignUp,
            }}
            loading={this.state.loading}
            error={this.state.error}
          />
          )}
        </ModalBody>
        }
        {wait &&
        <div className={style.overlay}>
          <Spinner color="primary"/>
        </div>
        }
      </Modal>
    )
  }
}

AccessDialog.Mode = Mode;

export default AccessDialog
