export function truncateText({text, maxLength, ellipsis='...'}){
  let result = text.substring(0, maxLength)
  let truncated = false;
  if (text.length > maxLength){
    result += ellipsis
    truncated = true
  }
  return{
    text: result,
    truncated: truncated,
  }
}