import React from 'react';
import {Button} from 'reactstrap';
import cx from 'classnames';

import './RedButton.css';

function RedButton({children, onClick, className}) {
  return(
    <Button className={cx("redButton", className)} onClick={onClick}>
      {children}
    </Button>
  )
}

export default RedButton