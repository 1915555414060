import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {isEmpty} from 'lodash';
import {Container, Row, Col} from 'reactstrap';
import cx from 'classnames';
import querySearch from 'stringquery';
import { Spinner } from 'reactstrap';

import * as selectors from '../../reducers/selectors';
import {
  ActionButton, 
  ActionType, 
  Mode as ButtonMode} from '../UI/Buttons';
import {FacultyDescription} from '../CourseTop/CourseTop';
import PillCard from '../PillCard/PillCard';
import {PillModal, getPillStart} from '../FacultyExtra/FacultyExtra';

import style from './PillSerie.module.css';

class PillSerie extends Component{
  state = {
    pillSerieLoading: false,
    pillSerie: {},
    faculty: {},
    pills: [],
    pillId: null,
  }

  more = 20

  loadMore = () =>{
    const {pillSerie: {pills: allPills}, pills} = this.state
    const moreLength = pills.length + this.more 
    let newPills = allPills
    if (moreLength < allPills.length){
      newPills = allPills.slice(0, moreLength)
    }
    this.setState({pills: newPills})
  }

  modalToggle = () => {
    const {location: {pathname}, history} = this.props
    history.replace(pathname)
    this.setState({pillId: null})
  }

  handleClick = pill => {
    const {location: {pathname}, history} = this.props
    history.replace(pathname+"?pill="+pill.id)
    this.setState({pillId: pill.id})
  }

  render(){
    const {isDataReady} = this.props
    const {
      pillSerie, 
      faculty, 
      pills, 
      pillId, 
    } = this.state
    
    if (!isDataReady) {
      return (
        <div className={style.root} style={{textAlign: "center"}}>
          <Spinner/>
        </div>  
      )
    }

    if (!pillSerie){
      return <Redirect to="/" />
    }

    const showLoadMore = pillSerie.pills && (pills.length < pillSerie.pills.length)
    const selectedPill = pillId ? pills.find(p => p.id === pillId) : null
    let pillStart = 0
    if (selectedPill) {
      pillStart = getPillStart(selectedPill)
    }

    return(
      <div className={style.root}>
        <PillModal 
          toggle={this.modalToggle} 
          pill={selectedPill}
          startOn={pillStart}/>
        <Container>
          <Row>
            <Col xs={12}>
              <FacultyDescription faculty={faculty}/>
              <h3 className={style.title}>{pillSerie.title}</h3>
              <p className={cx("big", style.description)}>{pillSerie.description}</p>
            </Col>
            {pills.map((pill, idx) => 
              <Col xs={12} md={6} lg={4} xl={3} key={idx}>
                <PillCard pill={pill} key={idx} number={idx+1} pillClick={this.handleClick}/>
              </Col>
            )}  
          </Row>
          {showLoadMore &&
          <Row>
            <Col xs={12} className={style.loadMoreWrap}>
              <ActionButton 
                action={ActionType.cta} 
                mode = {ButtonMode.default}
                text={"Load More"}   
                callback={this.loadMore}
                className={style.loadMore}/>
            </Col>
          </Row>
          }
        </Container>
      </div>
    )
  }

  setPillSerie(){
    // now data is ready
    const {
      faculty: getFaculty, 
      hasAccess,
      match: {params: {pillSerieId, academyId}},
      location: {search, pathname},
      history,
    } = this.props
    const faculty = getFaculty(academyId)
    const somethingWrong = () => {
      this.setState({pillSerie: null})
    }

    if (!faculty || !hasAccess(faculty)) return somethingWrong()
    const pillSerie = faculty.pill_series.find(p => p.id === parseInt(pillSerieId))
    if (!pillSerie) return somethingWrong()
    const pills = pillSerie.pills.slice(0,this.more)

    let state = {faculty: faculty, pillSerie: pillSerie, pills: pills}

    const {pill: pillId} = querySearch(search)
    if (pillId){
      let pillIdx = 0
      const selected = pillSerie.pills.find((el, idx) => {
        pillIdx = idx
        return el.id === parseInt(pillId)
      })
      if (selected){
        if (pillIdx+1 > this.more){
          state.pills = pillSerie.pills.slice(0,pillIdx+1)
        }
        state = {...state, pillId: selected.id}
      }else{
        // pill does not exist, clean url
        history.replace(pathname)
      }
    }
    this.setState(state)
  }

  componentDidUpdate(){
    const {isDataReady} = this.props
    const {pillSerie} = this.state
    if (isDataReady && isEmpty(pillSerie)){
      this.setPillSerie()
    }
  }

  componentDidMount(){
    const {isDataReady} = this.props
    if (isDataReady){
      this.setPillSerie()
    }
  }
}

const mapState = (state) => {
  return {
    user: selectors.getUser(state),
    isDataReady: selectors.getUserDataReady(state),
    faculty: selectors.getFaculty(state),
    hasAccess: selectors.hasFacultyAccess(state),
  }
}

export default connect(mapState)(withRouter(PillSerie))