import React, {useState,useEffect} from 'react';
import {Container, Row, Col} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import {groupBy} from 'lodash';
import cx from 'classnames';

import {api as client} from '../../api';
import {onlyDesktop, onlyMobile} from '../../utilities/styleClasses';
import {BackButton} from '../UI/Buttons';
import LiveEventCard from '../LiveEventCard/LiveEventCard';
import {NextArrow, PrevArrow} from '../UI/Arrows';
import {EventSlider} from '../FacultyLive/FacultyLive';

import style from './LiveEvents.module.css';
import '../FacultyLive/liveEventsSlider.css';

function EventsList({events}){
  return events.map((el, idx) => (
    <Col xs={3} key={idx}>
      <LiveEventCard event={el}/>
    </Col>
  ))
}

function EventsDay({events}){
  const day = moment(events[0].start_at).format('DD MMMM')
  const sliderSettings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  }

  return(
    <Row className={style.eventsDay}>
      <Col xs={12} className={style.day}>
        <p className="text-blue">{day}</p>
      </Col>

      <Col xs={12} className={onlyMobile}>
        <Row>
          <Col 
            xs={{size: 10, offset: 1}} 
            md={{size: 12, offset: 0}} 
            className="xxsGridModifier"
          >
            <Row className="liveSliderWrap">
              <EventSlider 
                events={events} 
                settings={sliderSettings}/>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col xs={12} className={onlyDesktop}>
        <Row>
          {events.length > 4 ? 
            <Col xs={12}>
              <Row className={cx(style.desktopSlider, 'liveSliderWrap')}>
                <EventSlider 
                  events={events} 
                  settings={sliderSettings}/>
              </Row>
            </Col>:
            <EventsList events={events}/>
          }  
        </Row>
      </Col>
    </Row>
  )
}

function LiveEvents({history, match: {params: {academyId}}}){
  const [eventsGrouped, setEvents] = useState([])
  useEffect(() => {
    client.getLiveEvents(academyId)
      .then(({data: {live_events}}) => {
        const grouped = groupBy(live_events, dateString)
        setEvents(Object.values(grouped))
      })
  }, [academyId])

  const dateString = event => {
    return moment(event.start_at).format('DD-MM-YYYY')
  }

  const htext = "Scopri gli appuntamenti del mese del Marketing di Monty"
  
  const back = () => {
    history.goBack()
  }

  return(
    <>
      <Container fluid className={style.root}>
        <Container>
          <Row className={style.headerRow}>
            <Col xs={12}>
              <div onClick={back} className={style.backBlock}>
                <BackButton/>
                <p className="big">Torna all'evento live</p>
              </div>
            </Col>
            <Col xs={12} lg={{size: 8, offset: 2}} className={style.headerCol}>
              <h2 className={onlyDesktop}>{htext}</h2>
              <h4 className={onlyMobile}>{htext}</h4>
              <p className="big">Diventa un Upper e fai salire di livello le tue skill digitali</p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
      {eventsGrouped.map((el, idx) => 
        <Row key={idx}>
          <Col xs={12}>
            <EventsDay events={el}/>
          </Col>
        </Row>
      )}
      </Container>
    </>  
  )
}

export default withRouter(LiveEvents)