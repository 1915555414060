import React from 'react';

function LongArrowLeft ({color='#C2D1D9'}){
  return(
    <svg width="24px" height="24px" viewBox="0 0 24 24" className="long_arrow_left">
        <defs>
            <path d="M11.295,4.705 C10.9055372,5.0944628 10.9052546,5.72581956 11.2943686,6.11563087 L16.17,11 L5,11 C4.44771525,11 4,11.4477153 4,12 C4,12.5522847 4.44771525,13 5,13 L16.17,13 L11.2943686,17.8843691 C10.9052546,18.2741804 10.9055372,18.9055372 11.295,19.295 C11.6843607,19.6843607 12.3156393,19.6843607 12.705,19.295 L20,12 L12.705,4.705 C12.3156393,4.31563925 11.6843607,4.31563925 11.295,4.705 Z" id="path-left"></path>
        </defs>
        <g id="UP-Academy-left-arr" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Dettaglio-Faculty-left-arr" transform="translate(-150.000000, -4198.000000)">
                <g id="Slider-left-#3" transform="translate(0.000000, 3791.000000)">
                    <g id="Left-normal" transform="translate(150.000000, 407.000000)">
                        <g transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) ">
                            <mask id="mask-42" fill="white">
                                <use xlinkHref="#path-left"></use>
                            </mask>
                            <use id="Maske" fillOpacity="0.0" fill="#000000" xlinkHref="#path-left"></use>
                            <g id="↪🎨" mask="url(#mask-42)" fill={color} className="fillTag">
                                <rect id="Atoms-/-Colors-/-Black" x="0" y="0" width="24" height="24"></rect>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  )
}

export default LongArrowLeft