import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import cx from 'classnames';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';

import * as selectors from '../../reducers/selectors';
import TeacherPreview from '../TeacherPreview/TeacherPreview';
import {FacultyStatsBadge, CategoryBadge, FacultyLogo} from '../UI';
import {LoginButton, BuyFacultyButton, RedButton} from '../UI/Buttons';
import {onlyDesktop, onlyMobile, onlyDesktopFlex} from '../../utilities/styleClasses';

import style from './FacultyTop.module.css';
import bookImg from '../../assets/images/book.png';
import cameraImg from '../../assets/images/video_camera_round.png';

export function FacultyPrice({
    plan,
    className, 
    ctaText=" - Accesso a tutti i contenuti"
  }){
  if (!plan) return null
  let priceStr = `${plan.price_string}`
  if (!plan.one_shot && plan.period_unit === 'y') priceStr += ' / anno'
  if (!plan.one_shot && plan.period_unit === 'm') priceStr += ' / mese'

  return(
    <div className={cx(style.facultyPrice, className)}>
      <p className="big">
        <strong>{priceStr}</strong>
        <span> (+IVA se dovuta)</span>
        <span>{ctaText}</span>
      </p>
    </div>
  )
}

function FacultyTop({faculty, user, hasAccess, getFacultyPlan, getFacultyCta}) {
  const statsBadgeDesktop = {
    iconClassName: style.desktopIcons,
  }
  const plan = getFacultyPlan(faculty.id)
  const ctaData = getFacultyCta(faculty.id)
  const showBuyButton = !isEmpty(user) && plan && isEmpty(ctaData)
  const showLandingLink = !isEmpty(user) && !isEmpty(ctaData)

  const ctaBlock = (
    <>
    {isEmpty(user) &&
      <LoginButton/>
    }
    {showBuyButton &&
      <>
        <BuyFacultyButton 
          faculty={faculty} 
          className={style.buyButton}
        />
        <FacultyPrice
          plan={plan}
          className={cx('text-center', style.facultyPrice)}
        />
      </>
    }
    {showLandingLink && ctaData.url !== "" &&
      <div>
        <a href={ctaData.url}>
          <RedButton>{ctaData.cta}</RedButton>
        </a>
      </div>
    }
    </>
  )

  // Old block
  // Leave here for future use?!
  const commonInfoNoAccess = (
    <Row>
      <Col xs={12} className={cx(onlyDesktop, style.desktopDivider)} />
      <Col xs={12} className={cx(style.topCtaMobile, onlyMobile)}>  
        <div className={style.ctaMobileWrapper}>
          {ctaBlock}
        </div>
      </Col>
      <Col xs={12} lg={4} className={style.topCourses}>
        <div className={style.topSpacer}>
          <img alt="book" src={bookImg} className={style.topCoursesImg}/>
        </div>
        <h5>4 corsi certificati</h5>
        <p className='big text-center'>Iscriviti all’academy e accedi a tutti i corsi. 
          Organizza in autonomia il tuo piano di studi, seleziona i corsi da seguire, 
          frequenta le lezione al tuo ritmo e rispondi a quiz ed esami per 
          controllare il tuo livello di comprensione.</p>
      </Col>
      <Col xs={12} className={cx(onlyMobile, style.mobileDivider)}/>
      <Col xs={12} lg={4} className={style.topExtra}>
        <div className={style.topSpacer}>
          <img alt="camera" src={cameraImg} className={style.topCoursesImg}/>
        </div>
        <h5>Contenuti extra</h5>
        <p className="big text-center">Approfondisci le tematiche dei corsi 
        con dei contenuti settimanali e mensili. 
        Salva gli extra che preferisci e non perdere nessun episodio.</p>
      </Col>
      <Col lg={4} className={cx(style.topCtaDesktop, onlyDesktop)}>
        <div className={style.topSpacer}/>
        {ctaBlock}
      </Col>
    </Row>
  )

  const logo = (
    <div className={style.logo}>
      <FacultyLogo faculty={faculty}/>
    </div>
  )

  const coverStyle = {
    backgroundImage: `url("${faculty.preview}")`
  }

  const canShowCommon = () => {
    // Never show commons
    // const facultyPlans = []
    // if (facultyPlans && facultyPlans.length > 0 && !hasAccess(faculty)) return true
    return false
  }

  const canShowCtaBlock = () => {
    if (isEmpty(user)) return true
    if (plan && !hasAccess(faculty)) return true
    return false
  }

  const canShowFacultyStats = false

  return (
    <Container fluid className={style.top}>
      <Row className={onlyMobile}>
        <Col xs={12} className="px-0">
          <div className={style.cover} style={coverStyle}/>
        </Col>
      </Row>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} lg={3} className={cx(onlyDesktop, style.coverDesk)} style={coverStyle} />
          <Col xs={4} className={cx(onlyMobile, style.logoMobile)}>
            {logo}
          </Col>
          <Col xs={12} lg={6} className={style.contentWrappeer}>
            <Row className={style.nameRow}>
              <Col className={style.nameWrapper}>
                <div className={cx(onlyDesktopFlex, style.contentDesktop)} >
                  <div className={cx(style.desktopLogo, onlyDesktop)}><FacultyLogo faculty={faculty}/></div>
                  <h2>{faculty.name}</h2>
                </div>
                <div className={onlyMobile}>
                  <h2>{faculty.name}</h2>
                </div>
              </Col>
            </Row>
            <Row className={style.categoriesRow}>
              <Col className={style.categoriesWrapper}>
                <div className={style.contentDesktop}>
                {faculty.categories.map((item, i) => 
                  <CategoryBadge key={i} category={item}/>
                )}
                </div>
              </Col>
            </Row>
            <Row className={cx("justify-content-between", style.mainContent)}>
              <Col xs="12">
                <p className={cx(style.description, style.contentDesktop)}>
                  {faculty.description}
                </p>
              </Col>
              <Col xs="auto" className={onlyDesktop}>
                {canShowFacultyStats &&
                <FacultyStatsBadge 
                  faculty={faculty} 
                  {...statsBadgeDesktop}/>
                }
              </Col>
            </Row>
            <Row className={onlyDesktop}>
              <Col xs={6}>
                <div className={style.contentDesktop}>
                  <TeacherPreview 
                    teacher={faculty.dean} 
                    nameClass={style.teacherName} 
                    positionClass={style.teacherPosition}/>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className={onlyDesktop} lg={3}> 
            {canShowCtaBlock() &&
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: '100%'}}>
              <div className='text-center'>
                {ctaBlock}
              </div>
            </div>
            }
          </Col>
          <Col className={onlyMobile} xs={12}>
            {canShowFacultyStats &&
            <FacultyStatsBadge 
              faculty={faculty} 
              className={style.statsBadgeMobile}/>
            }
          </Col>
          {canShowCtaBlock() &&
          <Col className={onlyMobile} xs={12}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', height: '100%'}}>
              <div className='text-center'>
                {ctaBlock}
              </div>
            </div>
          </Col>
          }
        </Row>
        {canShowCommon() && commonInfoNoAccess}
      </Container>
    </Container>
  )
}

const mapState = (state) => {
  return {
    user: selectors.getUser(state),
    hasAccess: selectors.hasFacultyAccess(state),
    getFacultyPlan: selectors.getFacultyPlan(state),
    getFacultyCta: selectors.getFacultyCta(state),
  }
}

export default connect(mapState)(FacultyTop)