import React from 'react';
import {Col} from 'reactstrap';
import CustomCard from '../UI/Card/Card';

import style from './TeacherCard.module.css';

import instagramImage from '../../assets/images/SM-instagram@2x.png';
import facebookImage from '../../assets/images/SM-Facebook@2x.png';
// import linkedinImage from '../../assets/images/SM-Linkedin@2x.png';
import twitterImage from '../../assets/images/SM-Twitter@2x.png';


function imgWrapper(url){
  return {
    backgroundImage: `url("${url}")`,
  }
}

function ImageLink({href, title, img}){
  return(
    <div>
      <a 
        href={href} 
        title={title}
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img src={img} alt={title}/>
      </a>
    </div>    
  )
}

function TeacherCard({teacher}){
  return(
    <CustomCard className={style.root}>
      <Col xs={12}>
        <div className={style.imgWrapper} style={imgWrapper(teacher.profile)}/>
      </Col>
      <Col xs={12} className={style.name}>
        <h5>{teacher.name}</h5>
      </Col>
      <Col xs={12} className={style.position}>
        <p>{teacher.position}</p>
      </Col>
      <Col xs={12} className={style.description}>
        <p>{teacher.description}</p>
      </Col>
      <Col xs={12} className={style.social}>  
        <div className={style.socialWrapper}>
          {teacher.twitter &&
          <ImageLink 
            href={teacher.twitter} 
            title="Follow me on twitter" 
            img={twitterImage}/>
          }
          {teacher.facebook &&
          <ImageLink 
            href={teacher.facebook} 
            title="Follow me on facebook" 
            img={facebookImage}/>
          }
          {teacher.instagram &&
          <ImageLink 
            href={teacher.instagram} 
            title="Follow me on instagram" 
            img={instagramImage}/>
          }
        </div>
      </Col>
    </CustomCard>
  )
}

export default TeacherCard