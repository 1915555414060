import React, { Component } from 'react'
import cx from 'classnames'

import style from './Upster.module.css'

const background = (url) => {
  return {
    backgroundImage: `url("${url}")`,
  }
}

class Upster extends Component {

  state = {
    contact: false,
  }

  render() {
    const {upster} = this.props
    return (
      <div
        className={style.box}
        onMouseEnter={() => this.setState({ contact: true })}
        onMouseLeave={() => this.setState({ contact: false })}
      >
        {this.state.contact ? (
          <div className={cx("d-flex flex-column justify-content-center", style.contact)}>
            <div className={style.name}>
              {upster.name}
            </div>
            <div>
              {upster.position}
            </div>
            <div>
              {upster.description}
            </div>
          </div>
        ) : (
          <div
            className={style.wrapper}
            style={background(upster.url)}
          >
          </div>
        )}
      </div>
    )
  }
}

export default Upster
