import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import {isEmpty} from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import Slider from "react-slick";

import {api as client} from '../../api';
import * as selectors from '../../reducers/selectors';
import {pathSegments} from '../../App';
import { onlyDesktopFlex, onlyMobile } from '../../utilities/styleClasses';
import {logIn} from '../AccessManager/utilities';
import TeacherPreview from '../TeacherPreview/TeacherPreview';
import LiveEventCard from '../LiveEventCard/LiveEventCard';
import {IntroSection } from '../UI';
import {GreenBadge} from '../UI/Badge/Badge';
import YoutubePlayer from '../VideoPlayer/YoutubePlayer';
import ActionButton, {Action} from '../UI/Buttons/ActionButton/ActionButton';
import {LinkButton} from '../UI/Buttons'
import {NextArrow, PrevArrow} from '../UI/Arrows';
import LiveCountdown from '../LiveCountdown/LiveCountdown';

import style from './FacultyLive.module.css';
import './facultyLive.css';
import './liveEventsSlider.css';

function Teacher({teacher}) {
  return(
    <Col xs={12} md={4} lg={3}>
      <TeacherPreview 
        teacher={teacher} 
        mdImageCol={3}
        mdTextCol={9}
        nameClass={style.teacherName}
        positionClass={style.positionName}
        rootClass={style.teacher}/>
    </Col>
  )
}

function SliderItem({event}){
  return(
    <div className={style.sliderCardWrap}>
      <LiveEventCard event={event}/>
    </div>
  )
}

export function EventSlider({events, settings}){
  return(
    <Col xs={12}>
      <Slider {...settings}>
        {events.map((el, idx) => (
          <SliderItem key={idx} event={el}/>
        ))}
      </Slider>
    </Col>
  )
}

class FacultyLive extends Component{
  constructor(props) {
    super(props)
    const aspectRatioDefault = Math.round(16/9 * 1000)/1000
    this.sliderSettings = {
      slidesToShow: 2,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      infinite: false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
      ]
    }

    this.state = {
      aspectRatio: aspectRatioDefault,
      isVideoReady: false,
      all: [],
      countdown: false,
    }  
  }

  startString = (event) => {
    const startAt = event.startAt
    const hour = startAt.format('HH:mm')
    const day = startAt.format('D MMMM')
    let text = `L’evento andrà live il ${day} alle ${hour}`
    if (startAt.isSame(moment(), 'day')){
      text = `LIVE SOON - l'evento andrà live alle ${hour}`
    }
    return text
  }

  handleVideoReady = () => {
    this.setState({isVideoReady: true})
  }

  handleToggleCountDown = () => {
    const {countdown} = this.state
    this.setState({countdown: !countdown})
  }

  render(){
    const {user, faculty, faculty:{live_events}} = this.props
    const {aspectRatio, isVideoReady, all, countdown} = this.state
    if (!live_events[0] || all.length === 0) return null
    const firstEvent = all[0]
    firstEvent.startString = this.startString(firstEvent)
    const videoWrapStyle = {
      paddingTop: `${1/(aspectRatio) * 100}%`
    }

    let videoClassName = countdown ? style.blur : ''
    if (!isVideoReady) videoClassName += "d-none"
    
    const linkButton = (
      <LinkButton 
        text="VISUALIZZA TUTTO" 
        link={`/${pathSegments.academies}/${faculty.id}/${pathSegments.liveEvents}`} 
        className={style.viewAll}/>
    )

    const teachers = firstEvent.teachers || []

    return (
      <IntroSection
        title={<h2 className={style.introTitle}>Le <span className="text-red-1">dirette</span></h2>}
        className={style.intro}
        mode="zig"
      >
        <Row className={style.rootRow}>
          <Col xs={12} lg={8} >
            <div className={style.videoWrap} style={videoWrapStyle}>
              <div className={style.absoluteFiller}>
                {isEmpty(user) ?
                <div className={style.loginBox}>
                  <div className={style.ctaBox}>
                    <h5>Per vedere la <span className="text-red-1">diretta</span></h5>
                    <h5>effettua il login</h5>
                  </div>
                  <ActionButton 
                    text="ACCEDI" 
                    callback={logIn} 
                    action={Action.cta} 
                    className={style.loginButton}/>
                </div>:
                <YoutubePlayer
                  id={firstEvent.video} 
                  className={videoClassName}
                  onReady={this.handleVideoReady}/>
                }
              </div>
              {countdown && !isEmpty(user) && isVideoReady &&
              <div className={style.absoluteFiller}>
                <LiveCountdown 
                  start={firstEvent.startAt} 
                  onEnd={this.handleToggleCountDown}/>
              </div>
              }
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div className={style.firstEventInfo}>
              {countdown ?
              <p className={style.startAt}>{firstEvent.startString}</p>:
              <GreenBadge text="LIVE NOW"/>
              }
              <h4 className={style.title}>{firstEvent.title}</h4>
              <p className={cx("big", style.description)}>{firstEvent.description}</p>
            </div>
          </Col>
        </Row>

        <Row className={style.teachersWrap}>
          {teachers.map((el, idx) => <Teacher key={idx} teacher={el} />)}
        </Row>
        {all.slice(1).length > 0 &&
        <>
          <Row className={cx(style.deskEventsWrap, onlyDesktopFlex)}>
            <Col lg={3}>
              <div className={style.nextLiveDesk}>
                <h5>Prossime live in programma</h5>
                {linkButton}
              </div>
            </Col>
            {all.slice(1,4).map((el, idx) => (
              <Col lg={3} key={idx}>
                <LiveEventCard event={el}/>
              </Col>
              )
            )}
          </Row>

          <Row className={cx(style.mobileEventsWrap, onlyMobile)}>
            <Col xs={12}>
              <h4>Prossime live in programma</h4>
            </Col>

            <Col 
              xs={{size: 10, offset: 1}} 
              md={{size: 12, offset: 0}} 
              className="xxsGridModifier"
            >
              <Row className="liveSliderWrap">
                <EventSlider 
                  events={all.slice(1)} 
                  settings={this.sliderSettings}/>
              </Row>
            </Col>

            <Col xs={12} className="text-center">
              {linkButton}
            </Col>
          </Row>
        </>
        }
      </IntroSection>
    )
  }

  componentDidMount() {
    const {faculty} = this.props
    client.getLiveEvents(faculty.id)
      .then(({data: {live_events}}) => {
        const eventsPlus = live_events.map(el => {
          el.startAt = moment(el.start_at).milliseconds(0)
          return el
        })
        this.setState({all: eventsPlus}, () => {
          if (live_events.length === 0) return
          const now = moment().milliseconds(0)
          const start = eventsPlus[0].startAt
          if (start.isAfter(now)){
            this.setState({countdown: true})
          }
        })
      })
  }
}

const mapState = (state) => {
  return {
    user: selectors.getUser(state),
  }
}

export default connect(mapState)(FacultyLive)
