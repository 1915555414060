import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import cx from 'classnames';
import PropTypes from 'prop-types';

import style from './VideoPlayer.module.css';

class VideoPlayer extends Component{
  ref = React.createRef()
  state={
    ready: false,
  }

  handleProgress = (obj) => {
    this.props.onProgress(obj)
  }

  handleReady = () => {
    const {startOn, onReady} = this.props
    this.ref.current.seekTo(startOn)
    onReady()
    this.setState({ready: true})
  }

  handlePause = () => {
    const {onPause, onPauseProgress} = this.props
    onPause()
    onPauseProgress(this.ref.current.getCurrentTime())
  }

  render(){
    const {id, light, playing, className, onEnded, onPlay, onError} = this.props
    const opacity = this.state.ready ? style.opacityOne : style.opacityZero
    return (
      <ReactPlayer
        url={`https://player.vimeo.com/video/${id}`}
        ref = {this.ref}
        width='100%'
        height='100%'
        controls={true}
        light={light}
        className={cx(style.playerRoot, opacity, className)}
        playing={playing}
        progressInterval={10000}
        onProgress={this.handleProgress}
        onEnded={onEnded}
        onPause={this.handlePause}
        onPlay={onPlay}
        onReady={this.handleReady}
        onError={onError}
        config={{
          vimeo: {
            playerOptions: {
              title: false,
            }
          }
        }}
      />
    )
  }

  componentDidUpdate(prevProps){
    if (this.props.id && (this.props.id !== prevProps.id)){
      this.setState({ready: false})
    }
  }
}

VideoPlayer.propTypes = {
  id: PropTypes.number.isRequired,
  light: PropTypes.bool,
  className: PropTypes.string,
  startOn: PropTypes.number,
  onReady: PropTypes.func,
  onPlay: PropTypes.func,
  onEnded: PropTypes.func,
  onPause: PropTypes.func,
  onPauseProgress: PropTypes.func,
  onProgress: PropTypes.func,
  onError: PropTypes.func,
}

VideoPlayer.defaultProps = {
  light: false,
  startOn: 0,
  className: '',
  onReady: () => {},
  onPlay: () => {},
  onEnded: () => {},
  onPause: () => {},
  onPauseProgress: () => {},
  onProgress: () => {},
  onError: () => {},
}

export default VideoPlayer