import event from 'events';

export const AccessEvent = {
  Login: 'login',
  Logout: 'logout',
  Success: 'login_success',
  Exit: 'login_exit',
  GotToken: 'got_token',
}

export const loggingEmitter = new event()

export function logIn(){
  loggingEmitter.emit(AccessEvent.Login)
}

export function logOut(){
  loggingEmitter.emit(AccessEvent.Logout)
}

export function success(){
  loggingEmitter.emit(AccessEvent.Success)
}

export function exit(){
  loggingEmitter.emit(AccessEvent.Exit)
}
