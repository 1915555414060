import React from 'react'
import cx from 'classnames';

import style from './StatsBadge.module.css'

export const getLessonsSeconds = (lessons) => {
  let seconds = 0
  lessons.forEach(lesson => {
    seconds += lesson.duration
  });
  return seconds
}

const getCoursesSeconds = (courses) => {
  let seconds = 0
  courses.forEach(course => {
    seconds += getLessonsSeconds(course.lessons)
  });
  return seconds
}

export const secondsToHours = (seconds) => {
  return Math.floor(seconds / 3600)
}

export function StatBadge(props) {
  const {
    className, 
    icon, 
    label, 
    value, 
    iconClassName="", 
    textClassName="", 
    valueClassName="",
  } = props
  return (
    <div className={cx(className, style.statRoot)}>
      <p className="big">
        <span
          className={cx("oi", icon, iconClassName)}
          aria-hidden="true"
        />
        <span className={cx(style.label, textClassName)}>
          {label}:
        </span>
        <strong className={valueClassName}>
          {value}
        </strong>
      </p>
    </div>
  )
}

function StatsBadge(
  {
    className, 
    itemsValue,
    itemsIcon, 
    itemsLabel,
    hoursValue, 
    hoursIcon, 
    hoursLabel, 
    badgeClassName="", 
    iconClassName="", 
    textClassName="", 
    valueClassName="",
    statsBoxClassName=""}){
  const common = {
    className: badgeClassName,
    iconClassName,
    textClassName,
    valueClassName,
  } 
  const showHours = hoursValue > 0
  return(
    <div className={className}>
      <div className={cx(style.statBox, statsBoxClassName)}>
        <StatBadge
          {...common}
          icon={itemsIcon}
          label={itemsLabel}
          value={itemsValue}
        />
      </div>
      {showHours &&
      <div className={cx(style.statBox, statsBoxClassName)}>
        <StatBadge
          {...common}
          icon={hoursIcon}
          label={hoursLabel}
          value={hoursValue}
        />
      </div>
      }
    </div>
  )
}

export function FacultyStatsBadge({faculty, className, badgeClassName="", iconClassName="", textClassName="", valueClassName=""}){
  const itemsValue = (faculty.courses || []).length
  // const hoursValue = faculty.hours
  const hoursValue = secondsToHours(getCoursesSeconds(faculty.courses || []))
  return <StatsBadge 
    itemsValue={itemsValue}
    hoursValue={hoursValue}
    className={className}
    badgeClassName={badgeClassName}
    iconClassName={iconClassName}
    textClassName={textClassName}
    valueClassName={valueClassName}
    itemsIcon="oi-menu" 
    itemsLabel="N. Corsi" 
    hoursIcon="oi-calendar" 
    hoursLabel="Ore"/>
}

export function CourseStatsBadge({
    course, 
    className, 
    badgeClassName, 
    iconClassName, 
    textClassName, 
    valueClassName,
    statsBoxClassName=""}){
  const itemsValue = (course.lessons || []).length
  // const hoursValue = secondsToHours(getLessonsSeconds(course.lessons || []))
  const hoursValue = 0
  return <StatsBadge 
    itemsValue={itemsValue}
    hoursValue={hoursValue}
    className={cx(style.badgeCourseRoot,className)}
    badgeClassName={cx(style.badgeCourseItem, badgeClassName)}
    iconClassName={cx(style.badgeCourseIcons, iconClassName)}
    textClassName={cx(style.badgeCourseText, textClassName)}
    valueClassName={cx(style.badgeCourseValue, valueClassName)}
    statsBoxClassName={statsBoxClassName}
    itemsIcon="oi-menu" 
    itemsLabel="Video" 
    hoursIcon="oi-calendar" 
    hoursLabel="Ore"/>
}