import React, {Component} from 'react';
import {Collapse, Col} from 'reactstrap';
import cx from 'classnames';

import {IntroSection, CollapseIcon, CollapseMode} from '../UI';
import {onlyDesktop, onlyMobile} from '../../utilities/styleClasses';
import style from './Faq.module.css';


function IntroTitle(){
  const text = "Frequently asked questions"
  return (
    <>
      <h2 className={cx(onlyDesktop, style.intro)}>{text}</h2>
      <h3 className={cx(onlyMobile, style.intro)}>{text}</h3>
    </>
  )
}

const faqs = [
  {
    header: <p className="big">Cosa succede nel momento in cui acquisto un servizio del Monty Breakfast Club?</p>,
    body: <p>Riceverai una un'email di conferma e una di benvenuto con le credenziali per la consultazione dei contenuti.</p>
  },
  {
    header: <p className="big">Vorrei pagare inserendo la Partita Iva, perché non viene accettata?</p>,
    body: <p>La piattaforma accetta solo Partite IVA che siano registrate presso il VIES comunitario. Per l'Italia, è possibile verificare la registrazione <a href="http://ec.europa.eu/taxation_customs/vies/?locale=it" target='_blank' rel='noopener noreferrer'>qui</a>. Se la tua Partita IVA non risulta presente, non abbiamo modo di forzare quel dato nel sistema. Ti consigliamo pertanto di iscrivervi online e gratuitamente presso il registro VIES oppure di considerare l’iscrizione da privato. In quest'ultimo caso, lascia vuoto il campo #Reg IVA e sarà applicata l'imposta del valore aggiunto del paese indicato.</p>
  },
  {
    header: <p className="big">Perché non compare il campo P. Iva?</p>,
    body: <p>È necessario prima selezionare la nazione di appartenenza.</p>
  },
  {
    header: <p className="big">Posso pagare con PayPal o tramite bonifico bancario?</p>,
    body: <p>La piattaforma accetta le carte di credito VISA, Mastercard e American Express. Non è possibile pagare con bonifico bancario e PayPal.</p>
  },
  {
    header: <p className="big">Sono uno studente. Ho qualche agevolazione?</p>,
    body: <p>Se sei uno studente puoi avere accesso gratis al videocorso Social Media Playbook. Non potendo gestire richieste di singoli studenti, chiedi alla tua scuola superiore o università di inviare un'email ufficiale indicando il numero di studenti interessati (min. 20) e provvederemo a creare gli accessi. Contattaci scrivendo a supporto@upacademy.eu</p>
  }
]

function Header({el}){
  return(
    <div className={style.header}>
      {el}
    </div>
  )
}

function Body({el}){
  return(
    <>
      <div className={cx(onlyDesktop, style.bodyDesktop)}>
        {/* <p>{text}</p> */}
        {el}
      </div>
      <div className={cx(onlyMobile, style.bodyMobile)}>
        {/* <p>{text}</p> */}
        {el}
      </div>
    </>
  )
}

class Faq extends Component{
  state={
    collapse: null
  }

  getFaqs(){
    return faqs
  }

  getHeaderClassName(idx){
    if (this.state.collapse === idx) return style.headerWrapperOpen
    return style.headerWrapperClose
  }

  toggleOpen(idx){
    let collapse = idx
    if(this.state.collapse === idx){
      collapse = null
    }
    this.setState({collapse})
  }

  render(){
    const faqs = this.getFaqs() 
    const {collapse} = this.state
    const {className} = this.props
    return(
      <IntroSection
        title={<IntroTitle/>}
        className={cx(className, style.coursesIntro )}
        mode={'zig'}
      >
      {faqs.map((item, idx) => 
        <div key={idx}>
          <Col xs={12}
            className={this.getHeaderClassName(idx)}
            onClick={() => this.toggleOpen(idx)}
          >
            <Header el={item.header}/>
            <CollapseIcon isOpen={collapse === idx} mode={CollapseMode.Arrow}/>
          </Col>
          <Collapse isOpen={collapse === idx}>
            <Col xs={12} className={style.bodyWrapper}>
              <Body el={item.body}/>
            </Col>
          </Collapse>
        </div>  
      )}
      </IntroSection>
    )
  }
}

export default Faq
