import React, {Component} from 'react';
import cx from 'classnames';

import play from '../../assets/images/play_button@2x.png';
import style from './VimeoPreview.module.css';

class VimeoPreview extends Component{
  render(){
    const {pill, number, className} = this.props
    const pillImageStyle = {
      backgroundImage: `url("${pill.thumbnail}")`,
    }
    return(
      <div className={cx(style.wrapper, className)}>
        <div 
          className={cx(style.pillOverlay, style.pillImage)} 
          style={pillImageStyle}/>
        <div className={style.pillOverlay}>
          <div className={style.playWrapper}>
            <img src={play} alt={pill.title}/>
          </div>
        </div>
        <div className={style.pillOverlay}>
          <div className={style.textWrapper}>
            <h2>{number}</h2>
          </div>
        </div>
      </div>
    )
  }
}

export default VimeoPreview