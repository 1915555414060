import React from 'react';

function ArrowRight ({color='#000'}){
  return(
    <svg width="23px" height="43px" viewBox="0 0 23 43" >
        <g id="UP-Academy-versione-review" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="Dettaglio-Faculty" transform="translate(-1374.000000, -2383.000000)" stroke={color} strokeWidth="4">
                <polyline id="right_arrow" transform="translate(1385.500000, 2404.500000) rotate(-90.000000) translate(-1385.500000, -2404.500000) " points="1366 2395 1385.42338 2414 1405 2395"></polyline>
            </g>
        </g>
    </svg>
  )
}

export default ArrowRight