import React from 'react';
import cx from 'classnames';

import style from './CollapseIcon.module.css';

export const Mode = {
  Arrow: 'arrow',
  PlusMinus: 'plusminus',
  ArrowThin: 'arrowThin',
}

function Collapse({isOpen}){
  let className = style.collapseImg
  if (isOpen) className = cx(className, style.open)
  return(
    <div className={className}/>
  )
}

function CollapseIcon({isOpen, mode=Mode.ArrowThin}){
  if (mode === Mode.ArrowThin){
    return <Collapse isOpen={isOpen}/>
  }
  const openAction = (
    <>
    {mode === Mode.PlusMinus &&
      <span className={style.openActionPlusMinus}>
        <span className="oi oi-plus" aria-hidden="true" />
        <span aria-label="open" className="p-0"/>
      </span>
    }
    {mode === Mode.Arrow &&
      <span className={style.openActionArrow}>
        <span className="oi oi-chevron-right" aria-hidden="true" />
        <span aria-label="open" className="p-0"/>
      </span>
    }
    </>
  )

  const closeAction = (
    <>
    {mode === Mode.PlusMinus &&
      <span className={style.closeActionPlusMinus}>
        <span className="oi oi-minus" aria-hidden="true" />
        <span aria-label="close" className="p-0"/>
      </span>
    }
    {mode === Mode.Arrow &&
      <span className={style.closeActionArrow}>
        <span className="oi oi-chevron-bottom" aria-hidden="true" />
        <span aria-label="close" className="p-0"/>
      </span>
    }
    </>
  )

  return isOpen ? closeAction : openAction  
}

export default CollapseIcon