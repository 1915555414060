import React from 'react';
import { Container } from 'reactstrap';
import cx from 'classnames';

import style from './IntroSection.module.css';

function IntroSection({title, description, children, mode, className}){
  const getMode = () => {
    return mode === 'zig' ? style.zig : style.zag
  }

  return (
    <div className={cx(style.fluid, getMode(), className)}>
      <Container>
        <div className={style.intro}>
          {title}
          {description}
        </div>
        {children}
      </Container>
    </div>
  )
}

export default IntroSection
