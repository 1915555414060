import React from 'react'
import { Badge } from 'reactstrap';

import './badge.css';

export function CategoryBadge ({category}) {
  return (
    <Badge className="custom-badge category-badge" pill>
      <p className="big m-0">{category.name}</p>
    </Badge>
  )
}

export function AudioBadge(){
  return(
    <Badge className="custom-badge audio-badge" pill>
      <div className="d-flex align-items-center ">
        <span className="oi oi-microphone" aria-hidden="true" />
        <span className="audio">AUDIO</span>
      </div>
    </Badge>
  )
}

export function GreenBadge({text}){
  return(
    <Badge className="custom-badge green-badge" pill>
      <p className="big m-0">{text}</p>
    </Badge>
  )
}