import React from 'react';

import ArrowLeft from '../ArrowLeft/ArrowLeft';
import ArrowRight from '../ArrowRight/ArrowRight';

function Arrow(props){
  const {onClick, component, className} = props;
  return (
    <div 
      className={className}
      style={{zIndex: 10, width: '23px', height: '43px'}}
      onClick={onClick}
    >
      {component}
    </div>
  )
}

function PrevArrow(props){
  return <Arrow {...props} component={<ArrowLeft/>}/>
}

function NextArrow(props){
  return <Arrow {...props} component={<ArrowRight/>}/>
}

export {PrevArrow, NextArrow}