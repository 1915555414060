export const Mode = {
  default: 'modeDefault',
  small: 'modeSmall'
}
export {
  default as ActionButton, 
  Action as ActionType,
  LoginButton, 
  BuyFacultyButton,
  BuyCourseButton} from './ActionButton/ActionButton';
export {default as OutlineButton} from './OutlineButton/OutlineButton';
export {default as LinkButton} from './LinkButton/LinkButton';
export {default as BackButton} from './BackButton/BackButton';
export {default as BluButton} from './BluButton/BluButton';
export {default as RedButton} from './RedButton/RedButton';
export {default as PlayButton} from './PlayButton/PlayButton';