import { combineReducers } from 'redux';
import { 
  SET_USER_READY, 
  CLEAR_USER, 
  CLEAR_ACCESSES,
  SET_USER_DATA_READY } from '../actions/actionsTypes';

export function user(state = {}, action) {
  if (action.response && action.response.user) {
    return action.response.user
  }

  if (action.type === CLEAR_USER) {
    return {}
  }

  return state
}

const userReady = (state = false, action) => {
  if (action.type === SET_USER_READY) {
    return action.value
  }

  return state
}

const academyAccesses = (state = [], action) => {
  if (action.response && action.type === 'ACCESSES_SUCCESS' && action.response.accesses) {
    const academies = action.response.accesses.find(item => item.type === "academy").ids
    return academies
  }
  if(action.type === CLEAR_ACCESSES) {
    return []
  }

  return state
}

const facultyAccesses = (state = [], action) => {
  if (action.response && action.type === 'ACCESSES_SUCCESS' && action.response.accesses) {
    const faculties = action.response.accesses.find(item => item.type === "faculty").ids

    return faculties.map(i => parseInt(i))
  }
  if(action.type === CLEAR_ACCESSES) {
    return []
  }

  return state
}

const courseAccesses = (state = [], action) => {
  if (action.response && action.type === 'ACCESSES_SUCCESS' && action.response.accesses) {
    const courses = action.response.accesses.find(item => item.type === "course").ids
    return courses.map(i => parseInt(i))
  }
  if(action.type === CLEAR_ACCESSES) {
    return []
  }

  return state
}

const userDataReady = (state = false, action) => {
  if (action.type === SET_USER_DATA_READY) {
    return action.value
  }

  return state
}

export default combineReducers({
  user,
  userReady,
  facultyAccesses,
  courseAccesses,
  userDataReady,
  academyAccesses,
})
