import React from 'react';
import {withRouter} from 'react-router-dom';
import cx from 'classnames';

import {pathSegments} from '../../App';
import style from './MainContent.module.css';

function MainContent(props){
  let addictionalClass = ""
  const pageIsViewCourse = props.location.pathname.includes(pathSegments.viewcourse)
  if(pageIsViewCourse) {
    addictionalClass = "d-flex"
  }
  return (
    <main className={cx(style.main, addictionalClass)}>
      {props.children}
    </main>
  )
}

export default withRouter(MainContent)