import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import * as selectors from '../../reducers/selectors';
import * as actions from '../../actions';
import AccessDialog from './AccessDialog';
import Firebase from './Firebase';
import { client } from '../../api/Client';
import {loggingEmitter, AccessEvent} from './utilities';

class AccessManager extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      mode: AccessDialog.Mode.Off,
    };

    loggingEmitter.on(AccessEvent.Login, () => {
      this.switchToSignIn()
    })

    loggingEmitter.on(AccessEvent.Logout, () => {
      this.signOut()
    })

    this.toggle = this.toggle.bind(this);
    this.authChanged = this.authChanged.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signUp = this.signUp.bind(this);
    this.userMailExist = this.userMailExist.bind(this);
    this.switchToSignUp = this.switchToSignUp.bind(this);
    this.switchToSignIn = this.switchToSignIn.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.google = this.google.bind(this);
    this.facebook = this.facebook.bind(this);

    this.firebase = new Firebase(this.authChanged);
  }

  authChanged(user) {
    const {
      logout, 
      setUserReady, 
      user: old_user, 
      login, 
      loadData} = this.props
    this.setState({ready: true})
    if (isEmpty(user)) {
      if (old_user.id) {
        // User made a logout
        logout()
      }else{
        // User opens application (maybe reload), no firebase user data
        setUserReady(true)
        loadData()
      }
      
    }else{
      // User opens application (maybe reload), we have firebase user data
      // Login action is manage with single login method (google, facebook, signIn, signUp)
      if (this.state.mode === AccessDialog.Mode.Off){
        user.getIdToken()
          .then(client.startSession)
          .then(session => {
            login(session.user_id)
            loggingEmitter.emit(AccessEvent.Success)
          })
      }
    }
  }

  manageLogIn(prom){
    const {login} = this.props
    return prom
      .then(token => {
        loggingEmitter.emit(AccessEvent.GotToken)
        return client.startSession(token)
      })
      .then(session => {
        login(session.user_id)
        this.setState({mode: AccessDialog.Mode.Off})
        loggingEmitter.emit(AccessEvent.Success)
      })
  }

  // Not used anymore
  toggle() {
    this.setState(prev => {
      if (prev.mode === AccessDialog.Mode.Off) {
        return {
          mode: AccessDialog.Mode.SignIn,
        };
      }

      return {
        mode: AccessDialog.Mode.Off,
      };
    });
  }

  mode() {
    const {ready, mode} = this.state
    const {user} = this.props

    if (!ready || !isEmpty(user)) {
      return AccessDialog.Mode.Off
    }

    return mode
  }

  signIn(email, password) {
    return this.manageLogIn(this.firebase.signIn(email, password))
  }

  signUp(fullName, email, password) {
    return this.manageLogIn(this.firebase.signUp(fullName, email, password))
  }

  switchToSignUp(){
    this.setState({mode: AccessDialog.Mode.SignUp})
  }

  switchToSignIn(){
    this.setState({mode: AccessDialog.Mode.SignIn})  }

  userMailExist(email) {
    return this.firebase.signIn(email, " ")
      .catch(error => {
        if (error.code === 'auth/user-not-found') {
          return false
        }else if (error.code === 'auth/wrong-password'){
          return true
        }else if (error.code === 'auth/invalid-email'){
          throw new Error("Invalid email")
        }
      })
  }

  resetPassword(email) {
    return this.firebase.resetPassword(email, window.location.href)
  }

  google() {
    return this.manageLogIn(this.firebase.googleSignIn())
  }

  facebook() {
    return this.manageLogIn(this.firebase.facebookSignIn())
  }

  turnOff = () => {
    loggingEmitter.emit(AccessEvent.Exit)
    this.setState({mode: AccessDialog.Mode.Off})
  }

  hide = () => {
    this.setState({mode: AccessDialog.Mode.Off})
  }

  signOut = () => {
    this.setState({ready: false})
    this.firebase.signOut()
  }

  render() {
    return (
      <AccessDialog
        mode={this.mode()}
        toggle={this.turnOff}
        hide={this.hide}
        providers={{
          mail: {
            signIn: this.signIn,
            signUp: this.signUp,
            reset: this.resetPassword,
            check: this.userMailExist,
            switchToSignUp: this.switchToSignUp,
          },
          google: this.google,
          facebook: this.facebook,
        }}
      />
    )
  }
}

const mapState = (state) => {
  return {
    user: selectors.getUser(state),
  }
}

const mapDispatch = (dispatch) => {
  return {
    setUserReady: value => dispatch(actions.setUserReady(value)),
    logout: () => dispatch(actions.logout()),
    loadData: () => dispatch(actions.loadData()),
    login: (user) => dispatch(actions.login(user))
  }
}

export default connect(mapState, mapDispatch)(AccessManager)
