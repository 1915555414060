import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import { FormGroup, Input } from 'reactstrap';
import cx from 'classnames';

import style from './SignInUp.module.css';
import './signInUp.css'

import googleLogo from '../../assets/images/logo_google@2x.png';
import facebookLogo from '../../assets/images/logo_facebbok@2x.png';
import {ActionButton, Mode as buttonMode} from '../UI/Buttons';
import UpLogo from '../UI/UpLogo/UpLogo';

export function CommonHead({firstStep}){
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className={style.logoWrapper}>
            <UpLogo color="#172E88"/>
          </div>
        </Col>
      </Row>
      <Row className={style.claim}>
        <Col xs={12}>
          <p className={style.signinTitle}>
            Entra in Up Academy
          </p>
        </Col>
        {firstStep &&
        <Col xs={12}>
          <p className={style.modalText}>
            Crea il tuo account ed inizia ad aumentare le tue skill
          </p>
        </Col>}
      </Row>
    </>
  )
}

function SocialLogo({image}){
  const background = {
    backgroundImage: `url("${image}")`,
  }
  return (
    <div className={cx("my-auto", style.socialLogo)} style={background}/>
  )
}

function SocialLoginBox({text, image, provider}){
  return (
    <div className={cx("d-flex p-1 mb-2 mx-auto rounded", style.socialBox)}>
      <div className="col-2 px-0 d-flex">
        <SocialLogo image={image} />
      </div>
      <div className="col-10 d-flex" onClick={provider}>
        <span className={cx("my-auto", style.socialSignInText)}>{text}</span>
      </div>
    </div>
  )
}

export function CustomFormGroup({type, placeholder, value, onChange, disabled}){
  return (
    <FormGroup 
      className={cx(style.customFormGroup, 'signInCustomForm')} 
      disabled={disabled} 
      check={true}
    >
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={!disabled ? onChange : () => {}}
        className={cx("border-dark rounded-sm", style.customInput)}/>
    </FormGroup>
  )
}

function SocialLogin({providers: {google, facebook}}){
  return(
    <Row style={{marginBottom: '20px'}}>
      <Col xs="12" className="mx-auto">
        <SocialLoginBox 
          text="Entra con Google" 
          image={googleLogo} 
          provider={google}/>
      </Col>
      <Col xs="12" className="mx-auto">
        <SocialLoginBox 
          text="Entra con Facebook" 
          image={facebookLogo} 
          provider={facebook}/>
      </Col>
    </Row>
  )
}

export function SubmitButton({text, callback, disabled}){
  return(
    <ActionButton
      disabled={disabled}
      mode={buttonMode.small} 
      text={text} 
      callback={callback}
      className={cx(style.customButton, "no-shadow")}/>
  )
}

export function ErrorView({error}){
  return(
    <div className={style.formError}>
      {error &&
      <p className={style.errorMessage}>{error.message}</p>
      }
    </div>
  )
}

class SignIn extends Component{
  state={
    userExist: null,
    pwdChangeRequested: false,
  }

  onMailSubmit = () => {
    const {providers: {mail: {switchToSignUp, mailSubmit}}, email} = this.props
    if(email){
      mailSubmit(email)
        .then(resp => {
          if(resp){
            this.setState({userExist: resp})
          }else{
            switchToSignUp()
          }
        })
        .catch(e => {})
    }
  }

  onPwdReset = () => {
    const {providers: {mail}} = this.props
    this.setState({pwdChangeRequested: true})
    mail.reset()
  }

  onMailChange = (evt) => {
    const {providers: {mail}} =this.props
    const {pwdChangeRequested} = this.state
    if (pwdChangeRequested) {
      this.setState({pwdChangeRequested: false})
    }
    mail.address(evt)
  }

  render(){
    const {loading, error, email, password, providers} = this.props
    const {mail} = providers;
    const {userExist, pwdChangeRequested} = this.state
    const verifyMail = "continua con la tua mail".toUpperCase()
    const submitMail = "entra in up academy".toUpperCase()
    const firstStep = userExist === null
    const secondStep = userExist || userExist === false
    
    const mailForm = (
      <>
        <p className={style.modalText} style={{marginBottom: '20px'}}>
          oppure continua con la tua mail
        </p>
        <div className={style.formGroupWrapper}>
          <CustomFormGroup 
            type="email" 
            placeholder="Scrivi la tua mail" 
            value={email} 
            onChange={this.onMailChange} 
            disabled={loading}/>
        </div>
        <ErrorView error={error}/>
      </>
    )
    
    const passwordForm = (
      <>
        <div className={style.formGroupWrapper}>
          <CustomFormGroup 
            type="password" 
            placeholder="La tua password" 
            value={password} 
            onChange={mail.password} 
            disabled={loading}/>
        </div>
        <ErrorView error={error}/>
      </>
    )

    const pwdReq = pwdChangeRequested && !loading

    return(
      <Container>
        <CommonHead />
        {firstStep && <SocialLogin providers={providers}/>}
        <Row>
          <Col>
            {firstStep && mailForm}
            {secondStep && passwordForm}
            <div className={style.buttonWrapper}>
              {firstStep && (!pwdReq || error) &&
              <SubmitButton 
                text={verifyMail} 
                callback={this.onMailSubmit} 
                disabled={loading}/>
              }
              {secondStep && 
              <SubmitButton 
                text={submitMail} 
                callback={mail.submit} 
                disabled={loading}/>
              }
            </div>
            {userExist === null &&
            <div className={cx(style.buttonWrapper, style.passwordReset)}>
            {pwdReq && !error ?
              <p className={style.pwrResetMsg}>Controlla la tua mail per dare seguito alla richiesta</p> :
              <p className={style.textAction} onClick={this.onPwdReset}>Hai dimenticato la password?</p>
            }
            </div>
            }
          </Col>
        </Row>
      </Container>
    )
  }
  
}


export default SignIn
