import React from 'react';
import cx from 'classnames';

import style from './BackButton.module.css';

function BackButton({className}){
  return (
    <div className={cx(style.root, className)}>
      <span className="oi oi-chevron-left" aria-hidden="true" />
    </div>
  )
}

export default BackButton