import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import { Spinner } from 'reactstrap';

import {getUser, getUserReady} from './reducers/selectors';
import AppWrapper from './components/AppWrapper/AppWrapper';
import LoggedCoursePage from './components/LoggedCoursePage/LoggedCoursePage';
import MainContent from './components/MainContent/MainContent';
import AppCommonBlock from './components/AppCommonBlock/AppCommonBlock';
import Home from './components/Home/Home';
import Dashboard from './components/Dashboard/Dashboard';
import Faculty from './components/Faculty/Faculty';
import CoursePage from './components/CoursePage/CoursePage';
import PillSerie from './components/PillSerie/PillSerie';
import LiveEvents from './components/LiveEvents/LiveEvents';
import TermsCondition from './components/TermsCondition/TermsCondition'
import {getPageFromPathname} from './components/AppBar/AppBar';

import {logIn, loggingEmitter, AccessEvent} from '../src/components/AccessManager/utilities';

export const pathSegments = {
  dashboard: 'dashboard',
  academies: 'accademie',
  courses: 'corsi',
  viewcourse: 'viewcourse',
  liveEvents: 'events',
  pillSerie: 'pillserie',
  tm: 'terms-conditions'
}

function LoggedHandler({user, userReady, children}){
  if (userReady && isEmpty(user)) {
    return <Redirect to="/" />
  }
  return children
}

class DoLoginHandler extends Component {
  state={
    exit: false
  }

  constructor(props){
    super(props)

    this.loginExitListener = () => {
      this.setState({exit: true})
    }

    this.logoutListener = () => {
      this.setState({exit: true})
    }

    loggingEmitter.on(AccessEvent.Exit, this.loginExitListener)
    loggingEmitter.on(AccessEvent.Logout, this.logoutListener)
  }

  render(){
    const {userReady, user, children} = this.props
    const {exit} = this.state

    if (exit){
      return <Redirect to="/" />
    }

    if (!userReady){
      return (
        <div style={{textAlign: "center", paddingTop: "70px", backgroundColor: "white", width: "100%"}}>
          <Spinner/>
        </div>  
      )
    }

    if (isEmpty(user)){
      logIn()
      return (
        <div style={{textAlign: "center", paddingTop: "70px", backgroundColor: "white", width: "100%"}}>
          <Spinner/>
        </div>  
      )
    }

    return (
      <LoggedHandler user={user} userReady={true}>
        {children}
      </LoggedHandler>
    )

  }
}

function Routes({user, userReady}){
  const segments = pathSegments
  return(
    <Switch>
      <Route 
        path="/"
        exact
        render={() => <Home/>}
      />
      <Route
        path={`/${segments.academies}/:id`}
        exact
        render={() => <Faculty />}
      />
      <Route
        path={`/${segments.academies}/:academyId/${segments.courses}/:courseId`}
        exact
        render={() => <CoursePage />}
      />
      <Route 
        path={`/${segments.academies}/:academyId/${segments.liveEvents}`} 
        exact 
        render={() => <LiveEvents/>}
      />
      <Route
        path={`/${segments.dashboard}`}
        exact
        render={() => 
          <LoggedHandler 
            user={user} 
            userReady={userReady}
          >
            <Dashboard/>
          </LoggedHandler>}
      />
      <Route
        path={`/${segments.academies}/:academyId/${segments.viewcourse}/:courseId`}
        exact
        render={() => 
          <DoLoginHandler 
            user={user} 
            userReady={userReady}
          >
            <LoggedCoursePage/>
          </DoLoginHandler>}
      />
      <Route
        path={`/${segments.academies}/:academyId/${segments.pillSerie}/:pillSerieId`}
        exact
        render={() => 
          <DoLoginHandler 
            user={user} 
            userReady={userReady}
          >
            <PillSerie/>
          </DoLoginHandler>}
      />
      <Route 
        path={`/${segments.tm}`}
        exact
        render={() => <TermsCondition/>}
      />
      <Redirect to="/" />
    </Switch>
  )
}

class App extends Component {
  render() {
    const {user, userReady, location: {pathname}} = this.props
    const page = getPageFromPathname(pathname)
    return (
      <AppWrapper page={page}>
        <AppCommonBlock page={page}>
          <MainContent>
              <Routes 
                user={user} 
                userReady={userReady}
              />
          </MainContent>
        </AppCommonBlock>
      </AppWrapper>
    )
  }
}

const mapState = state => {
  return {
    user: getUser(state),
    userReady: getUserReady(state),
  }
}

export default withRouter(connect(mapState)(App));
