import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { Spinner } from 'reactstrap';

class Spinnable extends Component {

  render() {
    const {item, children} = this.props

    if (isEmpty(item)) {
      return (
        <div style={{textAlign: "center", paddingTop: "70px", backgroundColor: "white", width: "100%"}}>
          <Spinner/>
        </div>  
      )
    }

    return children
  }
}

export default Spinnable
