import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import cx from 'classnames';
import _ from 'lodash';

import * as selectors from '../../reducers/selectors';
import {onlyDesktop, onlyMobile} from '../../utilities/styleClasses';
import {CategoryBadge, FacultyLogo} from '../UI';
import TeacherPreview from '../TeacherPreview/TeacherPreview';
import {
  LoginButton, 
  BuyCourseButton,
  BuyFacultyButton,
  ActionButton, 
  ActionType, 
  Mode as ButtonMode,
  RedButton} from '../UI/Buttons';
import {coursePlansInfo} from '../Checkout/utilities';
import {
  courseIsComplete, 
  courseNextLesson,
  courseIsStarted} from '../../utilities/reactions';
import ShowMoreItems from '../ShowMoreItems/ShowMoreItems';
import {getViewCourseUrl} from '../../utilities/nav';
import {FacultyPrice} from '../FacultyTop/FacultyTop';
import style from './CourseTop.module.css';

export function FacultyDescription({faculty}){
  const facultyName = faculty.name && faculty.name.toUpperCase()
  return(
    <Row>
      <Col xs={12}>
        <div className={style.facultyDescription}> 
          <FacultyLogo 
            faculty={faculty} 
            className={style.facultyLogo}
          />
          <div className={style.facultyName}>
            <p><strong>{facultyName}</strong></p>
          </div>  
        </div>
      </Col>
    </Row>
  )
}

function Badges({categories}){
  return(
    <>
    {categories && categories.map((item, i) => 
      <CategoryBadge key={i} category={item}/>  
    )}
    </>
  )
}

const teachersArray = teachers => {
  return teachers.map((item, idx) => 
    <Col 
      xs={12} 
      lg={4} 
      key={idx} 
      className={style.teacherWrapper}
    >
      <div className={style.teacherPreviewWrap}>
        <TeacherPreview 
          teacher={item} 
          nameClass={style.teacherName} 
          positionClass={style.teacherPosition}
          pictureClass={style.teacherProfile}
        />
      </div>
    </Col>
  )
}

function Teachers({teachers}){
  return teachersArray(teachers)
}

function NextStep({course}){
  const getLessonTimeString = lesson => {
    const hours = Math.floor(lesson.duration / 3600)
    const minutes = Math.floor((lesson.duration - (hours * 3600)) / 60)
    let result = ""
    if (hours > 0 ){
      const hoursName = hours > 1 ? "ore" : "ora"
      result += `${hours} ${hoursName} `
    }
    if (hours > 0 && minutes > 0) result += "e "
    if (minutes > 0 ){
      const minutesName = minutes > 1 ? "minuti" : "minuto"
      result += `${minutes} ${minutesName}`
    }
    if (hours + minutes === 0){
      result = `${lesson.duration} secondi`
    }
    return result
  }

  const nextLesson = courseNextLesson(course)

  if (!nextLesson) return null
  return (
    <p className="big">Dal video "{nextLesson.title}" ({getLessonTimeString(nextLesson)})</p>
  )
}

function LoggedCourseButton({course, history}){
  let url = getViewCourseUrl(course)
  const nextLesson = courseNextLesson(course)
  var nextLessonPath = ""
  if (nextLesson){
    nextLessonPath = nextLesson.id ? `?lesson=${nextLesson.id}` : ''
  }
  
  let message = ''
  if (courseIsComplete(course)){
    message = "VAI"
  }else if(courseIsStarted(course)){
    message = "CONTINUA"
    url += nextLessonPath
  }else{
    message = "INIZIA AD IMPARARE"
    url += nextLessonPath
  }

  return(
    <ActionButton 
      action={ActionType.cta} 
      mode = {ButtonMode.default}
      text={message}   
      callback={() => history.push(url)}
      className={style.loggedCourseButton}/>
  )
}

function BuyCourse({course, plan}){
  return(
    <>
      <BuyCourseButton 
        course={course} 
        className={style.ctaBuyCourseButton}/>
      <p className="big">
        <strong>Al prezzo di €{(plan.price)/100}</strong>
      </p>
    </>
  )
}

function BuyFaculty({faculty, plan}){
  const ctaText = (
    <span> - Accesso a tutti i contenuti di <strong>{faculty.name}</strong></span>
  )
  return(
    <>
      <BuyFacultyButton faculty={faculty}/>
      <FacultyPrice 
        plan={plan} 
        ctaText={ctaText}/>
    </>
  )
}

export function Cta(props){
  const {
    user, 
    course, 
    hasCourseAccess, 
    history,
    facultyCta,
   } = props

  const haveUser = !_.isEmpty(user)
  const courseInfo = coursePlansInfo(course)
  const hasCoursePlan = courseInfo.coursePlan !== null
  const hasFacultyPlan = courseInfo.facultyPlan !== null
  
  const hasLanding = !_.isEmpty(facultyCta)
  const showCourseButton = haveUser && hasCourseAccess
  const showBuyFromLanding = haveUser && !hasCourseAccess && hasLanding
  const showBuyButton = haveUser && !hasCourseAccess && !hasLanding
  const showBuyCourseButton = showBuyButton && hasCoursePlan
  const showBuyAcademyButton = showBuyButton && !hasCoursePlan && hasFacultyPlan

  return(
    <div className={style.cta}>
      {!haveUser && <LoginButton/>}
      <div className={style.ctaCourseButton}>
        {showBuyFromLanding && facultyCta.url !== "" &&
          <div className={style.buyFromLanding}>
            <a href={facultyCta.url}>
              <RedButton>
                {facultyCta.cta}
              </RedButton>
            </a>
          </div>
        }
        {showCourseButton &&
        <>
          <LoggedCourseButton 
            course={course} 
            history={history}
          />
          <NextStep course={course}/>
        </>
        }
        {showBuyCourseButton &&
        <BuyCourse 
          course={course} 
          plan={courseInfo.coursePlan}
        />
        }
        {showBuyAcademyButton &&
        <BuyFaculty
          faculty={courseInfo.faculties[0]} 
          plan={courseInfo.facultyPlan}
        />
        }
      </div>
    </div>
  )
}

class CourseTop extends Component {
  mobileMaxTeachers=4
  
  render(){
    const {
      user, 
      course, 
      faculty, 
      isDataReady,
      hasCourseAccess,
      history,
      facultyCta,
    } = this.props
    
    if(_.isEmpty(course) || _.isEmpty(faculty)) return null
    const teachers = course.teachers || []
    const initialTeachers = teachers.slice(0, this.mobileMaxTeachers)
    const moreTeachers = teachers.slice(this.mobileMaxTeachers)
    return(
      <div className={style.root}>
        <Container>
          <Row>
            <Col xs={12} className={style.descriptionWrapper}>
              <FacultyDescription faculty={faculty}/>
              <Row>
                <Col xs={12} className={style.courseTitle}>
                  <h3>{course.name}</h3>
                </Col>
                <Col xs={12} className={style.badgesWrapper}>
                  <Badges categories={course.categories}/>
                </Col>
                {teachers.length > 0 &&
                <Col xs={12} className={cx(style.teachersWrapper, onlyDesktop)}>
                  <h3>Speaker</h3>
                  <Row>
                    <Teachers teachers={teachers}/>
                  </Row>
                </Col>
                }
                <Col xs={12} className={cx(style.teachersWrapper, onlyMobile)}>
                  <Row>
                    <Teachers teachers={initialTeachers}/>  
                  </Row>
                  <Row>
                    <ShowMoreItems items={teachersArray(moreTeachers)}/>
                  </Row>
                </Col>
                <Col xs={12} className={cx(style.ctaWrapper, onlyDesktop)}>
                  {isDataReady &&
                  <Cta 
                    user={user} 
                    course={course}
                    hasCourseAccess={hasCourseAccess}
                    history={history}
                    facultyCta={facultyCta}
                  />
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const mapState = state => {
  return {
    user: selectors.getUser(state),
    isDataReady: selectors.getUserDataReady(state),
  }
}

export default withRouter(connect(mapState)(CourseTop))