import React from 'react';
import cx from 'classnames';

import style from './PlayButton.module.css';

// Reference
// https://medium.com/@pppped/how-to-code-a-responsive-circular-percentage-chart-with-svg-and-css-3632f8cd7705

function PlayButton({complete, percentage}){
  let playButtonClass = style.playButton
  if (complete) {
    playButtonClass = cx(playButtonClass, style.complete)
    percentage = 0
  }

  const stroke = 4
  const boxDimension = 36
  const ratio = 0.8

  const diameter = boxDimension * ratio
  const radius = diameter / 2
  const circumference = diameter * Math.PI
  const x = boxDimension / 2
  const y = (boxDimension - diameter) / 2
  const circumferenceSector = percentage / 100 * circumference

  
  const dimensionStyle = {
    width: `${boxDimension}px`,
    height: `${boxDimension}px`
  }
  return(
    <div 
      className={style.playRoot} 
      style={dimensionStyle}
    >
      <div 
        className={playButtonClass} 
        style={dimensionStyle}
      />
      <div 
        className={style.playOverlay} 
        style={dimensionStyle}
      >
        <svg viewBox={`0 0 ${boxDimension} ${boxDimension}`} >
          <path
            d={`M${x} ${y} a ${radius} ${radius} 0 0 1 0 ${diameter} a ${radius} ${radius} 0 0 1 0 -${diameter}`}
            fill="none"
            stroke="#25248e"
            strokeWidth={stroke}
            strokeDasharray={`${circumferenceSector}, ${circumference}`}
            className={style.svgCircle}
          />
        </svg>
      </div>
    </div>
  )
}

export default PlayButton